import React, { ChangeEvent, DragEvent } from "react";
import { toast } from "react-toastify";
import { convertXlsxToArrayObjects } from "../utils/fileUtils";
import Papa, { ParseResult } from "papaparse";

interface DropzoneProps {
  id: string;
  onUploadCallback: (data: any) => void;
  children: React.ReactNode;
  accept?: string;
}

export function Dropzone({ id, onUploadCallback, children, accept = "" }: DropzoneProps) {
  if (!id) {
    throw new Error("id is required");
  }

  const processFile = (file: File) => {
    if (
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      handleExcelFile(file);
    } else if (file.type === "text/csv") {
      handleCsvFile(file);
    } else if (file.type === "text/plain") {
      handleTextFile(file);
    }
    // else if (file.type === "application/pdf") {
    //   handlePdfFile(file);
    // }
    else {
      toast.error("Unsupported file type");
    }
  };

  const handleCsvFile = (file: any) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      header: true,
      // Typescript tells me the following options aren't allowed...
      // encoding: "UTF-8", // Specify the encoding
      // error: function (err: Error, file: File, inputElem: HTMLInputElement, reason: string) {
      //   if (reason === "EncodingError") {
      //     toast.error("File contains disallowed characters. Only UTF-8 encoding is allowed.");
      //   }
      // },
      complete: function (results: ParseResult<any>) {
        onUploadCallback(results.data);
      },
    });
  };

  const handleExcelFile = (file: File) => {
    convertXlsxToArrayObjects(file)
      .then((arrayObjects) => {
        onUploadCallback(arrayObjects); // Handle the upload for Excel files
      })
      .catch((error) => {
        console.error("Error converting file to base64", error);
        toast.error("Error converting file to base64");
      });
  };

  const handleTextFile = (file: File) => {
    // just call the onUploadCallback with a string which is read from the file
    const reader = new FileReader();
    reader.onload = function (e) {
      onUploadCallback(e.target?.result);
    };
    reader.readAsText(file);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      processFile(file);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      processFile(file);
    }
  };

  // Add the event listeners for dragging over and leaving the drop zone
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div className="flex items-center justify-center w-96 h-full" onDragOver={handleDragOver} onDrop={handleDrop}>
      <label
        htmlFor={id}
        className="flex flex-col items-center justify-center w-full h-full border-2 border-mainlightgray border-dashed rounded-lg cursor-pointer bg-mainlightgray dark:hover:bg-bray-800 dark:bg-maindarkgray hover:bg-mainlightgray dark:border-maindarkgray dark:hover:border-maindarkgray dark:hover:bg-maindarkgray"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            className="w-8 h-8 mb-6 text-maindarkgray dark:text-maindarkgray"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 16"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
            />
          </svg>

          <p className="mb-2 text-sm text-maindarkgray dark:text-maindarkgray">
            <span className="font-semibold">Click to upload</span> or drag and drop
          </p>
          {children}
        </div>

        <input id={id} type="file" className="hidden" accept={accept} onChange={handleChange} />
      </label>
    </div>
  );
}
