import React, { useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ArrowsPointingOutIcon } from "@heroicons/react/20/solid";

function stringToHash(string: string | null): number {
  if (!string || string.length === 0) {
    return 0;
  }

  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

interface TruncateTextProps {
  text: string;
  pushX?: number;
  pushY?: number;
  lineClamp?: number;
  minBoundLettersPerLine?: number;
}

export const TruncateText: React.FC<TruncateTextProps> = ({
  text,
  pushX = 0,
  pushY = 0,
  lineClamp = 10,
  minBoundLettersPerLine = 1,
}) => {
  const [isHover, setIsHover] = useState(false);

  const wordCutoff = lineClamp * minBoundLettersPerLine;
  const show = isHover && text.length > wordCutoff;
  const thisBoxHash = stringToHash(text);

  if (!text) {
    return null;
  }

  const stringWithLineBreaks = text.split("\n").map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));

  return (
    <Popover className="relative overflow-visible w-full h-full">
      <div
        className="overflow-hidden w-full h-full invisible"
        style={{
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: lineClamp,
        }}
      >
        {text}
      </div>
      <div
        className="absolute inset-0 z-5"
        style={{
          bottom: `-${pushY}px`,
          right: `-${pushX}px`,
          paddingBottom: `${pushY}px`,
          paddingRight: `${pushX}px`,
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div
          className="overflow-hidden w-full h-full"
          style={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: lineClamp,
          }}
        >
          {text}
        </div>
        <Transition
          show={show}
          enter="transition-opacity duration-[2000ms]"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-[100ms]"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <button
            onClick={() =>
              (document.getElementById(`overflow-display-modal-${thisBoxHash}`) as HTMLDialogElement)?.showModal()
            }
          >
            <ArrowsPointingOutIcon className="w-5 h-5 absolute bottom-0 right-0" />
          </button>
        </Transition>
      </div>
      <dialog id={`overflow-display-modal-${thisBoxHash}`} className="modal">
        <div className="modal-box">
          <p className="py-4">{stringWithLineBreaks}</p>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </Popover>
  );
};
