import React from "react";
import AppRouter from "./AppRouter";

// Some tasty providers
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Configure Amplify once with all necessary options
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "6cllmb6g0fsavvsgf3ak8po3gt",
      userPoolId: "us-east-1_ja6W0NI9c",
    },
  },
});

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        theme="light"
      />

      <AppRouter />
    </React.StrictMode>
  );
};

export default App;
