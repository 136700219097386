import React, { useRef } from "react";
import { PipelineSpec, PipelineStage, PipelineStageType } from "../../utils/interfaces";

const commandMapping: Record<PipelineStageType, string> = {
  LOAD_FROM_JSON_STRING: "Load From JSON String",
  SINGLE_INDEX: "Single Index",
  DICT_DRILL_DOWN: "Dict Drill Down",
  STRING_SPLIT: "String Split",
  STRING_JOIN: "String Join",
};

interface PipelineBuilderProps {
  pipelineSpec: PipelineSpec | null;
  setPipelineSpec: (spec: PipelineSpec | null) => void;
}

export const PipelineBuilder: React.FC<PipelineBuilderProps> = ({ pipelineSpec, setPipelineSpec }) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const addStage = (stageType: PipelineStageType) => {
    let newStage: PipelineStage;
    switch (stageType) {
      case "LOAD_FROM_JSON_STRING":
        newStage = { COMMAND: "LOAD_FROM_JSON_STRING", PARAMS: {} };
        break;
      case "SINGLE_INDEX":
        newStage = { COMMAND: "SINGLE_INDEX", PARAMS: { index: 0 } };
        break;
      case "DICT_DRILL_DOWN":
        newStage = { COMMAND: "DICT_DRILL_DOWN", PARAMS: { field: "" } };
        break;
      case "STRING_SPLIT":
      case "STRING_JOIN":
        newStage = { COMMAND: stageType, PARAMS: { separator: "" } };
        break;
    }
    setPipelineSpec(
      pipelineSpec ? { ...pipelineSpec, STAGES: [...pipelineSpec.STAGES, newStage] } : { STAGES: [newStage] },
    );

    // Reset the select value
    if (selectRef.current) {
      selectRef.current.value = "";
    }
  };

  const removeStage = (index: number) => {
    if (pipelineSpec) {
      const newStages = [...pipelineSpec.STAGES];
      newStages.splice(index, 1);
      setPipelineSpec({ ...pipelineSpec, STAGES: newStages });
    }
  };

  const updateStage = (index: number, updatedStage: PipelineStage) => {
    if (pipelineSpec) {
      const newStages = [...pipelineSpec.STAGES];
      newStages[index] = updatedStage;
      setPipelineSpec({ ...pipelineSpec, STAGES: newStages });
    }
  };

  const moveStage = (fromIndex: number, toIndex: number) => {
    if (pipelineSpec) {
      const newStages = [...pipelineSpec.STAGES];
      const [removed] = newStages.splice(fromIndex, 1);
      newStages.splice(toIndex, 0, removed);
      setPipelineSpec({ ...pipelineSpec, STAGES: newStages });
    }
  };

  const clearPipeline = () => {
    setPipelineSpec(null);
  };

  if (!pipelineSpec) {
    return (
      <div className="container mx-auto">
        <h4 className="text-lg font-bold mb-2">Selector Configuration</h4>
        <button className="btn btn-primary btn-sm mt-2" onClick={() => setPipelineSpec({ STAGES: [] })}>
          Create New Selector
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <h4 className="text-lg font-bold mb-2">Selector Configuration</h4>
      <div className="space-y-2">
        {pipelineSpec.STAGES.map((stage, index) => (
          <StageComponent
            key={`stage-${index}`}
            stage={stage}
            index={index}
            onUpdate={(updatedStage) => updateStage(index, updatedStage)}
            onRemove={() => removeStage(index)}
            onMoveUp={() => moveStage(index, index - 1)}
            onMoveDown={() => moveStage(index, index + 1)}
            isFirst={index === 0}
            isLast={index === pipelineSpec.STAGES.length - 1}
          />
        ))}
      </div>
      <div className="mt-2">
        <div className="mt-2">
          <select
            ref={selectRef}
            className="select select-bordered select-sm w-full max-w-xs"
            onChange={(e) => addStage(e.target.value as PipelineStageType)}
            defaultValue=""
          >
            <option value="" disabled>
              Add a new stage...
            </option>
            {Object.entries(commandMapping).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mt-2">
        <button className="btn btn-error btn-sm" onClick={clearPipeline}>
          Clear Selectors
        </button>
      </div>
    </div>
  );
};

interface StageComponentProps {
  stage: PipelineStage;
  index: number;
  onUpdate: (updatedStage: PipelineStage) => void;
  onRemove: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  isFirst: boolean;
  isLast: boolean;
}

const StageComponent: React.FC<StageComponentProps> = ({
  stage,
  index,
  onUpdate,
  onRemove,
  onMoveUp,
  onMoveDown,
  isFirst,
  isLast,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const handleParamChange = (paramName: string, value: any) => {
    const updatedStage = {
      ...stage,
      PARAMS: {
        ...stage.PARAMS,
        [paramName]: value,
      },
    };
    onUpdate(updatedStage);
  };

  return (
    <div className="card bg-base-100 shadow-sm">
      <div className="card-body p-2">
        <div className="card-title text-sm">
          <button className="btn btn-square btn-xs" onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? "▶" : "▼"}
          </button>
          <span>{commandMapping[stage.COMMAND as PipelineStageType]}</span>
          <div className="card-actions">
            <button className="btn btn-error btn-xs" onClick={onRemove}>
              Remove
            </button>
            {!isFirst && (
              <button className="btn btn-info btn-xs" onClick={onMoveUp}>
                Up
              </button>
            )}
            {!isLast && (
              <button className="btn btn-info btn-xs" onClick={onMoveDown}>
                Down
              </button>
            )}
          </div>
        </div>
        {!isCollapsed && (
          <div className="mt-2">
            {stage.COMMAND === "LOAD_FROM_JSON_STRING" && (
              <div>
                <p className="text-xs">This stage loads data from a JSON string. No parameters required.</p>
              </div>
            )}
            {stage.COMMAND === "SINGLE_INDEX" && (
              <div>
                <label className="label text-xs">
                  <span className="label-text">Index:</span>
                </label>
                <input
                  type="number"
                  value={stage.PARAMS.index}
                  onChange={(e) => handleParamChange("index", parseInt(e.target.value))}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            )}
            {stage.COMMAND === "DICT_DRILL_DOWN" && (
              <div>
                <label className="label text-xs">
                  <span className="label-text">Field:</span>
                </label>
                <input
                  type="text"
                  value={stage.PARAMS.field}
                  onChange={(e) => handleParamChange("field", e.target.value)}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            )}
            {(stage.COMMAND === "STRING_SPLIT" || stage.COMMAND === "STRING_JOIN") && (
              <div>
                <label className="label text-xs">
                  <span className="label-text">Separator:</span>
                </label>
                <input
                  type="text"
                  value={stage.PARAMS.separator}
                  onChange={(e) => handleParamChange("separator", e.target.value)}
                  className="input input-bordered input-sm w-full max-w-xs"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
