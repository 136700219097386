import { useMemo } from "react";
import { APP_PARAMETER_VAR_DEFAULTS, PLAIN_LLM_CALL_API_KEY, DEMO_APPS } from "../../globals";
import { App, Evalset, Evalcase, Testcase, Testset, Runner } from "../../utils/model";
import {
  AppReturnType,
  EvalcaseReturnType,
  EvalsetCreateType,
  EvalsetReturnType,
  ParameterTypes,
  TestcaseReturnType,
} from "../../utils/interfaces";

export enum AppChoiceSectionNames {
  NO_APPS = "NO_APPS",
  DEMO_APPS = "DEMO_APPS",
  USER_APPS = "USER_APPS",
}

export interface AppChoice {
  section_name: AppChoiceSectionNames;
  api_key: string;
  app_name: string;
  id: string | null;
}

export enum LoadMethod {
  BLANK = "BLANK",
  CUSTOM = "CUSTOM",
  APP_DEMO_VALUES = "APP_DEMO_VALUES",
  EVALSET = "EVALSET",
  EVALCASE = "EVALCASE",
}

export interface LoadSpec {
  appId?: string | null;
  version?: string | null;
  evalsetId?: string | null;
  evalcaseId?: string | null;
  customValues?: any | null;
  loadMethod?: LoadMethod | null;
}

export interface BlankLoadSpec extends LoadSpec {
  appId: string;
  version: string;
  evalsetId: null;
  evalcaseId: null;
  customValues: null;
  loadMethod: LoadMethod.BLANK | null;
}

export interface DemoValuesLoadSpec extends LoadSpec {
  appId: string;
  version: string;
  evalsetId: null;
  evalcaseId: null;
  customValues: null;
  loadMethod: LoadMethod.APP_DEMO_VALUES | null;
}

export interface CustomLoadSpec extends LoadSpec {
  api: string;
  version: string;
  evalsetId: null;
  evalcaseId: null;
  customValues: { [key: string]: any };
  loadMethod: LoadMethod.CUSTOM | null;
}

export interface EvalcaseLoadSpec extends LoadSpec {
  appId: string;
  version: string;
  evalsetId: string;
  evalcaseId: string;
  customValues: null;
  loadMethod: LoadMethod.EVALCASE | null;
}

export interface EvalsetLoadSpec extends LoadSpec {
  appId: string;
  version: string;
  evalsetId: string;
  evalcaseId: null;
  customValues: null;
  loadMethod: LoadMethod.EVALSET | null;
}

export type ValidLoadSpec = BlankLoadSpec | CustomLoadSpec | EvalcaseLoadSpec | EvalsetLoadSpec | DemoValuesLoadSpec;

// ---------------------------------------------------
// FUNCTIONS
// ---------------------------------------------------

export function usePlaygroundAppChoices(userApps: AppReturnType[] | null): AppChoice[] | null {
  const appChoices = useMemo(() => {
    if (userApps == null) {
      return null;
    } else {
      const _appChoices: AppChoice[] = [];
      // Add NO_APPS section
      _appChoices.push({
        section_name: AppChoiceSectionNames.NO_APPS,
        api_key: PLAIN_LLM_CALL_API_KEY,
        app_name: "Just chat with LLM",
        id: userApps.map((x) => x.api_key).includes(PLAIN_LLM_CALL_API_KEY)
          ? userApps.find((x) => x.api_key === PLAIN_LLM_CALL_API_KEY)!.id
          : null,
      });
      // Add DEMO_APPS section
      DEMO_APPS.filter((app) => app.api_key !== PLAIN_LLM_CALL_API_KEY).forEach((app) => {
        _appChoices.push({
          section_name: AppChoiceSectionNames.DEMO_APPS,
          api_key: app.api_key,
          app_name: app.name,
          id: userApps.map((x) => x.api_key).includes(app.api_key)
            ? userApps.find((x) => x.api_key === app.api_key)!.id
            : null,
        });
      });
      // Add USER_APPS section
      userApps
        .filter((app) => !DEMO_APPS.map((x) => x.api_key).includes(app.api_key))
        .forEach((app) => {
          _appChoices.push({
            section_name: AppChoiceSectionNames.USER_APPS,
            api_key: app.api_key,
            app_name: app.name,
            id: app.id,
          });
        });
      return _appChoices;
    }
  }, [userApps]);
  return appChoices;
}

export const getEvalOrder = (
  evalcases: EvalcaseReturnType[] | null,
  testcases: TestcaseReturnType[] | null,
  frozenTestcases: TestcaseReturnType[] | null,
): (EvalcaseReturnType | null)[] | null => {
  // This function is used to get the order of evalcases to match the order of testcases
  // Testcases are the ones loaded currently in the UI
  // Frozen testcases are the archived ones that were used for this evalset
  // Archived testcases always have snapshot_source set to the id of the original testcase
  // So we can match either
  if (testcases == null || frozenTestcases == null || evalcases == null) {
    return null;
  }

  let _evalcaseMatches = testcases.map((testcase) => {
    // Is this ever the case? Perhaps if it's a brand new testcase that hasn't been pushed to server yet
    if (!testcase?.id) {
      return null;
    }

    // If the testcase directly matches an evalcase, return that
    const directMatch = evalcases.find((evalcase) => evalcase.datacase_id === testcase.id);
    if (directMatch != null) {
      return directMatch;
    }

    // If the testcase matches a frozen testcase, return the corresponding evalcase
    const directMatchViaFrozen = frozenTestcases.find(
      (frozenTestcase) => frozenTestcase.snapshot_source === testcase.id,
    );
    if (directMatchViaFrozen != null) {
      // If we have a match via the frozen testcases, there should always be a corresponding evalcase
      const evalcaseMatchingFrozenTestcase = evalcases.find(
        (evalcase) => evalcase.datacase_id === directMatchViaFrozen.id,
      );
      if (evalcaseMatchingFrozenTestcase != null) {
        return evalcaseMatchingFrozenTestcase;
      }
    }

    // If the testcase snapshot_source is the same as the frozen testcase snapshot_source, return the corresponding evalcase
    const matchViaFrozenSource = frozenTestcases.find(
      (frozenTestcase) => frozenTestcase.snapshot_source === testcase.snapshot_source,
    );
    if (matchViaFrozenSource != null) {
      // If we have a match via the frozen testcases, there should always be a corresponding evalcase
      const evalcaseMatchingFrozenSource = evalcases.find(
        (evalcase) => evalcase.datacase_id === matchViaFrozenSource.id,
      );
      if (evalcaseMatchingFrozenSource != null) {
        return evalcaseMatchingFrozenSource;
      }
    }

    return null;
  });

  return _evalcaseMatches;
};
