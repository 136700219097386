import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
}

const Modal = ({ isOpen, closeModal, children }: ModalProps) => {
  // NOTE closeModal is needed to enable clickaway functionality
  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog
        className="fixed h-screen w-screen inset-0 z-10 overflow-y-auto flex items-center justify-center p-16"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed h-screen w-screen inset-0 bg-black/60" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {children}
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Modal;
