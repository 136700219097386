import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { TESTSET_ENDPOINT, DEMO_TESTSETS } from "../../globals";
import { useNavigate, useMatch } from "react-router-dom"; // Changed here
import { PlusIcon, EllipsisHorizontalIcon, ClipboardIcon } from "@heroicons/react/20/solid";
import { Menu, MenuItemProps } from "@headlessui/react";
import { Breadcrumbs } from "../../presentation_components/primitives/Breadcrumbs";
import moment from "moment";
import { DemoCard } from "../../presentation_components/DemoCard";
import { Testcase, Testset } from "../../utils/model";
import { NewTestsetModal } from "../../presentation_components/modals/NewTestsetModal";
import urlJoin from "url-join";
import { tenantedRouteBuilder } from "../../utils/tenantManager";

export default function Testsets() {
  const navigate = useNavigate(); // Changed here
  const match = useMatch("/*"); // Changed here - adjust the pattern based on your routing setup

  const [testSets, setTestSets] = useState<Testset[]>([]);
  const [newTestsetModalOpen, setNewTestsetModalOpen] = useState(false);

  useEffect(() => {
    Testset.fetchAll().then((x) => {
      setTestSets(x);
    });
  }, []);

  return (
    <div className="platform-container flex flex-col min-h-screen">
      <NewTestsetModal isOpen={newTestsetModalOpen} setIsOpen={setNewTestsetModalOpen} />
      <Breadcrumbs routes={[{ name: "Testsets", href: tenantedRouteBuilder("/testset") }]} />

      <div className="flex flex-row justify-between items-center pb-6 pt-8">
        <h1>My Test sets</h1>
        <div className="flex flex-row items-center">
          <button className="btn btn-primary text-white" onClick={() => setNewTestsetModalOpen(true)}>
            Create New
          </button>
        </div>
      </div>

      <div className="flex flex-row flex-wrap">
        {testSets.map((testSet) => (
          <a
            key={testSet.id}
            className="link-reset flex card card-bordered bg-white shadow mr-8 mb-8 hover:shadow-md"
            href={urlJoin(window.location.href, testSet.id.toString())}
          >
            <div className="card-actions z-[1] justify-end m-2 mb-0"></div>

            <div className="card-body pt-4 space-y-4">
              <div className="flex justify-between items-center space-x-8">
                <h3>{testSet.name}</h3>
                <div className="dropdown dropdown-end flex flex-col justify-center">
                  <Menu>
                    <Menu.Button as="button" tabIndex={0}>
                      <EllipsisHorizontalIcon className="simple-icon-button" />
                    </Menu.Button>
                    <Menu.Items
                      as="ul"
                      className="dropdown-content z-[1] menu p-2 shadow bg-mainlightgray rounded-box w-52"
                    >
                      <Menu.Item as="li">
                        {({ active, close }: any) => (
                          <div
                            className={`${active && "bg-blue-500"}`}
                            onClick={(e) => {
                              e.preventDefault();
                              close();

                              Promise.all(testSet.datacase_ids.map((id) => Testcase.fetch(id))).then((testcases) => {
                                Testset.duplicate(testSet, testcases).then(() => {
                                  setTestSets((prevTestsets) => [...prevTestsets, testSet]);
                                });
                              });
                            }}
                          >
                            Duplicate
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item as="li">
                        {({ active, close }: any) => (
                          <div
                            className={`${active && "bg-blue-500"}`}
                            onClick={(e) => {
                              e.preventDefault();
                              close();

                              toast.info("Deleting...");

                              Testset.delete(testSet.id)
                                .then(() => {
                                  toast.success("Test set deleted");
                                  setTestSets((prevTestsets) => prevTestsets.filter((x) => x.id !== testSet.id));
                                })
                                .catch(() => {
                                  toast.error("There was an error deleting this test set.");
                                });
                            }}
                          >
                            Delete
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                </div>
              </div>
              <div className="w-full flex flex-row items-center justify-between">
                <div className="text-maindarkgray text-sm">Created {moment(testSet.created).format("Do MMM YYYY")}</div>

                <div className="badge badge-secondary whitespace-nowrap ml-4">{testSet?.datacase_ids?.length} rows</div>
              </div>
            </div>
          </a>
        ))}
      </div>

      {/* Test Set Library Section */}
      <div className="mt-auto space-y-4">
        <hr className="border-t border-mainbordergray" />
        <h2>Test sets library</h2>

        <div className="flex flex-row flex-wrap justify-start">
          {DEMO_TESTSETS.map((testset, index) => (
            <DemoCard
              className="mr-4 mb-4 w-96"
              title={testset.name}
              onClick={() => Testset.createDemoTestset(testset.name).then((x) => setTestSets((prev) => [...prev, x]))}
              key={index}
            >
              <div className="flex justify-between space-x-4">
                <div className="text-maindarkgray text-xs">{testset.hook}</div>
                <div className="badge badge-secondary whitespace-nowrap">{testset.demoData.length} rows</div>
              </div>
            </DemoCard>
          ))}
        </div>
      </div>
    </div>
  );
}
