import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { Breadcrumbs, BreadcrumbItem } from "../../presentation_components/primitives/Breadcrumbs";
import { TestcaseEditor } from "../../presentation_components/TestcaseEditor";
import { Testset, Testcase, Evaluatorconfig } from "../../utils/model";
import { tenantedRouteBuilder } from "../../utils/tenantManager";
import { EvaluatorconfigReturnType } from "../../utils/interfaces";
import { useEvaluatorconfigs } from "../../hooks/useEvaluatorconfigs";

const TestsetEditor = () => {
  const params = useParams<{ id: string }>();

  const [testset, setTestset] = useState<Testset | null>(null);
  const [testcases, setTestcases] = useState<Testcase[] | null>(null);

  const { evaluatorconfigsByTestcaseId, setEvaluatorconfigs } = useEvaluatorconfigs(testcases);

  const sortedTestcases = useMemo(() => {
    if (testcases == null) {
      return null;
    }
    return Testset.sortTestcases(testcases);
  }, [testcases]);

  // Initial loading or when params.id changes (which is when the user navigates to a different testset)
  useEffect(() => {
    if (params.id) {
      Testset.fetchTestsetAndTestcases(params.id).then(([testsetData, testcasesData]) => {
        setTestset(testsetData);
        setTestcases(testcasesData);
      });
    }
  }, [params.id]);

  if (sortedTestcases == null) {
    return <div>Loading...</div>;
  }

  const breadcrumbRoutes: BreadcrumbItem[] = [
    { name: "Testsets", href: tenantedRouteBuilder("/testset") },
    {
      name: testset?.name || "Loading...",
      href: "",
      editCallback: (x: string) => {
        setTestset((prev) => (prev ? { ...prev, name: x } : null));
        if (testset) {
          Testset.update(testset.id, { name: x }).then((data) => setTestset(data));
        }
      },
    },
  ];

  return (
    <div className="platform-container space-y-8">
      <Breadcrumbs routes={breadcrumbRoutes} appendArrow={false} />

      {sortedTestcases.map((testcase, index) => (
        <div key={testcase.id} className="h-96 space-y-4">
          <TestcaseEditor
            testcase={testcase}
            testcases={sortedTestcases}
            setTestcases={setTestcases}
            evaluatorconfigs={evaluatorconfigsByTestcaseId[testcase.id]}
            setEvaluatorconfigs={(newEvaluatorconfigs: Evaluatorconfig[]) =>
              setEvaluatorconfigs(testcase.id, newEvaluatorconfigs)
            }
          />
        </div>
      ))}

      <button
        className="btn btn-primary w-28"
        onClick={() => {
          if (testset) {
            Testset.addBlankTestcase(testset, sortedTestcases).then(([newTestset, newTestcase]) => {
              // Append the new testcase to the list of testcases
              setTestcases([newTestcase, ...(testcases || [])]);
              setTestset(newTestset);
            });
          }
        }}
      >
        Add Row
      </button>
    </div>
  );
};

export default TestsetEditor;
