import React from "react";
import { parameterNameProcessor } from "../../utils/utils";
import { LinkIcon } from "@heroicons/react/24/outline";
import { DropdownCaseBare, DropdownDivider, DropdownOption } from "../primitives/Dropdown";
import { APP_PARAMETER_VAR_DEFAULTS } from "../../globals";
import { ParameterTypes, VarSourceType } from "../../utils/interfaces";
import { GetParameterMatchFn, SetParameterMatchFn } from "../../utils/useParameterMatches";

interface DatasetLinkButtonProps {
  parameter: ParameterTypes;
  uniqueTestcaseFields: string[] | null;
  getParameterMatch: GetParameterMatchFn;
  setParameterMatch: SetParameterMatchFn;
  allLinked: boolean;
}

export const DatasetLinkButton: React.FC<DatasetLinkButtonProps> = ({
  parameter,
  uniqueTestcaseFields,
  getParameterMatch,
  setParameterMatch,
  allLinked,
}) => {
  if (!uniqueTestcaseFields) {
    return null;
  }

  const thisIsLinked = getParameterMatch(parameter.name)?.source === VarSourceType.DATASET;

  const linkColour = thisIsLinked ? "text-maingreen" : allLinked ? "text-mainbordergray" : "text-red-700 stroke-2 ";
  const tooltipText = thisIsLinked
    ? "Parameter linked"
    : allLinked
      ? "Testcase inputs are linked"
      : "Testcase inputs are not linked";

  return (
    <DropdownCaseBare
      label={
        <div className="tooltip tooltip-right" data-tip={tooltipText}>
          <LinkIcon className={`simple-icon-button ${linkColour}`} />
        </div>
      }
      contentClassName="min-w-[180px]"
    >
      <DropdownDivider label={"Link to testcase fields"} divider={false}></DropdownDivider>

      {uniqueTestcaseFields.map((field) => (
        <DropdownOption
          className="btn-xs mb-1"
          key={field}
          selected={
            getParameterMatch(parameter.name)?.source === VarSourceType.DATASET &&
            getParameterMatch(parameter.name)?.value === field
          }
          onClick={() => {
            setParameterMatch(parameter.name, VarSourceType.DATASET, field);
          }}
        >
          {parameterNameProcessor(field)}
        </DropdownOption>
      ))}
      <DropdownDivider label="No link"></DropdownDivider>
      <DropdownOption
        className="btn-xs"
        onClick={() => {
          setParameterMatch(
            parameter.name,
            VarSourceType.CONFIG,
            structuredClone(APP_PARAMETER_VAR_DEFAULTS[parameter.param_type]),
          );
        }}
      >
        No Link
      </DropdownOption>
    </DropdownCaseBare>
  );
};
