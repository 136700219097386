import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";

interface DropdownCaseProps extends React.HTMLAttributes<HTMLDivElement> {
  label: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

export const DropdownCaseBare: React.FC<DropdownCaseProps> = ({
  label,
  children,
  className,
  contentClassName,
  ...rest
}) => {
  return (
    <Menu className="relative" as="div">
      <Menu.Button
        as="button"
        tabIndex={0}
        className={`justify-between flex flex-row flex-nowrap normal-case ${className}`}
      >
        {label}
      </Menu.Button>

      <Menu.Items
        as="ul"
        className={`absolute dropdown-content z-[1] menu p-4 shadow border bg-mainlightgray rounded-md min-w-[148px] ${contentClassName}`}
      >
        {children}
      </Menu.Items>
    </Menu>
  );
};

interface DropdownCaseProps extends React.HTMLAttributes<HTMLDivElement> {
  label: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  dropdownClassName?: string;
  ChangeIcon?: any;
}

export const DropdownCase: React.FC<DropdownCaseProps> = ({
  label,
  children,
  className = "",
  dropdownClassName = "",
  ChangeIcon = ChevronDownIcon,
  ...rest
}) => {
  return (
    <div className={`dropdown ${dropdownClassName}`} {...rest}>
      <Menu>
        <Menu.Button
          as="label"
          tabIndex={0}
          className={`btn justify-between flex flex-row flex-nowrap normal-case ${className}`}
        >
          <div className="truncate ">{label}</div>
          <ChangeIcon className="w-5 h-5" />
        </Menu.Button>

        <Menu.Items
          as="ul"
          className="dropdown-content z-40 menu p-4 shadow bg-mainlightgray rounded-md w-full min-w-28"
        >
          {children}
        </Menu.Items>
      </Menu>
    </div>
  );
};

interface DropdownOptionProps extends React.HTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  className?: string;
  [key: string]: any;
}

export const DropdownOption: React.FC<DropdownOptionProps> = ({ selected, className, ...rest }) => {
  return (
    <Menu.Item
      as="button"
      className={`
      btn btn-ghost font-normal flex flex-row justify-start items-center w-full rounded-md text-sm capitalize truncate
      ${className}
      ${selected ? "bg-primary text-white" : ""}
      `}
      {...rest}
    ></Menu.Item>
  );
};

interface DropdownDividerProps {
  label?: React.ReactNode;
  divider?: boolean;
}

export const DropdownDivider: React.FC<DropdownDividerProps> = ({ label, divider = true }) => {
  return (
    <div>
      {divider && <hr className="mt-2 mb-2"></hr>}
      <div className="text-xs text-maindarkgray mb-2">{label}</div>
    </div>
  );
};
