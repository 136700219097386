import React, { useState, useEffect, Fragment, useRef } from "react";
import { APP_ENDPOINT, DOCS_LINK } from "../globals";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { App } from "../utils/model";
import { AppReturnType } from "../utils/interfaces";
import { generateRandomAPIKey } from "../utils/utils";

interface NewAppModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onSuccess: (app: AppReturnType) => void;
  alreadyOwnedApps: AppReturnType[];
}

const NewAppModal: React.FC<NewAppModalProps> = ({ isOpen, setIsOpen, onSuccess, alreadyOwnedApps }) => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const name = ((event.target as HTMLFormElement).elements.namedItem("name") as HTMLInputElement)?.value;
    const api_key = ((event.target as HTMLFormElement).elements.namedItem("apikey") as HTMLInputElement)?.value;

    if (alreadyOwnedApps.map((app) => app.api_key).includes(api_key)) {
      toast.error("You already own an app with this API key");
    } else {
      App.create({ name, api_key })
        .then((newApp) => {
          onSuccess(newApp);
          formRef.current?.reset(); // reset form
          setIsOpen(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Error creating app. Make sure the app has been activated before first link.");
        });
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="w-full text-lg font-medium leading-6 text-titlebrown flex justify-between items-center"
                  >
                    Link A New App
                    <a
                      href="https://replit.com/@composo/Composo-Hello-World-Python#main.py"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <button type="button" className="btn btn-secondary text-white btn-sm">
                        Host App on Replit
                      </button>
                    </a>
                  </Dialog.Title>
                  <form
                    ref={formRef}
                    method="dialog"
                    className="flex flex-col items-end justify-end"
                    onSubmit={handleSubmit}
                  >
                    <div className="mt-4 form-control w-full flex flex-col items-start justify-start">
                      <span className="label-text mb-4">
                        Apps in Composo are always connected to a codebase. To learn more, see our{" "}
                        <a href={DOCS_LINK} target="_blank" rel="noopener noreferrer">
                          linking guide
                        </a>
                        .
                      </span>
                      <label className="label">
                        <span className="label-text">Name</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="App Name"
                        className="input input-bordered w-full max-w-xs"
                        pattern="^[a-zA-Z0-9_\-. ]+$"
                        onInvalid={(e) =>
                          (e.target as HTMLInputElement).setCustomValidity(
                            "Invalid name. Please use only letters, numbers, spaces, and special characters - _ .",
                          )
                        }
                        onInput={(e) => (e.target as HTMLInputElement).setCustomValidity("")}
                        required
                      />
                    </div>

                    <div className="mt-4 form-control w-full flex flex-col items-start justify-start">
                      <label className="label w-full max-w-xs flex flex-row justify-between">
                        <span className="label-text">API Key</span>
                        <span
                          className="underline text-sm text-blue-600 hover:text-blue-800 hover:cursor-pointer visited:text-purple-600"
                          onClick={() => {
                            const randomApiKey = generateRandomAPIKey();
                            navigator.clipboard.writeText(randomApiKey);
                            toast.success("API Key copied to clipboard.");

                            // also write to API Key input
                            (
                              (formRef.current as HTMLFormElement).elements.namedItem("apikey") as HTMLInputElement
                            ).value = randomApiKey;
                          }}
                        >
                          Create New?
                        </span>
                      </label>
                      <input
                        type="text"
                        name="apikey"
                        placeholder="API Key"
                        className="input input-bordered w-full max-w-xs"
                        pattern="^[a-zA-Z0-9\-]+$"
                        onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity("Invalid API Key.")}
                        onInput={(e) => (e.target as HTMLInputElement).setCustomValidity("")}
                        required
                      />
                    </div>

                    <button type="submit" className="mt-8 btn btn-primary text-white max-w-xs">
                      Submit
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default NewAppModal;
