import { useCallback, useEffect, useState } from "react";

// Hook to get the size of an element
export const useElementSize = (ref: React.RefObject<HTMLElement>) => {
  const [size, setSize] = useState({
    height: 0,
    width: 0,
  });

  // Function to handle the resizing event
  const handleResize = useCallback(() => {
    if (ref.current) {
      setSize({
        height: ref.current.offsetHeight,
        width: ref.current.offsetWidth,
      });
    }
  }, [ref]);

  useEffect(() => {
    // Set the size initially
    handleResize();

    for (let i = 100; i < 1500; i += 50) {
      setTimeout(() => {
        handleResize();
      }, i);
    }

    // Add the event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Remove the event listener in the cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]); // Dependency on handleResize to update when ref changes

  return size;
};
