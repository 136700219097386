import { styled } from "@mui/material/styles";
import MuiSlider, { SliderProps } from "@mui/material/Slider";
import React, { FC } from "react";

const CustomSlider = styled(MuiSlider)({
  root: {
    width: "100%",
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    border: "2px solid black",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "inherit",
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: "transparent",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    background: "linear-gradient(to right, #267df6, #f17721)",
    opacity: 0.8,
  },
});

interface TemperatureSliderProps extends Omit<SliderProps, "onChange"> {
  temperature: number | null;
  setTemperature: (value: number) => void;
  allowableMin: number;
  allowableMax: number;
  step: number;
  className?: string;
  disabled?: boolean;
}

export const TemperatureSlider: FC<TemperatureSliderProps> = ({
  temperature,
  setTemperature,
  allowableMin,
  allowableMax,
  step,
  className,
  disabled,
}) => {
  if (temperature == null) {
    return null;
  }

  return (
    <div>
      <div className="w-full flex flex-row justify-center items-center">{temperature}</div>
      <CustomSlider
        className={`${className} overflow-x-visible`}
        value={temperature}
        step={step}
        min={allowableMin}
        max={allowableMax}
        onChange={(e, newValue) => setTemperature(newValue as number)}
        disabled={disabled}
        sx={{ width: "100%" }}
      />
    </div>
  );
};
