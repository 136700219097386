import React, { useState, useEffect, useRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { Breadcrumbs } from "../../presentation_components/primitives/Breadcrumbs";
import { toast } from "react-toastify";
import { ProgressBadge } from "../../presentation_components/primitives/EvaluationBadges";
import { InputBoxWithModal } from "../../presentation_components/primitives/InputBoxWithModal";
import moment from "moment";
import { Evalset } from "../../utils/model";
import { tenantedRouteBuilder } from "../../utils/tenantManager";

export default function Evaluations() {
  const [evalsets, setEvalsets] = useState<Evalset[]>([]);

  const fetchRuns = () => {
    Evalset.fetchAll().then((data) => {
      const joinedData = data.filter((run) => run.dataset_id != null);
      setEvalsets(joinedData);
    });
  };

  useEffect(() => {
    fetchRuns();
  }, []);

  return (
    <div className="platform-container">
      <Breadcrumbs routes={[{ name: "Evaluations", href: tenantedRouteBuilder("/evaluation") }]} appendArrow={true} />

      <div className="flex flex-row justify-between items-center pb-6 pt-8">
        <h1>My Evaluations</h1>
      </div>

      <div className="flex flex-col overflow-hidden">
        <table className="table w-full table-pin-rows mb-36">
          <thead className="w-full table-header">
            <tr className="">
              <th className="">Evaluation Run</th>
              <th className="">App</th>
              <th className="">App version</th>
              <th className=" ">Date of Run</th>
              <th className="">Result</th>
              <th className="">Status</th>
              <td className="">Annotation</td>
              <th className=""></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(evalsets)
              ? evalsets
                  // .sort((a, b) => {
                  //   const dateA = new Date(a.created);
                  //   const dateB = new Date(b.created);

                  //   if (dateA < dateB) return 1;
                  //   if (dateA > dateB) return -1;

                  //   return a.name.localeCompare(b.name);
                  // })
                  .map((evalset) => {
                    return (
                      <tr
                        key={evalset.id}
                        className="hover:bg-mainlightgray hover:cursor-pointer"
                        onClick={() => {
                          window.location.href = tenantedRouteBuilder(
                            "/playground?mode=eval&load1=".concat(evalset.id),
                          );
                        }}
                      >
                        <td className="">{evalset?.name}</td>
                        <td className="">{evalset.app_name}</td>
                        <td className="">{evalset.version}</td>
                        <td className="">
                          {new Date(evalset.created).toLocaleString("en-US", {
                            weekday: "short",
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: false,
                          })}
                        </td>
                        <td className=""></td>
                        <td className="">
                          <ProgressBadge run={evalset} />
                        </td>
                        <td className="">
                          <InputBoxWithModal
                            value={evalset.annotation}
                            setValue={(new_annotation: string) => {
                              Evalset.update(evalset.id, { annotation: new_annotation }).then((data) => {
                                setEvalsets((prevRuns) => {
                                  return prevRuns.map((r) => {
                                    if (r.id === data.id) {
                                      return data;
                                    }
                                    return r;
                                  });
                                });
                              });
                            }}
                            className="border rounded-lg border-mainlightgray"
                          />
                        </td>
                        <td className="">
                          <XMarkIcon
                            className="simple-icon-button"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              Evalset.delete(evalset.id).then((data) => {
                                toast.success("Evaluation deleted.");
                                setEvalsets((prevRuns) => prevRuns.filter((r) => r.id !== evalset.id));
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}
