import React, { useEffect, useState, useRef } from "react";
import { HashtagIcon } from "@heroicons/react/20/solid";
import { ArrowLongRightIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CRITERIA_DETAILS } from "../globals";
import { toast } from "react-toastify";
import { CriteriaEditor } from "./CriteriaEdit";
import { useUniqueTestcaseFields } from "../hooks/useUniqueTestcaseFields";

import { ConversationHistory } from "./parameter_editors/ConversationHistory";
import TextareaAutosize from "react-textarea-autosize";
import { Bars3BottomLeftIcon, LanguageIcon } from "@heroicons/react/20/solid";
import { EditableTitleDisplay } from "./EditableTitleDisplay";
import { useElementSize } from "../hooks/useElementSize";
import { EvaluatorConfigJoinsDatacase, Evaluatorconfig, Testcase, Testset } from "../utils/model";

import {
  CriteriaSelectorEnum,
  CriteriaTypes,
  EvaluatorconfigCreateType,
  EvaluatorconfigReturnType,
  EvaluatorconfigUpdateType,
  TestcaseReturnType,
} from "../utils/interfaces";

interface DatasetTextFieldProps {
  text: string;
  setText: (text: string) => void;
}

const DatasetTextField: React.FC<DatasetTextFieldProps> = ({ text, setText }) => {
  const [localText, setLocalText] = useState(text);

  useEffect(() => {
    setLocalText(text);
  }, [text]);

  return (
    <TextareaAutosize
      className="textarea textarea-primary textarea-xs w-full resize-none bg-white no-scrollbar"
      value={localText}
      onChange={(e) => setLocalText(e.target.value)}
      onBlur={(e) => setText(e.target.value)}
      minRows={1}
      maxRows={8}
    ></TextareaAutosize>
  );
};

interface DatasetNumberFieldProps {
  value: string;
  setValue: (value: string) => void;
}

const DatasetNumberField: React.FC<DatasetNumberFieldProps> = ({ value, setValue }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <input
      type="number"
      className="input input-sm bg-white"
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value)}
    />
  );
};

enum TestcaseInputType {
  STRING = "STRING",
  OBJECT = "OBJECT",
  NUMBER = "NUMBER",
}

const classifyInput = (value: any): TestcaseInputType.STRING | TestcaseInputType.NUMBER | TestcaseInputType.OBJECT => {
  // If it's an object (and not null) or a function, classify it as TestcaseInputType.OBJECT
  if (value !== null && (typeof value === "object" || typeof value === "function")) {
    return TestcaseInputType.OBJECT;
  }

  // Attempt to cast the value to a number and check if it isn't NaN
  const numberValue = Number(value);
  if (!isNaN(numberValue)) {
    return TestcaseInputType.NUMBER;
  }

  // Try to cast to a string if it's not a number or an object
  const stringValue = String(value);
  if (typeof stringValue === "string") {
    return TestcaseInputType.STRING;
  }

  // Default to TestcaseInputType.STRING (every value in JavaScript can be represented as a string)
  return TestcaseInputType.STRING;
};

interface TypeSwitchButtonProps {
  type: TestcaseInputType;
  setType: (type: TestcaseInputType.STRING | TestcaseInputType.OBJECT | TestcaseInputType.NUMBER) => void;
  setValue: (value: string | number | any[]) => void;
}

const TypeSwitchButton: React.FC<TypeSwitchButtonProps> = ({ type, setType, setValue }) => {
  const onClick = (currentType: TestcaseInputType.STRING | TestcaseInputType.OBJECT | TestcaseInputType.NUMBER) => {
    const nextTypeMapping: {
      [key: string]: TestcaseInputType.STRING | TestcaseInputType.OBJECT | TestcaseInputType.NUMBER;
    } = {
      STRING: TestcaseInputType.OBJECT,
      OBJECT: TestcaseInputType.NUMBER,
      NUMBER: TestcaseInputType.STRING,
    };

    const resetValueMapping: { [key: string]: string | number | any[] } = {
      STRING: "",
      OBJECT: [],
      NUMBER: 0,
    };

    setValue(resetValueMapping[nextTypeMapping[currentType]]);
    setType(nextTypeMapping[currentType]);
  };

  return (
    <div>
      <div className="tooltip tooltip-left" data-tip="Change input type">
        {type === TestcaseInputType.STRING ? (
          <LanguageIcon className="tiny-icon-button" onClick={() => onClick(TestcaseInputType.OBJECT)} />
        ) : type === TestcaseInputType.OBJECT ? (
          <Bars3BottomLeftIcon className="tiny-icon-button" onClick={() => onClick(TestcaseInputType.NUMBER)} />
        ) : type === TestcaseInputType.NUMBER ? (
          <HashtagIcon className="tiny-icon-button" onClick={() => onClick(TestcaseInputType.STRING)} />
        ) : null}
      </div>
    </div>
  );
};

interface TestCaseInputBlockProps {
  testcase: TestcaseReturnType;
  setTestcase: (testcase: TestcaseReturnType) => void; // unfortunately this can't be a proper setState type because the state is actually held as a list
  addNewField: () => void;
  deleteField: (fieldName: string) => void;
  renameAllFields: (oldField: string, newField: string) => void;
}

const TestCaseInputBlock: React.FC<TestCaseInputBlockProps> = ({
  testcase,
  setTestcase,
  addNewField,
  deleteField,
  renameAllFields,
}) => {
  const [testCaseTypes, setTestCaseTypes] = useState<Record<string, TestcaseInputType> | null>(null);
  const [typeOverrides, setTypeOverrides] = useState<Record<string, TestcaseInputType>>({});

  const uniqueTestcaseVariables = useUniqueTestcaseFields([testcase]);

  useEffect(() => {
    const initialTestCaseTypes = Object.keys(testcase.inputs).reduce<Record<string, TestcaseInputType>>(
      (accumulator, key) => {
        const input = testcase.inputs[key];

        const type = classifyInput(input);

        accumulator[key] = type;

        return accumulator;
      },
      {},
    );

    const testCaseTypes = Object.keys(initialTestCaseTypes).reduce<Record<string, TestcaseInputType>>(
      (accumulator, key) => {
        const type = typeOverrides[key] || initialTestCaseTypes[key];
        accumulator[key] = type;
        return accumulator;
      },
      {},
    );

    setTestCaseTypes(testCaseTypes);
  }, [testcase, typeOverrides]);

  if (testCaseTypes === null || testcase.inputs === null) {
    return <div>Loading...</div>;
  }

  const setTestcaseValue = (value: string | number | any[], testcaseVariable: string) => {
    const oldInputs = testcase.inputs;
    const newInputs = { ...oldInputs, [testcaseVariable]: value };
    Testcase.update(testcase.id, { inputs: newInputs });

    setTestcase({ ...testcase, inputs: newInputs }); // would love to do this as a function, but hard to pass that down
  };

  return (
    <div className="flex flex-col space-y-2">
      {(uniqueTestcaseVariables || []).map((testcaseVariable, index) => {
        return (
          <div className="flex flex-col justify-start space-y-1" key={index}>
            <div className="flex flex-row flex-nowrap space-x-2 items-center justify-between">
              <EditableTitleDisplay
                text={testcaseVariable}
                rename={(newField) => renameAllFields(testcaseVariable, newField)}
              />
              <div className="flex flex-row flex-nowrap space-x-2 items-center">
                <div className="tooltip tooltip-left" data-tip="Delete input field for all test cases">
                  <TrashIcon className="tiny-icon-button text-red-700" onClick={() => deleteField(testcaseVariable)} />
                </div>

                <TypeSwitchButton
                  type={testCaseTypes[testcaseVariable]}
                  setType={(newType) => {
                    setTypeOverrides((prev) => {
                      const newOverrides = { ...prev };
                      newOverrides[testcaseVariable] = newType;
                      return newOverrides;
                    });
                  }}
                  setValue={(newValue) => setTestcaseValue(newValue, testcaseVariable)}
                />
              </div>
            </div>
            {testCaseTypes[testcaseVariable] === TestcaseInputType.STRING ? (
              <DatasetTextField
                text={testcase.inputs[testcaseVariable]}
                setText={(newValue) => setTestcaseValue(newValue, testcaseVariable)}
              />
            ) : testCaseTypes[testcaseVariable] === TestcaseInputType.OBJECT ? (
              <ConversationHistory
                messages={testcase.inputs[testcaseVariable]}
                setMessages={(newValue) => setTestcaseValue(newValue, testcaseVariable)}
                xs={true}
              />
            ) : (
              <DatasetNumberField
                value={testcase.inputs[testcaseVariable]}
                setValue={(newValue) => setTestcaseValue(newValue, testcaseVariable)}
              />
            )}
          </div>
        );
      })}
      <div className="flex flex-row justify-center w-full pt-2">
        <div className="tooltip" data-tip="Add new field to all test cases">
          <PlusCircleIcon className="simple-icon-button" onClick={addNewField} />
        </div>
      </div>
    </div>
  );
};

interface CriteriaButtonProps {
  criteriaType: CriteriaSelectorEnum;
  className?: string;
  onClick?: () => void;
  selected?: boolean;
  viable?: boolean;
}

const CriteriaButton: React.FC<CriteriaButtonProps> = ({ criteriaType, className, onClick, selected, viable }) => {
  return (
    <button
      className={`max-w-full flex flex-row justify-start btn btn-xs text-xs ${selected ? "" : "btn-outline"} ${
        criteriaType === CriteriaSelectorEnum.NO_CRITERIA ? "btn-ghost" : "btn-primary"
      } rounded-full w-32 ${className}`}
      onClick={onClick}
    >
      <div className="w-full truncate">{CRITERIA_DETAILS[criteriaType].displayName}</div>
    </button>
  );
};

interface FullCriteriaEditorProps {
  evaluatorconfigs: EvaluatorconfigReturnType[] | null;
  setEvaluatorconfigs: (x: EvaluatorconfigReturnType[]) => void;
  testcase: TestcaseReturnType;
  launchAsModal: () => void;
}

const FullCriteriaEditor: React.FC<FullCriteriaEditorProps> = ({
  evaluatorconfigs,
  setEvaluatorconfigs,
  testcase,
  launchAsModal,
}) => {
  const [selectedCriteriaId, setSelectedCriteriaId] = useState<string | null>(null);

  if (evaluatorconfigs == null) {
    return <div>Loading...</div>;
  }

  const selectedCriteria = selectedCriteriaId ? evaluatorconfigs.find((x) => x.id === selectedCriteriaId) : null;

  const updateEvaluatorconfig = (id: string, newEvaluatorconfig: EvaluatorconfigUpdateType) => {
    Evaluatorconfig.update(id, newEvaluatorconfig).then((updatedEvaluatorconfig) => {
      let prevEvaluatorconfigs = evaluatorconfigs;
      if (prevEvaluatorconfigs == null) {
        return null;
      } else {
        setEvaluatorconfigs(
          prevEvaluatorconfigs.map((prevEvaluatorconfig) =>
            prevEvaluatorconfig.id === updatedEvaluatorconfig.id ? updatedEvaluatorconfig : prevEvaluatorconfig,
          ),
        );
      }
    });
  };

  const deleteEvaluatorconfig = (id: string) => {
    Evaluatorconfig.delete(id).then(() => {
      let prevEvaluatorconfigs = evaluatorconfigs;
      if (prevEvaluatorconfigs == null) {
        return null;
      } else {
        setEvaluatorconfigs(prevEvaluatorconfigs.filter((prevEvaluatorconfig) => prevEvaluatorconfig.id !== id));
      }
    });
  };

  return (
    <div className="w-full h-full flex flex-row">
      <CriteriaSelector
        evaluatorconfigs={evaluatorconfigs}
        setEvaluatorconfigs={setEvaluatorconfigs}
        testcase={testcase}
        launchAsModal={launchAsModal}
        selectCriteriaCallback={(x) => {
          setSelectedCriteriaId(x);
        }}
        selectedId={selectedCriteriaId}
      />
      <div className="divider divider-horizontal m-0 p-0"></div>
      <div className="grow h-full rounded-md overflow-y-scroll scrollable-content">
        {selectedCriteria != null && selectedCriteriaId != null && evaluatorconfigs.length > 0 ? (
          <CriteriaEditor
            open={true}
            criteria={selectedCriteria}
            updateCriteria={(x) => updateEvaluatorconfig(selectedCriteria.id, x)}
            deleteEvaluatorconfig={() => deleteEvaluatorconfig(selectedCriteria.id)}
          />
        ) : null}
      </div>
    </div>
  );
};

interface CriteriaSelectorProps {
  evaluatorconfigs: EvaluatorconfigReturnType[] | null;
  setEvaluatorconfigs: (x: EvaluatorconfigReturnType[]) => void;
  testcase: TestcaseReturnType;
  launchAsModal: () => void;
  selectCriteriaCallback?: (id: string) => void;
  selectedId?: string | null;
}

const CriteriaSelector: React.FC<CriteriaSelectorProps> = ({
  evaluatorconfigs,
  setEvaluatorconfigs,
  testcase,
  launchAsModal,
  selectCriteriaCallback,
  selectedId,
}) => {
  const callback = selectCriteriaCallback ? selectCriteriaCallback : launchAsModal;

  const addNewEvaluatorconfig = async () => {
    await Evaluatorconfig.create({
      name: "",
      config: {
        type: CriteriaSelectorEnum.NO_CRITERIA,
      },
      pipeline_spec: null,
    }).then(async (newEvaluatorconfig) => {
      setEvaluatorconfigs([...(evaluatorconfigs || []), newEvaluatorconfig]);

      await EvaluatorConfigJoinsDatacase.create({
        evaluatorconfig_id: newEvaluatorconfig.id,
        datacase_id: testcase.id,
      });

      callback(newEvaluatorconfig.id);
    });
  };

  if (evaluatorconfigs == null) {
    return null;
  }

  return (
    <div className="h-full min-w-36 w-36">
      {evaluatorconfigs.map((evaluatorconfig, index) => {
        return (
          <div
            className="flex flex-col justify-center items-center"
            style={{
              height: "12.5%",
            }}
          >
            <CriteriaButton
              criteriaType={evaluatorconfig.config.type}
              viable={evaluatorconfig.config.viable}
              key={evaluatorconfig.id}
              onClick={() => callback(evaluatorconfig.id)}
              selected={evaluatorconfig.id === selectedId}
            />
          </div>
        );
      })}
      {evaluatorconfigs.length < 9 ? (
        <div
          className="flex flex-col justify-center items-center"
          style={{
            height: "12.5%",
          }}
        >
          <button className="simple-icon-button" onClick={addNewEvaluatorconfig}>
            <PlusCircleIcon></PlusCircleIcon>
          </button>
        </div>
      ) : null}
    </div>
  );
};

interface TestcaseEditorProps {
  testcase: TestcaseReturnType | null;
  setTestcases: React.Dispatch<React.SetStateAction<TestcaseReturnType[] | null>>;
  testcases: TestcaseReturnType[] | null;
  evaluatorconfigs: EvaluatorconfigReturnType[] | null;
  setEvaluatorconfigs: (x: EvaluatorconfigReturnType[]) => void;
  launchAsModal?: () => void;
  closeTestcaseModal?: () => void;
  frozen?: boolean;
  className?: string;
  [x: string]: any;
}

export const TestcaseEditor: React.FC<TestcaseEditorProps> = ({
  testcase,
  testcases,
  setTestcases,
  evaluatorconfigs,
  setEvaluatorconfigs,
  launchAsModal = () => {},
  closeTestcaseModal = () => {},
  frozen = false,
  className = "",
  ...rest
}) => {
  // Element height calculations
  const targetRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(targetRef);

  const loadingIncomplete = testcase == null || testcases == null || evaluatorconfigs == null;

  if (loadingIncomplete) {
    closeTestcaseModal();
    return null;
  }

  let inputWidth: number;
  let criteriaWidth: number;
  if (size.width > 700) {
    criteriaWidth = size.width * (5 / 8) - 2;
    inputWidth = size.width - criteriaWidth - 4;
  } else {
    criteriaWidth = 150;
    inputWidth = size.width - criteriaWidth - 4;
  }

  const setTestcase = (newTestcase: TestcaseReturnType) => {
    setTestcases((prevTestcases) => {
      if (prevTestcases == null) {
        return null;
      }
      return prevTestcases.map((prevTestcase) => (prevTestcase.id === newTestcase.id ? newTestcase : prevTestcase));
    });
  };

  return (
    <div className={`relative flex flex-col justify-start w-full h-full ${className}`} {...rest} ref={targetRef}>
      <div
        className={`absolute w-full h-full top-0 left-0 bg-white z-30 opacity-50 ${frozen ? "visible" : "hidden"}`}
      />
      <div className="flex flex-row items-center justify-between px-2 space-x-2" style={{ height: 32 }}>
        <EditableTitleDisplay
          text={testcase.name}
          rename={(newName) => {
            const originalTestcase = { ...testcase };
            setTestcase({ ...testcase, name: newName });
            Testcase.update(originalTestcase.id, { name: newName })
              .then((updatedTestcase) => {
                // Testcase was already updated above
              })
              .catch((x) => {
                toast.error("There was an error updating the test case.");
                setTestcase(originalTestcase);
              });
          }}
          displayClassName="text-maindarkgray text-sm font-semibold capitalize"
        />
        <div className="flex flex-row items-end justify-start space-x-2">
          <div className="tooltip" data-tip="Delete test case">
            <TrashIcon
              className="tiny-icon-button mb-1 text-red-700"
              onClick={() => {
                setTestcases((prevTestcases) => {
                  if (prevTestcases == null) {
                    return null;
                  }
                  return prevTestcases.filter((prevTestcase) => prevTestcase.id !== testcase.id);
                });
                Testcase.delete(testcase.id).catch((x) => {
                  toast.error("There was an error deleting the test case. Reloading...");
                  // refresh the page
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                });
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="relative flex flex-row items-center justify-center bg-white z-auto overflow-y-hidden grow"
        // style={{
        //   height: size.height - 32,
        // }}
      >
        <div
          style={{ width: inputWidth }}
          className="h-full mr-2 flex flex-col bg-mainlightgray rounded-md p-2 space-y-1 border border-mainbordergray shadow overflow-y-auto no-scrollbar z-10"
        >
          <TestCaseInputBlock
            testcase={testcase}
            setTestcase={setTestcase}
            addNewField={() => {
              Testset.addFieldToAllTestcases(testcases).then((newTestcases) => {
                setTestcases(newTestcases);
              });
            }}
            deleteField={(fieldName) => {
              Testset.deleteFieldFromAllTestcases(fieldName, testcases).then((newTestcases) => {
                setTestcases(newTestcases);
              });
            }}
            renameAllFields={(oldField, newField) => {
              Testset.renameFieldInAllTestcases(oldField, newField, testcases).then((newTestcases) => {
                setTestcases(newTestcases);
              });
            }}
          />
        </div>
        {/* Criteria Editing */}
        <div
          className="h-full bg-mainlightgray rounded-md border border-mainbordergray shadow z-10 "
          style={{ width: criteriaWidth }}
        >
          {criteriaWidth > 500 ? (
            <FullCriteriaEditor
              evaluatorconfigs={evaluatorconfigs}
              setEvaluatorconfigs={setEvaluatorconfigs}
              testcase={testcase}
              launchAsModal={launchAsModal}
            />
          ) : (
            <CriteriaSelector
              evaluatorconfigs={evaluatorconfigs}
              setEvaluatorconfigs={setEvaluatorconfigs}
              testcase={testcase}
              launchAsModal={launchAsModal}
            />
          )}
        </div>
        <div className="w-full h-full absolute left-0 top-0 px-2 p-2">
          <div className="h-full w-full border border-mainbordergray bg-mainlightgray shadow"></div>
        </div>
      </div>
    </div>
  );
};
