import { useContext, useEffect, useRef } from "react";
import { makeApiRequestPromise } from "./ApiRequest";
import urlJoin from "url-join";
import {
  APP_ENDPOINT,
  EVALSET_ENDPOINT,
  EVALCASE_ENDPOINT,
  TESTSET_ENDPOINT,
  TESTCASE_ENDPOINT,
  USER_ENDPOINT,
  TENANT_ENDPOINT,
  ASSIGNMENT_ENDPOINT,
  EVALUATORCONFIGJOINSAPP_ENDPOINT,
  EVALUATORCONFIGJOINSDATASET_ENDPOINT,
  EVALUATORCONFIGJOINSDATACASE_ENDPOINT,
  EVALUATORRESULT_ENDPOINT,
  EVALUATORCONFIG_ENDPOINT,
  CRITERIA_DETAILS,
  RUNNER_ENDPOINT,
} from "../globals";
import { DEMO_TESTSETS } from "../globals";

import {
  AppCreateType,
  AppUpdateType,
  AppReturnType,
  ParameterTypes,
  VarSourceType,
  CriteriaTypes,
  CriteriaSelectorEnum,
  UserType,
  TenantType,
  AssignmentCreateType,
  TenantCreateType,
  TenantUpdateType,
  UserUpdateType,
  RunnerCreateType,
  RunnerReturnType,
  PipelineSpec,
} from "./interfaces";
import { TestsetCreateType, TestsetUpdateType, TestsetReturnType } from "./interfaces";
import { TestcaseCreateType, TestcaseUpdateType, TestcaseReturnType } from "./interfaces";
import { EvalsetCreateType, EvalsetUpdateType, EvalsetReturnType } from "./interfaces";
import { EvalcaseCreateType, EvalcaseReturnType } from "./interfaces";
import { EvaluatorconfigCreateType, EvaluatorconfigUpdateType, EvaluatorconfigReturnType } from "./interfaces";
import { EvaluatorresultUpdateType, EvaluatorresultReturnType, ConfidenceEnum } from "./interfaces";
import {
  EvaluatorConfigJoinsAppCreateType,
  EvaluatorConfigJoinsAppReturnType,
  EvaluatorConfigJoinsDatasetCreateType,
  EvaluatorConfigJoinsDatasetReturnType,
  EvaluatorConfigJoinsDatacaseCreateType,
  EvaluatorConfigJoinsDatacaseReturnType,
} from "./interfaces";

import { getTenantId } from "./tenantManager";

function addQueryParam(url: string, paramName: string, paramValue: string): string {
  const hasQueryParams = url.includes("?");
  const separator = hasQueryParams ? "&" : "?";
  return `${url}${separator}${paramName}=${encodeURIComponent(paramValue)}`;
}

interface FetchAllOptions {
  tenantId?: string | null;
  includeDeleted?: boolean;
  includeSnapshotted?: boolean;
  ids?: string[] | null;
  keyValueFilters?: Object;
}

export class Resource {
  protected static async fetchFromDatabase<T extends Resource>(
    this: new (data: any) => T,
    id: string,
    endpoint: string,
  ): Promise<T> {
    if (!id) {
      throw new Error("No ID provided");
    }
    const data = await makeApiRequestPromise({
      path: urlJoin(endpoint, id),
      method: "GET",
    });
    return new this(data);
  }

  protected static async fetchAllFromDatabase<T extends Resource>(
    this: new (data: any) => T,
    endpoint: string,
  ): Promise<T[]> {
    const data = await makeApiRequestPromise({
      path: endpoint,
      method: "GET",
    });
    return data.map((item: any) => new this(item));
  }

  protected static async createInDatabase<T extends Resource>(
    this: new (data: any) => T,
    data: any,
    endpoint: string,
  ): Promise<T> {
    const newData = await makeApiRequestPromise({
      path: endpoint,
      method: "POST",
      body: data,
    });
    return new this(newData);
  }

  protected static async updateInDatabase<T extends Resource>(
    this: new (data: any) => T,
    id: string,
    data: any,
    endpoint: string,
  ): Promise<T> {
    const newData = await makeApiRequestPromise({
      path: urlJoin(endpoint, id),
      method: "PUT",
      body: data,
    });
    return new this(newData);
  }

  protected static async deleteFromDatabase<T extends Resource>(
    this: new (data: any) => T,
    id: string,
    endpoint: string,
  ): Promise<boolean> {
    const data = await makeApiRequestPromise({
      path: urlJoin(endpoint, id),
      method: "DELETE",
    }).then((data) => true);
    return data;
  }

  protected static async duplicateInDatabase<T extends Resource>(
    this: new (data: any) => T,
    data: any,
    endpoint: string,
  ): Promise<T> {
    // Remove id, created, deleted_at, snapshot_source
    // deepcopy the input data
    const copiedData = JSON.parse(JSON.stringify(data));
    delete copiedData.id;
    delete copiedData.created;
    const newData = await makeApiRequestPromise({
      path: endpoint,
      method: "POST",
      body: copiedData,
    });
    return new this(newData);
  }
}

class TenantedResource extends Resource {
  protected static async createInDatabase<T extends Resource>(
    this: new (data: any) => T,
    data: any,
    endpoint: string,
    tenantId?: string | null,
  ): Promise<T> {
    if (!tenantId) {
      tenantId = getTenantId();
    }
    if (!tenantId) {
      throw new Error("No tenant ID");
    }

    // inject tenant_id into the data object
    const dataWithTenant = { ...data, tenant_id: tenantId };

    const newData = await makeApiRequestPromise({
      path: endpoint,
      method: "POST",
      body: dataWithTenant,
    });
    return new this(newData);
  }

  protected static async fetchAllFromDatabase<T extends Resource>(
    this: new (data: any) => T,
    endpoint: string,
    options: FetchAllOptions = {},
  ): Promise<T[]> {
    if (!options.tenantId) {
      options.tenantId = getTenantId();
    }
    if (!options.tenantId) {
      throw new Error("No tenant ID");
    }

    let path = endpoint;

    path = addQueryParam(path, "tenant_id", options.tenantId);

    if (options.includeDeleted) {
      path = addQueryParam(path, "include_deleted", "true");
    }

    if (options.includeSnapshotted) {
      path = addQueryParam(path, "include_snapshotted", "true");
    }

    if (options.ids) {
      path = addQueryParam(path, "ids", options.ids.join(","));
    }

    if (options.keyValueFilters) {
      for (const [key, value] of Object.entries(options.keyValueFilters)) {
        path = addQueryParam(path, key, value.toString());
      }
    }

    const data = await makeApiRequestPromise({
      path: path,
      method: "GET",
    });

    return data.map((item: any) => new this(item));
  }
}

export class User extends Resource implements UserType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  api_keys: string[];

  first_name: string | null;
  email: string | null;
  fingerprint: string | null;
  cognito_username: string | null;

  constructor(data: UserType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.api_keys = data.api_keys;

    this.first_name = data.first_name;
    this.email = data.email;
    this.fingerprint = data.fingerprint;
    this.cognito_username = data.cognito_username;
  }

  static async fetch(id: string): Promise<User> {
    return await this.fetchFromDatabase(id, USER_ENDPOINT);
  }

  static async fetchMe(): Promise<User> {
    return await makeApiRequestPromise({
      path: urlJoin(USER_ENDPOINT, "me"),
      method: "GET",
    }).then((data) => new User(data));
  }

  static async fetchAll(): Promise<User[]> {
    return await this.fetchAllFromDatabase(USER_ENDPOINT);
  }

  static async update(id: string, data: UserUpdateType): Promise<User> {
    return await this.updateInDatabase(id, data, USER_ENDPOINT);
  }
}

export class Tenant extends Resource implements TenantType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;

  name: string;
  personal: boolean;

  constructor(data: TenantType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;

    this.name = data.name;
    this.personal = data.personal;
  }

  static async create(data: TenantCreateType): Promise<Tenant> {
    return await this.createInDatabase(data, TENANT_ENDPOINT);
  }

  static async fetch(id: string): Promise<Tenant> {
    return await this.fetchFromDatabase(id, TENANT_ENDPOINT);
  }

  static async fetchAll(): Promise<Tenant[]> {
    return await this.fetchAllFromDatabase(TENANT_ENDPOINT);
  }

  static async update(id: string, data: TenantUpdateType): Promise<Tenant> {
    return await this.updateInDatabase(id, data, TENANT_ENDPOINT);
  }
}

export class Assignment extends Resource implements AssignmentCreateType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;

  user_id: string;
  tenant_id: string;

  constructor(data: AssignmentCreateType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;

    this.user_id = data.user_id;
    this.tenant_id = data.tenant_id;
  }

  static async create(data: AssignmentCreateType): Promise<Assignment> {
    return await this.createInDatabase(data, ASSIGNMENT_ENDPOINT);
  }

  static async fetch(id: string): Promise<Assignment> {
    return await this.fetchFromDatabase(id, ASSIGNMENT_ENDPOINT);
  }

  static async fetchAll(): Promise<Assignment[]> {
    return await this.fetchAllFromDatabase(ASSIGNMENT_ENDPOINT);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, ASSIGNMENT_ENDPOINT);
  }
}

export class EvaluatorConfigJoinsApp extends TenantedResource implements EvaluatorConfigJoinsAppReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;

  evaluatorconfig_id: string;
  app_id: string;

  constructor(data: EvaluatorConfigJoinsAppReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;

    this.evaluatorconfig_id = data.evaluatorconfig_id;
    this.app_id = data.app_id;
  }

  static async create(data: EvaluatorConfigJoinsAppCreateType): Promise<EvaluatorConfigJoinsApp> {
    return await this.createInDatabase(data, EVALUATORCONFIGJOINSAPP_ENDPOINT);
  }

  static async fetch(id: string): Promise<EvaluatorConfigJoinsApp> {
    return await this.fetchFromDatabase(id, EVALUATORCONFIGJOINSAPP_ENDPOINT);
  }

  static async fetchAll(options: FetchAllOptions = {}): Promise<EvaluatorConfigJoinsApp[]> {
    return await this.fetchAllFromDatabase(EVALUATORCONFIGJOINSAPP_ENDPOINT, options);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, EVALUATORCONFIGJOINSAPP_ENDPOINT);
  }
}

export class EvaluatorConfigJoinsDataset extends TenantedResource implements EvaluatorConfigJoinsDatasetReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;

  evaluatorconfig_id: string;
  dataset_id: string;

  constructor(data: EvaluatorConfigJoinsDatasetReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;

    this.evaluatorconfig_id = data.evaluatorconfig_id;
    this.dataset_id = data.dataset_id;
  }

  static async create(data: EvaluatorConfigJoinsDatasetCreateType): Promise<EvaluatorConfigJoinsDataset> {
    return await this.createInDatabase(data, EVALUATORCONFIGJOINSDATASET_ENDPOINT);
  }

  static async fetch(id: string): Promise<EvaluatorConfigJoinsDataset> {
    return await this.fetchFromDatabase(id, EVALUATORCONFIGJOINSDATASET_ENDPOINT);
  }

  static async fetchAll(options: FetchAllOptions = {}): Promise<EvaluatorConfigJoinsDataset[]> {
    return await this.fetchAllFromDatabase(EVALUATORCONFIGJOINSDATASET_ENDPOINT, options);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, EVALUATORCONFIGJOINSDATASET_ENDPOINT);
  }
}

export class EvaluatorConfigJoinsDatacase extends TenantedResource implements EvaluatorConfigJoinsDatacaseReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;

  evaluatorconfig_id: string;
  datacase_id: string;

  constructor(data: EvaluatorConfigJoinsDatacaseReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;

    this.evaluatorconfig_id = data.evaluatorconfig_id;
    this.datacase_id = data.datacase_id;
  }

  static async create(data: EvaluatorConfigJoinsDatacaseCreateType): Promise<EvaluatorConfigJoinsDatacase> {
    return await this.createInDatabase(data, EVALUATORCONFIGJOINSDATACASE_ENDPOINT);
  }

  static async fetch(id: string): Promise<EvaluatorConfigJoinsDatacase> {
    return await this.fetchFromDatabase(id, EVALUATORCONFIGJOINSDATACASE_ENDPOINT);
  }

  static async fetchAll(options: FetchAllOptions = {}): Promise<EvaluatorConfigJoinsDatacase[]> {
    return await this.fetchAllFromDatabase(EVALUATORCONFIGJOINSDATACASE_ENDPOINT, options);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, EVALUATORCONFIGJOINSDATACASE_ENDPOINT);
  }
}

export class Runner extends Resource implements RunnerReturnType {
  id: string;
  created: string;

  runnerfamily_id: string;
  last_active: string;
  is_live: boolean;
  version: string;

  parameters: ParameterTypes[];
  runner_type: string;
  package_version: string;
  docstring?: string | null;

  constructor(data: RunnerReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;

    this.runnerfamily_id = data.runnerfamily_id;
    this.last_active = data.last_active;
    this.is_live = data.is_live;
    this.version = data.version;

    this.parameters = data.parameters;
    this.runner_type = data.runner_type;
    this.package_version = data.package_version;
    this.docstring = data.docstring;
  }
  static async fetch(id: string): Promise<Runner> {
    return await this.fetchFromDatabase(id, RUNNER_ENDPOINT);
  }

  static async fetchAll(api_key: string): Promise<Runner[]> {
    const data = await makeApiRequestPromise({
      path: RUNNER_ENDPOINT + "?api_key=" + api_key,
      method: "GET",
    });
    return data.map((item: any) => new this(item));
  }
}

export class App extends TenantedResource implements AppReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;

  name: string;

  // calculated
  api_key: string;
  last_active: string;
  latest_version: string;
  is_live: boolean;

  constructor(data: AppReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;

    this.name = data.name;

    // calculated
    this.api_key = data.api_key;
    this.last_active = data.last_active;
    this.latest_version = data.latest_version;
    this.is_live = data.is_live;
  }

  static async create(data: AppCreateType, tenantId?: string): Promise<App> {
    return await this.createInDatabase(data, APP_ENDPOINT, tenantId);
  }

  static async fetch(id: string): Promise<App> {
    return await this.fetchFromDatabase(id, APP_ENDPOINT);
  }

  static async fetchAll(options: FetchAllOptions = {}): Promise<App[]> {
    return await this.fetchAllFromDatabase(APP_ENDPOINT, options);
  }

  static async update(id: string, data: AppUpdateType): Promise<App> {
    return await App.updateInDatabase(id, data, APP_ENDPOINT);
  }

  static async delete(id: string): Promise<boolean> {
    return await App.deleteFromDatabase(id, APP_ENDPOINT);
  }

  static async duplicate(app: App): Promise<App> {
    return await App.duplicateInDatabase(app, APP_ENDPOINT);
  }

  // get api_key(): string | null {
  //   if (this.runners.length === 0) {
  //     return null;
  //   } else {
  //     return this.runners[0].api_key;
  //   }
  // }

  // get last_active(): string | null {
  //   if (this.runners.length === 0) {
  //     return null;
  //   } else {
  //     // return last_active of the last in the list of runners
  //     return this.runners[this.runners.length - 1].last_active;
  //   }
  // }

  // static async createAndWaitForPopulation(data: AppCreateType, tenantId?: string): Promise<App | null> {
  //   return await this.create(data, tenantId).then(async (newApp) => {
  //     // Keep fetching until response.parameters is populated, exit when either populated or 20 tries
  //     let populatedApp = null;
  //     for (let i = 0; i < 20; i++) {
  //       if (populatedApp != null) {
  //         return populatedApp;
  //       }

  //       const fetchedApp = await App.fetch(newApp.id.toString()).then((response) => {
  //         if (response.parameters) {
  //           return response;
  //         }
  //       });

  //       if (fetchedApp != null) {
  //         populatedApp = fetchedApp;
  //       }

  //       await new Promise((r) => setTimeout(r, 500)); // Sleep for 500ms
  //     }

  //     return null;
  //   });
  // }
}

export class Testset extends TenantedResource implements TestsetReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;
  name: string;
  datacase_ids: string[];

  constructor(data: TestsetReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;
    this.name = data.name;
    this.datacase_ids = data.datacase_ids;
  }

  static async create(data: TestsetCreateType): Promise<Testset> {
    return await this.createInDatabase(data, TESTSET_ENDPOINT);
  }

  static async fetch(id: string): Promise<Testset> {
    return await this.fetchFromDatabase(id, TESTSET_ENDPOINT);
  }

  static async fetchAll(options: FetchAllOptions = {}): Promise<Testset[]> {
    return await this.fetchAllFromDatabase(TESTSET_ENDPOINT, options);
  }

  static async update(id: string, data: TestsetUpdateType): Promise<Testset> {
    return await this.updateInDatabase(id, data, TESTSET_ENDPOINT);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, TESTSET_ENDPOINT);
  }

  static async duplicate(testset: Testset, testcases: Testcase[]): Promise<[Testset, Testcase[]]> {
    // First create the new Testset
    const newTestset = await Testset.create({
      name: `Copy of ${testset.name}`,
    });

    // Then re-create all testcases but with the new testset id
    const newTestcases = await Promise.all(
      testcases.map((testcase) =>
        Testcase.create({
          name: testcase.name,
          dataset_id: newTestset.id,
          inputs: testcase.inputs,
        }),
      ),
    );

    return [newTestset, newTestcases];
  }

  static async fetchTestcases(testset: Testset): Promise<Testcase[]> {
    const testcases = await Promise.all(testset.datacase_ids.map((id) => Testcase.fetch(id)));
    return Testset.sortTestcases(testcases);
  }

  static async fetchTestsetAndTestcases(id: string): Promise<[Testset, Testcase[]]> {
    const testset = await Testset.fetch(id);
    const testcases = await Testset.fetchTestcases(testset);

    return [testset, testcases];
  }

  static getUniqueTestcaseFields(testcases: TestcaseReturnType[]): string[] {
    if (!testcases) {
      return [];
    }
    if (testcases.length === 0) {
      return [];
    }

    //   TODO CONCERNED
    return Array.from(
      testcases.reduce((acc, curr) => {
        const keys = Object.keys(curr.inputs);
        keys.forEach((key) => acc.add(key));
        return acc;
      }, new Set<string>()),
    ).sort((a, b) => (a as string).localeCompare(b as string, undefined, { sensitivity: "base" }));
  }

  static async deleteFieldFromAllTestcases(field: string, testcases: Testcase[]): Promise<Testcase[]> {
    const updates = testcases.map((testcase) => {
      const newInputs = { ...testcase.inputs };
      delete newInputs[field];

      return Testcase.update(testcase.id, {
        ...testcase,
        inputs: newInputs,
      });
    });

    return Promise.all(updates);
  }

  static async addBlankTestcase(testset: Testset, currentTestcases: Testcase[]): Promise<[Testset, Testcase]> {
    const uniqueTestCaseFields = Testset.getUniqueTestcaseFields(currentTestcases);

    const newTestcase: TestcaseCreateType = {
      name: "A New Testcase",
      dataset_id: testset.id,
      inputs: uniqueTestCaseFields.reduce((acc: Record<string, string>, curr) => {
        acc[curr] = "";
        return acc;
      }, {}),
    };

    const createdTestcase = await Testcase.create(newTestcase);

    // Refetch the testset to get the updated datacase_ids
    const updatedTestset = await Testset.fetch(testset.id);

    return [updatedTestset, createdTestcase];
  }

  static async renameFieldInAllTestcases(
    oldField: string,
    newField: string,
    testcases: Testcase[],
  ): Promise<Testcase[]> {
    const updates = testcases.map((testcase) => {
      const newInputs = { ...testcase.inputs };
      const data = newInputs[oldField];
      delete newInputs[oldField];
      newInputs[newField] = data;

      return Testcase.update(testcase.id, {
        ...testcase,
        inputs: newInputs,
      });
    });

    return Promise.all(updates);
  }

  static async addFieldToAllTestcases(testcases: Testcase[]): Promise<Testcase[]> {
    const uniqueTestCaseFields = Testset.getUniqueTestcaseFields(testcases);
    // For all test cases, add a new field
    // Calculate the new field value from allTestCaseFields
    // The new field takes the template "New Input " + integer
    // First try "New Input 1", then "New Input 2", etc.
    let i = 1;
    let new_field = "New Input " + i;
    while (uniqueTestCaseFields.includes(new_field)) {
      i++;
      new_field = "New Input " + i;
    }

    const updates = testcases.map((testcase) => {
      const newInputs = { ...testcase.inputs };
      newInputs[new_field] = "";

      return Testcase.update(testcase.id, {
        ...testcase,
        inputs: newInputs,
      });
    });

    return Promise.all(updates);
  }

  static isFrozen(testset: Testset): boolean {
    return testset.snapshotted_at !== null;
  }

  static sortTestcases(testcases: Testcase[]): Testcase[] {
    // Sort the data cases alphabetically by their name
    return testcases.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      }),
    );
  }

  // static async createWithTestcases(
  //   createTestset: TestsetCreateType,
  //   createTestcases: TestcaseCreateType[],
  // ): Promise<[Testset, Testcase[]]> {
  //   const newTestset = await Testset.create(createTestset);

  //   const newTestcases = await Promise.all(
  //     createTestcases.map((createTestcase) =>
  //       Testcase.create({
  //         ...createTestcase,
  //         dataset_id: newTestset.id,
  //       }),
  //     ),
  //   );

  //   // refresh testset to get the updated datacase_ids
  //   const populatedTestset = await Testset.fetch(newTestset.id);

  //   return [populatedTestset, newTestcases];
  // }

  static createDemoTestset = async (name: string): Promise<TestsetReturnType> => {
    const testset_object = DEMO_TESTSETS.find((testset) => testset.name === name);
    if (!testset_object) {
      throw new Error("Testset not found");
    }

    const testsetCreate: TestsetCreateType = { name: testset_object.name };
    const newTestset = await Testset.create(testsetCreate);

    let joinPromises: Promise<EvaluatorConfigJoinsDatacase>[] = [];
    testset_object.demoData.forEach((testcase) => {
      const testcaseCreate: TestcaseCreateType = {
        name: testcase.name,
        dataset_id: newTestset.id,
        inputs: testcase.inputs,
      };
      const tcCreatePromise = Testcase.create(testcaseCreate);

      testcase.criteria.forEach(async (criteria, index) => {
        const name = "Evaluator " + index;
        const evaluatorconfigCreate: EvaluatorconfigCreateType = {
          name: name,
          config: criteria,
          pipeline_spec: null,
        };

        const ecCreatePromise = Evaluatorconfig.create(evaluatorconfigCreate);

        const tc = await tcCreatePromise;
        const ec = await ecCreatePromise;

        // link the evaluatorconfig to the testcase
        const join = EvaluatorConfigJoinsDatacase.create({
          evaluatorconfig_id: ec.id,
          datacase_id: tc.id,
        });
        joinPromises.push(join);
      });
    });

    await Promise.all(joinPromises);

    // refresh testset to get the updated datacase_ids
    const populatedTestset = Testset.fetch(newTestset.id);

    return populatedTestset;
  };

  static async createWithTestcases(
    createTestset: TestsetCreateType,
    createTestcases: {
      name: string;
      inputs: { [key: string]: string };
    }[],
  ): Promise<[Testset, Testcase[]]> {
    // WARNING: THIS WILL IGNORE ANY CRITERIA/EVALUA
    const newTestset = await Testset.create(createTestset);

    const newTestcases = await Promise.all(
      createTestcases.map((createTestcase) =>
        Testcase.create({
          ...createTestcase,
          dataset_id: newTestset.id,
        }),
      ),
    );

    // refresh testset to get the updated datacase_ids
    const populatedTestset = await Testset.fetch(newTestset.id);

    return [populatedTestset, newTestcases];
  }
}

export class Testcase extends TenantedResource implements TestcaseReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;
  name: string;
  dataset_id: string;
  inputs: { [key: string]: string };

  constructor(data: TestcaseReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;
    this.name = data.name;
    this.dataset_id = data.dataset_id;
    this.inputs = data.inputs;
  }

  static async create(data: TestcaseCreateType): Promise<Testcase> {
    return await this.createInDatabase(data, TESTCASE_ENDPOINT);
  }

  static async fetch(id: string): Promise<Testcase> {
    return await this.fetchFromDatabase(id, TESTCASE_ENDPOINT);
  }

  static async fetchAll(options: FetchAllOptions = {}): Promise<Testcase[]> {
    return await this.fetchAllFromDatabase(TESTCASE_ENDPOINT, options);
  }

  static async update(id: string, data: TestcaseUpdateType): Promise<Testcase> {
    return await this.updateInDatabase(id, data, TESTCASE_ENDPOINT);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, TESTCASE_ENDPOINT);
  }

  static async duplicate(testcase: Testcase): Promise<Testcase> {
    return await this.duplicateInDatabase(testcase, TESTCASE_ENDPOINT);
  }
}

export class Evaluatorconfig extends TenantedResource implements EvaluatorconfigReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;

  name: string;
  config: CriteriaTypes;
  pipeline_spec: PipelineSpec | null;
  derives_from?: string;

  constructor(data: EvaluatorconfigReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;

    this.name = data.name;
    this.config = data.config;
    this.pipeline_spec = data.pipeline_spec;
    this.derives_from = data.derives_from;
  }

  static async create(data: EvaluatorconfigCreateType): Promise<Evaluatorconfig> {
    return await this.createInDatabase(data, EVALUATORCONFIG_ENDPOINT);
  }

  static async fetch(id: string): Promise<Evaluatorconfig> {
    return await this.fetchFromDatabase(id, EVALUATORCONFIG_ENDPOINT);
  }

  static async fetchAll(options: FetchAllOptions = {}): Promise<Evaluatorconfig[]> {
    return await this.fetchAllFromDatabase(EVALUATORCONFIG_ENDPOINT, options);
  }

  static async update(id: string, data: EvaluatorconfigUpdateType): Promise<Evaluatorconfig> {
    return await this.updateInDatabase(id, data, EVALUATORCONFIG_ENDPOINT);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, EVALUATORCONFIG_ENDPOINT);
  }
}

export class Evaluatorresult extends TenantedResource implements EvaluatorresultReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;

  evalcase_id: string;
  evaluatorconfig_id: string;
  score: number | null;
  confidence: ConfidenceEnum | null;
  comment: string | null;
  eval_error: string | null;
  user_score: number | null;
  user_comment: string | null;

  constructor(data: EvaluatorresultReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;

    this.evalcase_id = data.evalcase_id;
    this.evaluatorconfig_id = data.evaluatorconfig_id;
    this.score = data.score;
    this.confidence = data.confidence;
    this.comment = data.comment;
    this.eval_error = data.eval_error;
    this.user_score = data.user_score;
    this.user_comment = data.user_comment;
  }

  static async create(data: EvaluatorresultReturnType): Promise<Evaluatorresult> {
    return await this.createInDatabase(data, EVALUATORRESULT_ENDPOINT);
  }

  static async fetch(id: string): Promise<Evaluatorresult> {
    return await this.fetchFromDatabase(id, EVALUATORRESULT_ENDPOINT);
  }

  static async fetchAll(options: FetchAllOptions = {}): Promise<Evaluatorresult[]> {
    return await this.fetchAllFromDatabase(EVALUATORRESULT_ENDPOINT, options);
  }

  static async update(id: string, data: EvaluatorresultUpdateType): Promise<Evaluatorresult> {
    return await this.updateInDatabase(id, data, EVALUATORRESULT_ENDPOINT);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, EVALUATORRESULT_ENDPOINT);
  }

  static didError = (criteriaResult: Evaluatorresult) => {
    return criteriaResult.eval_error != null;
  };

  static didPass = (evaluatorconfig: Evaluatorconfig, evaluatorresult: Evaluatorresult) => {
    const successThreshold = CRITERIA_DETAILS[evaluatorconfig.config.type].successThreshold;
    if (successThreshold == null) {
      return null;
    }

    const score_to_use = evaluatorresult.user_score ?? evaluatorresult.score;
    if (score_to_use == null) {
      return false;
    } else {
      return score_to_use >= successThreshold;
    }
  };
}

export class Evalset extends TenantedResource implements EvalsetReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;

  version: string;
  dataset_id?: string | null;
  app_id: string;
  name: string;
  var_matching: {
    source: VarSourceType;
    value: string;
  }[];
  annotation: string;
  launched: boolean;
  error?: string | null;
  results_summary?: string | null;

  // Derived properties
  evalcase_ids: string[];
  progress: number;
  app_name: string;
  app_api_key: string;

  constructor(data: EvalsetReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;

    this.version = data.version;
    this.dataset_id = data.dataset_id;
    this.app_id = data.app_id;
    this.name = data.name;
    this.var_matching = data.var_matching;
    this.annotation = data.annotation;
    this.launched = data.launched;
    this.error = data.error;
    this.results_summary = data.results_summary;

    this.evalcase_ids = data.evalcase_ids;
    this.progress = data.progress;
    this.app_name = data.app_name;
    this.app_api_key = data.app_api_key;
  }

  static async create(data: EvalsetCreateType): Promise<Evalset> {
    return await this.createInDatabase(data, EVALSET_ENDPOINT);
  }

  static async fetch(id: string): Promise<Evalset> {
    return await this.fetchFromDatabase(id, EVALSET_ENDPOINT);
  }

  static async fetchAll(options: FetchAllOptions = {}): Promise<Evalset[]> {
    return await this.fetchAllFromDatabase(EVALSET_ENDPOINT, options);
  }

  static async update(id: string, data: EvalsetUpdateType): Promise<Evalset> {
    return await this.updateInDatabase(id, data, EVALSET_ENDPOINT);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, EVALSET_ENDPOINT);
  }

  static async duplicate(evalset: Evalset): Promise<Evalset> {
    return await this.duplicateInDatabase(evalset, EVALSET_ENDPOINT);
  }

  static evalsetIsComplete = (evalset: Evalset): boolean => {
    if (evalset == null) {
      return false;
    }
    // Check if evalset.results_summary is a string and has length greater than 0
    if (
      evalset.progress === 100 &&
      evalset.results_summary !== null &&
      evalset.results_summary &&
      evalset.results_summary.length > 0
    ) {
      return true;
    }
    return false;
  };

  static fetchEvalsetAndEvalcases = async (id: string): Promise<[Evalset, Evalcase[]]> => {
    const evalset = await Evalset.fetch(id);
    const evalcases = await Promise.all(evalset.evalcase_ids.map((id) => Evalcase.fetch(id)));

    return [evalset, evalcases];
  };
}

export class Evalcase extends TenantedResource implements EvalcaseReturnType {
  id: string;
  created: string;
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
  tenant_id: string;

  evalset_id: string;
  datacase_id: string | null;
  output?: any | null;
  run_error?: string | null;
  output_stream_incomplete: boolean;
  evaluatorconfig_ids: string[];
  evaluatorresult_ids: string[];

  app_name: string;
  app_api_key: string;
  app_id: string;

  constructor(data: EvalcaseReturnType) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.deleted_at = data.deleted_at;
    this.snapshotted_at = data.snapshotted_at;
    this.snapshot_source = data.snapshot_source;
    this.tenant_id = data.tenant_id;

    this.evalset_id = data.evalset_id;
    this.datacase_id = data.datacase_id;
    this.output = data.output;
    this.run_error = data.run_error;
    this.output_stream_incomplete = data.output_stream_incomplete;
    this.evaluatorconfig_ids = data.evaluatorconfig_ids;
    this.evaluatorresult_ids = data.evaluatorresult_ids;

    this.app_name = data.app_name;
    this.app_api_key = data.app_api_key;
    this.app_id = data.app_id;
  }

  static async create(data: EvalcaseCreateType): Promise<Evalcase> {
    return await this.createInDatabase(data, EVALCASE_ENDPOINT);
  }

  static async fetch(id: string): Promise<Evalcase> {
    return await this.fetchFromDatabase(id, EVALCASE_ENDPOINT);
  }

  static async fetchAll(): Promise<Evalcase[]> {
    return await this.fetchAllFromDatabase(EVALCASE_ENDPOINT);
  }

  static async delete(id: string): Promise<boolean> {
    return await this.deleteFromDatabase(id, EVALCASE_ENDPOINT);
  }

  static async duplicate(evalcase: Evalcase): Promise<Evalcase> {
    return await this.duplicateInDatabase(evalcase, EVALCASE_ENDPOINT);
  }

  static isComplete = (evalcase: Evalcase): boolean => {
    if (evalcase == null) {
      return false;
    }
    if (evalcase.output_stream_incomplete === true) {
      return false;
    }
    if (evalcase.output != null || evalcase.run_error != null) {
      return true;
    }
    return false;
  };

  static didPass = (evalcase: Evalcase, evaluatorconfigs: Evaluatorconfig[], evaluatorresults: Evaluatorresult[]) => {
    // we cannot assume that the order of the evaluatorconfigs and evaluatorresults is the same
    // so we need to match them by id

    return evaluatorresults.every((result) => {
      const config = evaluatorconfigs.find((config) => config.id === result.evaluatorconfig_id);
      if (config == null) {
        return false;
      }
      return Evaluatorresult.didPass(config, result);
    });
  };

  static didError = (evalcase: Evalcase): boolean => {
    return evalcase.run_error != null;
  };

  static passRate = (evaluatorconfigs: Evaluatorconfig[], evaluatorresults: Evaluatorresult[]) => {
    const numPassed = evaluatorresults.filter((result) => {
      const config = evaluatorconfigs.find((config) => config.id === result.evaluatorconfig_id);
      if (config == null) {
        return false;
      }
      return Evaluatorresult.didPass(config, result);
    }).length;
    return { numPassed: numPassed, outOf: evaluatorresults.length };
  };
}
