import { saveAs } from "file-saver";
import Papa from "papaparse";
import * as XLSX from "xlsx";

export const convertXlsxToArrayObjects = (file: File): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const arrayObjects = XLSX.utils.sheet_to_json(worksheet);
      resolve(arrayObjects);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });
};

export const send_csv = (filename: string, base64_string: string) => {
  let binary = atob(base64_string);
  let array: number[] = [];
  let bom = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8 BOM

  // Check if BOM already exists at start of binary
  let bom_exists =
    binary.charCodeAt(0) === bom[0] && binary.charCodeAt(1) === bom[1] && binary.charCodeAt(2) === bom[2];

  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  let uint8Array = new Uint8Array(array);
  let blob: Blob;
  if (bom_exists) {
    blob = new Blob([uint8Array], { type: "text/csv;charset=utf-8;" });
  } else {
    blob = new Blob([bom, uint8Array], { type: "text/csv;charset=utf-8;" });
  }
  saveAs(blob, filename);
};

export const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString().split(",")[1] || "");
    reader.onerror = (error) => reject(error);
  });
};

export const objectsToCSV = (objs: any[]): string => {
  // First, remove any input objects that are blank
  let cleanedObjs = objs.filter((obj) => Object.values(obj).some((value) => value !== undefined && value !== null));

  // Also, check for blank headers and remove
  if (cleanedObjs.length > 0) {
    let exampleObj = cleanedObjs[0];
    for (let key in exampleObj) {
      if (key === null || key === "") {
        cleanedObjs = cleanedObjs.map((obj) => {
          const { [key]: _, ...rest } = obj;
          return rest;
        });
      }
    }
  }

  const csv = Papa.unparse(cleanedObjs);
  return csv;
};

export const csvToObjects = (csv: string): any[] => {
  const result = Papa.parse(csv.trim(), {
    skipEmptyLines: true,
    header: true,
  });

  const data = result.data as { [key: string]: any }[];

  if (data.length > 0) {
    // remove columns that have null or empty string as header
    const keys = Object.keys(data[0]);
    keys.forEach((key) => {
      if (key === null || key === "") {
        data.forEach((item: { [key: string]: any }) => delete item[key]);
      }
    });
  }
  return data;
};

// base64 to list of objects: csvToObjects(atob(base64_string))
// list of objects to base64: btoa(objectsToCSV(list_of_objects))
