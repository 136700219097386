import { CheckIcon, MinusIcon, PencilSquareIcon, PlusIcon } from "@heroicons/react/20/solid";
import { assert } from "console";
import React, { useState } from "react";
import { toast } from "react-toastify";

interface EditableScoreProps {
  title: string;
  undertext: string;
  score: number | null;
  outOf: number | null;
  successThreshold: number | null;
  onScoreChange: (newScore: number) => void;
}

export const EditableScore = ({
  title,
  undertext,
  score,
  outOf,
  successThreshold,
  onScoreChange,
}: EditableScoreProps) => {
  const [editingMode, setEditingMode] = useState(false);

  if (score == null || outOf == null || successThreshold == null) {
    return (
      <div className="stats bg-white">
        <div className="stat">
          <div className="stat-title">{title}</div>
          <div className="stat-value">-</div>
          <div className="stat-desc w-full truncate">{undertext}</div>
        </div>
      </div>
    );
  }

  const _onScoreChange = (newValue: number) => {
    if (newValue < 0 || newValue > outOf) {
      toast.error("Invalid score");
    } else {
      onScoreChange(newValue);
    }
  };

  return (
    <div className="relative stats bg-white">
      {/* absolute icon in top right */}
      {editingMode ? (
        <div className="absolute z-5 top-2 right-2 flex flex-row">
          <MinusIcon className="tiny-icon-button text-black" onClick={() => _onScoreChange(score - 1)}>
            -
          </MinusIcon>
          <PlusIcon className="tiny-icon-button text-black" onClick={() => _onScoreChange(score + 1)}>
            +
          </PlusIcon>
          <CheckIcon className="tiny-icon-button" onClick={() => setEditingMode((x) => !x)} />
        </div>
      ) : (
        <PencilSquareIcon
          className="absolute z-5 top-2 right-2 tiny-icon-button"
          onClick={(e) => setEditingMode((x) => !x)}
        />
      )}
      <div className="stat">
        <div className="stat-title">{title}</div>
        <div className={`stat-value flex flex-row ${score >= successThreshold ? "text-maingreen" : "text-mainorange"}`}>
          <div>{score}</div> <div>/ {outOf}</div>
        </div>
        <div className="stat-desc w-full truncate">{undertext}</div>
      </div>
    </div>
  );
};
