import React, { useEffect, useState } from "react";
import { parameterNameProcessor } from "../utils/utils";

interface EditableTitleDisplayProps {
  text: string;
  rename: (newText: string) => void;
  containerClassName?: string;
  inputClassName?: string;
  displayClassName?: string;
}

export const EditableTitleDisplay: React.FC<EditableTitleDisplayProps> = ({
  text,
  rename,
  containerClassName = "",
  inputClassName = "",
  displayClassName = "",
}) => {
  const [isEditInputOpen, setIsEditInputOpen] = useState(false);
  const [localValue, setLocalValue] = useState(text);

  useEffect(() => {
    setLocalValue(text);
  }, [text]);

  return (
    <div
      className={`group h-full flex flex-row items-center justify-between overflow-hidden space-x-2 p-2 text-xs ${containerClassName}`}
    >
      {isEditInputOpen ? (
        <input
          className={`input input-xs min-w-20 ${inputClassName}`}
          value={localValue ?? ""}
          onChange={(event) => setLocalValue(event.target.value)}
          autoFocus={true}
          onBlur={(event) => {
            rename(event.target.value);
            setIsEditInputOpen(false);
          }}
        />
      ) : (
        <div
          className={`text-ellipsis overflow-hidden capitalize hover:cursor-pointer ${displayClassName}`}
          onClick={() => {
            setIsEditInputOpen(true);
          }}
        >
          {parameterNameProcessor(text)}
        </div>
      )}
    </div>
  );
};
