import React, { useState, useEffect, Fragment } from "react";
import { LanguageIcon, LinkIcon, ListBulletIcon } from "@heroicons/react/20/solid";
import { HashtagIcon, PlusIcon, RectangleGroupIcon } from "@heroicons/react/24/outline";

const ITEM_HEIGHT = 24;

const simpleString: string = "Hello, JSON!";

const numberArray: number[] = [1, 2, 3, 4, 5];

const personObject: [string, string[]] = [
  "Here's an example on an output from the model. Here's an example on an output from the model. Here's an example on an output from the model. Here's an example on an output from the model.",
  ["Snippet example 1", "Snippet example 2", "Snippet example 3"],
];

interface User {
  id: number;
  username: string;
  details: {
    email: string;
    active: boolean;
  };
}

const users: User[] = [
  {
    id: 1,
    username: "user1",
    details: {
      email: "user1@example.com",
      active: true,
    },
  },
  {
    id: 2,
    username: "user2",
    details: {
      email: "user2@example.com",
      active: false,
    },
  },
];

interface JsonViewerProps {
  data: any;
  flattenDepthZero?: boolean;
  displayTypeWords?: boolean;
  collapsibleData?: boolean;
  collapseOnRender?: boolean;
}

export const JsonViewer: React.FC<JsonViewerProps> = ({
  data,
  flattenDepthZero = true,
  displayTypeWords = false,
  collapsibleData = true,
  collapseOnRender = true,
}) => {
  return (
    <div className="w-full" data-testid="json-viewer">
      <RecursiveJsonViewer
        data={data}
        recursionDepth={0}
        flattenDepthZero={flattenDepthZero}
        displayTypeWords={displayTypeWords}
        collapsibleData={collapsibleData}
        collapseOnRender={collapseOnRender}
      />
    </div>
  );
};

interface JsonLineProps extends React.HTMLAttributes<HTMLDivElement> {
  style?: React.CSSProperties;
  className?: string;
}

export const JsonLine: React.FC<JsonLineProps> = (props) => {
  const { style, className, ...rest } = props;
  return (
    <div
      className={`w-full flex flex-row justify-start space-x-1 ${className}`}
      style={{
        minHeight: ITEM_HEIGHT,
        maxWidth: "100%",
        ...style,
      }}
      {...rest}
    />
  );
};

interface CollapsibleButtonProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  ArgIcon: any;
}

const CollapsibleButton: React.FC<CollapsibleButtonProps> = ({ collapsed, setCollapsed, ArgIcon }) => {
  return (
    <button
      className="mt-1 w-5 h-5 btn btn-primary btn-outline btn-circle p-0 min-h-0 min-w-0 hover:scale-110 shrink-0 shadow"
      onClick={() => setCollapsed((x) => !x)}
    >
      <ArgIcon className="w-3 h-3 m-0" />
    </button>
  );
};

interface PlainIconProps {
  ArgIcon: any;
}

const PlainIcon: React.FC<PlainIconProps> = ({ ArgIcon }) => {
  return <ArgIcon className="h-4 w-4 min-w-4 inline-block mt-1 text-maindarkgray shrink-0"></ArgIcon>;
};

interface RecursiveJsonViewerProps {
  data: any;
  flattenDepthZero?: boolean;
  displayTypeWords?: boolean;
  collapsibleData?: boolean;
  recursionDepth: number;
  collapseOnRender: boolean;
}

const RecursiveJsonViewer: React.FC<RecursiveJsonViewerProps> = ({
  data,
  flattenDepthZero,
  displayTypeWords,
  collapsibleData,
  recursionDepth,
  collapseOnRender,
}) => {
  const [childrenCollapsed, setChildrenCollapsed] = useState(collapseOnRender);

  if (collapseOnRender && !collapsibleData) {
    throw new Error("Cannot collapse non-collapsible data");
  }
  if (data == null) {
    return <JsonLine></JsonLine>;
  } else if (Array.isArray(data)) {
    return (
      <div className="w-full flex flex-col justify-start items-start">
        {recursionDepth > 0 || !flattenDepthZero ? (
          <>
            <JsonLine>
              {collapsibleData ? (
                <CollapsibleButton
                  ArgIcon={ListBulletIcon}
                  collapsed={childrenCollapsed}
                  setCollapsed={setChildrenCollapsed}
                />
              ) : (
                <PlainIcon ArgIcon={ListBulletIcon} />
              )}
            </JsonLine>
            {childrenCollapsed && recursionDepth > 0 ? null : (
              <div className="ml-6">
                {data.map((item: any, index: number) => (
                  <div key={index} className="w-full">
                    <RecursiveJsonViewer
                      data={item}
                      recursionDepth={recursionDepth + 1}
                      flattenDepthZero={flattenDepthZero}
                      displayTypeWords={displayTypeWords}
                      collapsibleData={collapsibleData}
                      collapseOnRender={collapseOnRender}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <>
            {childrenCollapsed && recursionDepth > 0 ? null : (
              <div className="ml-0 w-full">
                {data.map((item: any, index: number) => (
                  <div key={index} className="w-full">
                    <RecursiveJsonViewer
                      data={item}
                      recursionDepth={recursionDepth + 1}
                      flattenDepthZero={flattenDepthZero}
                      displayTypeWords={displayTypeWords}
                      collapsibleData={collapsibleData}
                      collapseOnRender={collapseOnRender}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    );
  } else if (typeof data === "object") {
    return (
      <div className="w-full flex flex-col justify-start items-start">
        <JsonLine>
          {collapsibleData ? (
            <CollapsibleButton
              ArgIcon={RectangleGroupIcon}
              collapsed={childrenCollapsed}
              setCollapsed={setChildrenCollapsed}
            />
          ) : (
            <RectangleGroupIcon />
          )}
        </JsonLine>
        {childrenCollapsed && recursionDepth > 0 ? null : (
          <div className="ml-6">
            {Object.keys(data).map((key) => (
              <div key={key} className="w-full flex flex-row justify-start items-start space-x-1">
                <JsonLine className="text-maindarkgray">{key}</JsonLine>
                <RecursiveJsonViewer
                  data={data[key]}
                  recursionDepth={recursionDepth + 1}
                  flattenDepthZero={flattenDepthZero}
                  displayTypeWords={displayTypeWords}
                  collapsibleData={collapsibleData}
                  collapseOnRender={collapseOnRender}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  } else if (typeof data === "string") {
    return (
      <JsonLine>
        {recursionDepth > 0 || !flattenDepthZero ? <PlainIcon ArgIcon={LanguageIcon} /> : null}
        {displayTypeWords ? <div className="">String</div> : <div className="min-w-0 grow break-words">{data}</div>}
      </JsonLine>
    );
  } else if (typeof data === "number") {
    return (
      <JsonLine>
        {recursionDepth > 0 || !flattenDepthZero ? <PlainIcon ArgIcon={HashtagIcon} /> : null}
        {displayTypeWords ? <div className="">Number</div> : <div className="break-words">{data}</div>}
      </JsonLine>
    );
  } else if (typeof data === "boolean") {
    return (
      <JsonLine>
        {recursionDepth > 0 || !flattenDepthZero ? <PlainIcon ArgIcon={PlusIcon} /> : null}
        {displayTypeWords ? (
          <div className="">True/False</div>
        ) : (
          <div className=" break-words">{data ? "true" : "false"}</div>
        )}
      </JsonLine>
    );
  } else {
    return <JsonLine></JsonLine>;
  }
};

export const DevPlayground: React.FC = () => {
  return (
    <div className="flex flex-col space-y-8 justify-center items-center min-h-full w-full overflow-y-scroll">
      <div className="p-4 border-solid border-2 border-indigo-600 w-48">
        <JsonViewer data={simpleString} />
      </div>
      <div className="p-4 border-solid border-2 border-indigo-600 w-64">
        <JsonViewer data={numberArray} />
      </div>
      <div className="p-4 border-solid border-2 border-indigo-600 w-80">
        <JsonViewer data={personObject} />
      </div>
      <div className="p-4 border-solid border-2 border-indigo-600 w-96">
        <JsonViewer data={users} />
      </div>
    </div>
  );
};
