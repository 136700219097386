import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import { Amplify } from "aws-amplify";
import { signUp } from "aws-amplify/auth";
import { Authenticator, useTheme, View, Theme } from "@aws-amplify/ui-react";
import ProfileSettings from "./container_components/pages/ProfileSettings";
import Sidebar from "./presentation_components/PlatformSidebar";
import ProjectsPage from "./container_components/pages/Project";
import Playground from "./container_components/pages/Playground";
import Evaluations from "./container_components/pages/Evaluations";
import TestsetEditor from "./container_components/pages/TestsetDetails";
import Testsets from "./container_components/pages/Testset";
import { ScreenSizeCheck } from "./presentation_components/ScreenSizeCheck";
import AppDetails from "./container_components/pages/AppDetails";
import { ProtectedRoute } from "./presentation_components/primitives/ProtectedRoute";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { AUTH_PATH } from "./globals";
import { ModalProvider } from "./presentation_components/modals/useModal";
import urlJoin from "url-join";
import CompanyEmailValidator from "company-email-validator";
import AppCreationPage from "./container_components/pages/AppRegistration";
import { TenantProvider } from "./utils/TenantContext";
import { getTenantId, tenantedRouteBuilder } from "./utils/tenantManager";
import APIPlayground from "./presentation_components/APIPlayground/APIPlayground";

// Route constants
const ROUTES = {
  PLAYGROUND: "playground",
  PROJECT: "project",
  EVALUATION: "evaluation",
  TESTSET: "testset",
  PROFILE_SETTINGS: "/control-center",
  LINK: "/link",
  API_PLAYGROUND: "/api-playground",
};

// CONFIGURATION FOR AMPLIFY AUTH PAGE
const formFields = {
  signIn: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
  signUp: {
    username: {
      label: "Work Email",
      placeholder: "Enter your work email",
    },
  },
  resetPassword: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <img
          src={urlJoin(import.meta.env.BASE_URL, "/logo.svg")}
          className="w-full h-14 mb-8 mt-10"
          alt="Composo logo"
        />
      </View>
    );
  },
};

const amplifyTheme: Theme = {
  name: "Custom Theme",
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: "#AFD0CD" },
          80: { value: "#45918A" },
          90: { value: "#2B817A" },
          100: { value: "#107169" },
        },
      },
    },
  },
};

const services = {
  async handleSignUp(formData: any) {
    let { username, password } = formData;
    username = username.toLowerCase();

    const isCompanyEmail = CompanyEmailValidator.isCompanyEmail(username);

    if (isCompanyEmail) {
      return signUp({
        username,
        password,
      });
    } else {
      throw new Error("Please sign up with your work email.");
    }
  },
};

const AppRouter: React.FC = () => {
  const tenantPrefix: string = tenantedRouteBuilder("") || "";

  return (
    <Authenticator.Provider>
      <Router>
        <ModalProvider>
          <Routes>
            {/* NON TENANTED PLATFORM ROUTES */}
            <Route
              path={AUTH_PATH}
              element={
                <ThemeProvider theme={amplifyTheme}>
                  {/* hideSignUp={true} */}
                  <Authenticator services={services} formFields={formFields} components={components}>
                    <Navigate to="/" replace />
                  </Authenticator>
                </ThemeProvider>
              }
            />
            <Route path={"/"} element={<Navigate to={ROUTES.PROFILE_SETTINGS} replace />} />
            <Route
              path={ROUTES.PROFILE_SETTINGS}
              element={
                <ProtectedRoute>
                  <ProfileSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.LINK}
              element={
                <ProtectedRoute>
                  <AppCreationPage />
                </ProtectedRoute>
              }
            />

            <Route
              path={ROUTES.API_PLAYGROUND}
              element={
                <ProtectedRoute>
                  <APIPlayground />
                </ProtectedRoute>
              }
            />

            {/* TENANTED PLATFORM ROUTES */}
            <Route
              path={`${tenantPrefix}/*`}
              element={
                <ProtectedRoute>
                  <TenantProvider>
                    <ScreenSizeCheck>
                      <div
                        id="platformcontainer"
                        className="h-screen w-screen bg-white flex overflow-hidden"
                        style={{
                          minHeight: "350px",
                          minWidth: "700px",
                        }}
                      >
                        <Sidebar />
                        <div className="flex-grow overflow-auto">
                          <Routes>
                            <Route path={ROUTES.PLAYGROUND} element={<Playground />} />
                            <Route path={ROUTES.PROJECT} element={<ProjectsPage />} />
                            <Route path={`${ROUTES.PROJECT}/:id`} element={<AppDetails />} />
                            <Route path={ROUTES.EVALUATION} element={<Evaluations />} />
                            <Route path={ROUTES.TESTSET} element={<Testsets />} />
                            <Route path={`${ROUTES.TESTSET}/:id`} element={<TestsetEditor />} />
                            <Route path="" element={<Navigate to={urlJoin(tenantPrefix, ROUTES.PROJECT)} replace />} />
                            <Route
                              path="*"
                              element={
                                <div>
                                  <h1>404 - Not Found</h1>
                                </div>
                              }
                            />
                          </Routes>
                        </div>
                      </div>
                    </ScreenSizeCheck>
                  </TenantProvider>
                </ProtectedRoute>
              }
            />

            {/* Root level 404 route */}
            <Route
              path="*"
              element={
                <div>
                  <div>404 - Not Found</div>
                </div>
              }
            />
          </Routes>
        </ModalProvider>
      </Router>
    </Authenticator.Provider>
  );
};

export default AppRouter;
