import React from "react";
import { LiveStatusBadge } from "../primitives/AppBadges";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface NameBlockProps {
  name: string;
  docstring: string | null;
  last_active: string | null;
  is_live: boolean;
}

export const NameBlock: React.FC<NameBlockProps> = ({ name, docstring, last_active, is_live }) => {
  return (
    <div className="w-full flex justify-start items-center pl-4 my-4 space-x-2">
      <div className="text-maindarkgray text-base font-semibold capitalize font-title">{name}</div>
      {docstring !== null && (
        <div className="tooltip tooltip-bottom" data-tip={docstring}>
          <InformationCircleIcon className="simple-icon-button" />
        </div>
        // <TooltipButton>
        //   <ReactMarkdown>{docstring}</ReactMarkdown>
        // </TooltipButton>
      )}
      <LiveStatusBadge last_active={last_active} is_live={is_live} className="ml-4" />
      {/* <AppStatusBadge last_active={last_active} className="ml-8" /> */}
    </div>
  );
};
