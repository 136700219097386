import axios, { AxiosRequestConfig } from "axios";
import urlJoin from "url-join";
import { fetchAuthSession } from "aws-amplify/auth";
import { LOG_ENDPOINT } from "../globals";
import { ClientJS } from "clientjs";
import Cookies from "js-cookie";
import * as CryptoJS from "crypto-js";

const getJwtToken = async (callback: (token?: string) => void): Promise<void> => {
  try {
    const { tokens } = await fetchAuthSession();
    const token = tokens?.idToken?.toString();
    callback(token);
  } catch (error) {
    console.log("No session found");
    callback();
  }
};

interface ApiRequestOptions {
  path: string;
  method: string;
  body?: any;
  onSuccess: (data: any) => void;
  onFailure?: (error: any) => void;
  onFinally?: () => void;
}

export const makeApiRequest = ({
  path,
  method,
  body,
  onSuccess,
  onFailure = (error) => console.log(error),
  onFinally = () => {},
}: ApiRequestOptions): void => {
  const startTime = performance.now();
  const apiUrl = urlJoin(import.meta.env.VITE_APP_API_URL as string, path);

  Cookies.remove("fingerprint");
  let fprint = Cookies.get("FID");

  if (!fprint) {
    const client = new ClientJS();
    fprint = client.getFingerprint().toString();
    Cookies.set("FID", fprint, { expires: 365 });
  }

  const EFID = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(fprint), CryptoJS.enc.Utf8.parse("bmh2ax87y7eu9m33"), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).ciphertext.toString(CryptoJS.enc.Base64);

  const makeRequest = (token?: string) => {
    const headers: { [key: string]: string } = {
      "Content-Type": "application/json",
      "User-Ua-Print": EFID,
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios({
      method: method,
      url: apiUrl,
      data: JSON.stringify(body),
      headers: headers,
    })
      .then((response) => {
        onSuccess(response.data);
        const endTime = performance.now();
        const timeDiff = endTime - startTime;
        console.log(`Successful ${method} ${path} in ${Math.round(timeDiff)}ms`);
      })
      .catch((error) => {
        console.log("API request failed");
        onFailure(error);
      })
      .finally(() => {
        onFinally();
      });
  };

  getJwtToken(makeRequest);
};

const getJwtTokenPromise = async (): Promise<string | null> => {
  try {
    const { tokens } = await fetchAuthSession();
    return tokens?.idToken?.toString() || null;
  } catch (error) {
    return null;
  }
};

export const makeApiRequestPromise = async ({
  path,
  method,
  body,
}: Pick<ApiRequestOptions, "path" | "method" | "body">): Promise<any> => {
  const startTime = performance.now();
  const apiUrl = urlJoin(import.meta.env.VITE_APP_API_URL as string, path);

  Cookies.remove("fingerprint");
  let fprint = Cookies.get("FID");

  if (!fprint) {
    const client = new ClientJS();
    fprint = client.getFingerprint().toString();
    Cookies.set("FID", fprint, { expires: 365 });
  }

  const EFID = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(fprint), CryptoJS.enc.Utf8.parse("bmh2ax87y7eu9m33"), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).ciphertext.toString(CryptoJS.enc.Base64);

  let headers: AxiosRequestConfig["headers"] = {
    "Content-Type": "application/json",
    "User-Ua-Print": EFID,
  };

  const token = await getJwtTokenPromise();
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  try {
    const response = await axios({
      method: method,
      url: apiUrl,
      data: JSON.stringify(body),
      headers: headers,
    });

    const endTime = performance.now();
    const timeDiff = endTime - startTime;
    console.log(`Successful ${method} ${path} in ${Math.round(timeDiff)}ms`);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const logMessage = (level: string, message: string, user: any): void => {
  makeApiRequest({
    path: LOG_ENDPOINT,
    method: "POST",
    body: {
      level,
      message,
      source: "UI",
      user,
    },
    onSuccess: (response) => console.log("Log submitted:", response),
    onFailure: (error) => console.error("Error submitting log:", error),
  });
};
