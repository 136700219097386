import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../primitives/Modal";
import {
  XMarkIcon,
  RectangleGroupIcon,
  RocketLaunchIcon,
  SparklesIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import { DOCS_LINK, AUTH_PATH, CALENDLY_LINK } from "../../globals";
import { tenantedRouteBuilder } from "../../utils/tenantManager";

export type ModalComponentProps = React.FC<{
  isOpen: boolean;
  onClose: () => void;
  message?: string;
  title?: string;
  children?: React.ReactElement;
  iconGroup?: React.ReactElement;
}>;

export const GeneralUsageError: ModalComponentProps = ({ isOpen, onClose, title, children, iconGroup = null }) => {
  if (iconGroup == null) {
    iconGroup = <XMarkIcon className="simple-icon-button" onClick={onClose} />;
  }

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="flex flex-row transparent relative">
        <div className="min-w-[200px] min-h-[200px] bg-white rounded-lg p-16 flex flex-col shadow-xl">
          <div className="flex flex-row justify-between w-full space-x-16 items-center mb-8">
            <h1 className="whitespace-nowrap">{title}</h1>
            {iconGroup}
          </div>
          {children}
        </div>
      </div>
    </Modal>
  );
};

interface AppFeatureBoxProps {
  TitleIcon: any; // some weird icon type
  title: string;
  description: string;
}

const AppFeatureBox: React.FC<AppFeatureBoxProps> = ({ TitleIcon, title, description }) => {
  return (
    <div className="w-1/3 flex flex-col space-y-4 items-center">
      <div className="flex flex-row items-center space-x-4">
        <TitleIcon className="w-5 h-5 text-primary" />
        <h3>{title}</h3>
      </div>
      <div className="text-base text-center">{description}</div>
    </div>
  );
};

interface HelpBoxProps {
  TitleIcon: any; // icon
  title: string;
  description: string;
  buttontext: string;
  link: string;
}

const HelpBox: React.FC<HelpBoxProps> = ({ TitleIcon, title, description, buttontext, link }) => {
  return (
    <div className="w-1/3 flex flex-col space-y-2 items-center">
      <div className="flex flex-row items-center space-x-2">
        <TitleIcon className="w-5 h-5 text-primary" />
        <h3>{title}</h3>
      </div>
      <div className="text-base text-center">{description}</div>
      <button className="btn btn-primary mt-0" onClick={() => (window.location.href = link)}>
        {buttontext}
      </button>
    </div>
  );
};

export const LoomVideoEmbed: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.setAttribute("webkitallowfullscreen", "");
      iframeRef.current.setAttribute("mozallowfullscreen", "");
    }
  }, []);

  return (
    <div className="flex justify-center items-center overflow-hidden pt-2">
      <div className="aspect-w-16 aspect-h-6 w-3/5 relative">
        <iframe
          title="loom"
          ref={iframeRef}
          src="https://www.loom.com/embed/350d47a5fc564d39bbd71975bcf6ab3d?sid=8a3b1032-49c5-47ae-ac3f-2e189406591a"
          frameBorder="0"
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full object-cover"
        ></iframe>
      </div>
    </div>
  );
};

interface ButtonWithDescriptionProps {
  buttonText: string;
  link: string;
  onClose: () => void;
  description: string;
  buttonStyle: string;
}

const ButtonWithDescription: React.FC<ButtonWithDescriptionProps> = ({
  buttonText,
  link,
  onClose,
  description,
  buttonStyle,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-1/3 px-8 items-center space-y-8">
      <button
        className={`btn btn-primary capitalize w-36 ${buttonStyle}`}
        onClick={() => {
          navigate(link);
          onClose();
        }}
      >
        {buttonText}
      </button>
      <div className="text-center">{description}</div>
    </div>
  );
};

export const HelpModal: ModalComponentProps = ({ isOpen, onClose }) => {
  return (
    <GeneralUsageError
      isOpen={isOpen}
      onClose={onClose}
      title="Welcome to Composo!"
      iconGroup={
        <div className="flex flex-row space-x-2 items-center">
          <button
            className="btn btn-primary btn-outline capitalize btn-sm"
            onClick={() => {
              window.open(CALENDLY_LINK, "_blank");
            }}
          >
            Book a demo
          </button>
          <button
            className="btn btn-primary btn-outline capitalize btn-sm"
            onClick={() => {
              window.open(DOCS_LINK, "_blank");
            }}
          >
            Read the docs
          </button>
          <button className="simple-icon-button" data-testid="intro-modal-close" onClick={onClose}>
            <XMarkIcon />
          </button>
        </div>
      }
    >
      <>
        <div className="max-w-[900px] flex flex-col items-center pt-2">
          <div className="flex flex-row flex-nowrap space-x-16 bg-mainlightgray p-4 rounded-lg">
            <HelpBox
              TitleIcon={RectangleGroupIcon}
              title="Play"
              description="Play with different foundation models in the playground"
              buttontext="LLM Playground"
              link={tenantedRouteBuilder("/playground")}
            />
            <HelpBox
              TitleIcon={AdjustmentsHorizontalIcon}
              title="Test"
              description="Test for safety, accuracy and harmful content in your output"
              buttontext="Pressure Test"
              link={tenantedRouteBuilder("/testset")}
            />
            <HelpBox
              TitleIcon={RocketLaunchIcon}
              title="Build"
              description="Build high performing, low cost LLM-powered applications"
              buttontext="Link your Codebase"
              link={tenantedRouteBuilder("/project")}
            />
          </div>
        </div>
        <hr className="my-4" />
        <div>
          <h3>Watch the demo!</h3>
          <LoomVideoEmbed />
        </div>
      </>
    </GeneralUsageError>
  );
};

export const ProModelError: ModalComponentProps = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  return (
    <GeneralUsageError isOpen={isOpen} onClose={onClose} title="Sign Up To Use Pro LLM Models">
      <div className="h-full w-full flex flex-col justify-between space-y-8">
        <div>Our top LLM models are reserved for those that have an account with us</div>

        <div>Don't worry, it's free. Sign up below! 😅</div>
        <div className="w-full flex flex-row justify-center">
          <button
            className="btn btn-primary"
            onClick={() => {
              onClose();
              navigate(AUTH_PATH);
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
    </GeneralUsageError>
  );
};

// Update ExceededAnonymousUseError component
export const ExceededAnonymousUseError: ModalComponentProps = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  return (
    <GeneralUsageError isOpen={isOpen} onClose={onClose} title="Sign Up To Keep Using Composo">
      <div className="h-full w-full flex flex-col justify-between space-y-8">
        <div>You've run out of free credits 😢</div>
        <div>However, you can continue using Composo by signing up below! 😅</div>

        <div>
          For more info, read our docs{" "}
          <span className="link" onClick={() => window.open(DOCS_LINK, "_blank")}>
            here
          </span>
          .
        </div>
        <div className="w-full flex flex-row justify-center">
          <button
            className="btn btn-primary"
            onClick={() => {
              onClose();
              navigate(AUTH_PATH);
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
    </GeneralUsageError>
  );
};
