import { useState } from "react";
import { APP_PARAMETER_VAR_DEFAULTS } from "../globals";
import { toast } from "react-toastify";
import { VarSourceType, ParameterBaseType, ParameterTypes, TestcaseReturnType } from "./interfaces";
import { isValidString, isValidNumber, isValidChat } from "./utils";

export type ParameterMatchType = {
  source: VarSourceType;
  value: any;
};

export type ParameterMatchesType = {
  [key: string]: ParameterMatchType;
};

export type GetParameterMatchArrayFn = (parameters: ParameterBaseType[]) => ParameterMatchType[];

export type GetParameterMatchFn = (parameterName: string) => ParameterMatchType | null;

export type SetParameterMatchFn = (parameterName: string, source: VarSourceType, value: any) => Promise<void>;

export type ClearAllDatasetParameterMatchesFn = () => Promise<void>;

export const useParameterMatches = (
  testcases: TestcaseReturnType[] | null,
  parameters: ParameterTypes[] | null,
): [GetParameterMatchArrayFn, GetParameterMatchFn, SetParameterMatchFn, ClearAllDatasetParameterMatchesFn] => {
  const [parameterMatches, setParameterMatches] = useState<ParameterMatchesType>({});

  // If the testcases or parameters are null, return empty arrays and functions
  if (parameters == null) {
    return [() => [], () => null, async () => {}, async () => {}];
  }

  const clearAllDatasetParameterMatches = async () => {
    setParameterMatches((currentParameterMatches) => {
      const newParameterMatches = { ...currentParameterMatches };
      for (const key in newParameterMatches) {
        if (newParameterMatches[key]?.source === VarSourceType.DATASET) {
          newParameterMatches[key] = {
            source: VarSourceType.CONFIG,
            value: structuredClone(APP_PARAMETER_VAR_DEFAULTS[key]),
          };
        }
      }
      return newParameterMatches;
    });
  };

  const setParameterMatch = async (parameterName: string, source: VarSourceType, value: any) => {
    if (parameterName == null || source == null || value == null) {
      throw new Error("Null detected");
    }

    if (source === VarSourceType.DATASET) {
      const typeToValidator: { [key: string]: (input: any) => boolean } = {
        STRING: isValidString,
        INTEGER: isValidNumber,
        FLOAT: isValidNumber,
        CONVERSATIONHISTORY: isValidChat,
      };

      if (parameters != null && testcases != null) {
        const thisParameter = parameters.find((parameter) => parameter.name === parameterName);

        if (thisParameter == null) {
          throw new Error(`Parameter ${parameterName} not found`);
        }

        if (Object.keys(typeToValidator).includes(thisParameter.param_type)) {
          const validator = typeToValidator[thisParameter.param_type];
          // Check all testcases corresonding to the field in value are valid chats
          const chatValidities = testcases.map((element) => validator(element.inputs[value]));
          if (chatValidities.includes(false)) {
            toast.error(
              "Some of the data types for that link aren't compatible and will cause run errors. Please review the dataset and/or app links.",
              {
                autoClose: 12000,
              },
            );
          }
        }
      }
    }

    const parameterMatch = {
      source: source,
      value: value,
    };

    setParameterMatches((currentParameterMatches) => {
      const newParameterMatches = { ...currentParameterMatches };
      newParameterMatches[parameterName] = parameterMatch;
      return newParameterMatches;
    });
  };

  const getParameterMatch = (parameterName: string) => {
    return parameterMatches[parameterName];
  };

  const getParameterMatchArray = (parameters: ParameterBaseType[]) => {
    return parameters.map((parameter) => {
      return parameterMatches[parameter.name];
    });
  };

  return [getParameterMatchArray, getParameterMatch, setParameterMatch, clearAllDatasetParameterMatches];
};
