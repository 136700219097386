import React from "react";
import { DEMO_APPS } from "../../globals";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

interface ApiKeyBadgeProps {
  apiKey: string | null;
}

export const ApiKeyBadge: React.FC<ApiKeyBadgeProps> = ({ apiKey }) => {
  if (!apiKey) {
    return null;
  }

  const filteredApiKey = DEMO_APPS.map((app) => app.api_key).includes(apiKey) ? "<REDACTED>" : apiKey;

  const shortApiKey = filteredApiKey.length > 10 ? filteredApiKey.slice(0, 10) + "..." : filteredApiKey;

  return (
    <div className="flex flex-row items-center justify-center flex-start">
      <div className="flex-1 min-w-0 overflow-hidden whitespace-nowrap text-ellipsis">{shortApiKey}</div>
      <button
        className="ml-2 simple-icon-button"
        onClick={(e) => {
          e.preventDefault(); // stop the row from being clicked
          e.stopPropagation(); // stop the row from being clicked
          navigator.clipboard.writeText(filteredApiKey);
          toast.success("API Key copied to clipboard.");
        }}
      >
        <Square2StackIcon className="w-5 h-5" />
      </button>
    </div>
  );
};
