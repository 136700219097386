import React, { useState, useEffect } from "react";
import { signOut, getCurrentUser, updatePassword } from "aws-amplify/auth";
import { toast } from "react-toastify";
import { logMessage } from "../../utils/ApiRequest";
import { Breadcrumbs } from "../../presentation_components/primitives/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { User } from "../../utils/model";
import { UserType, UserUpdateType } from "../../utils/interfaces";
import { tenantedRouteBuilder } from "../../utils/tenantManager";
import urlJoin from "url-join";
import { DOCS_LINK } from "../../globals"; // Add this import

type TabType = "general" | "api" | "security";

export default function AccountSettings() {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [localFirstName, setLocalFirstName] = useState<string>("");
  const [user, setUser] = useState<UserType | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [activeTab, setActiveTab] = useState<TabType>("api");

  const navigate = useNavigate();

  useEffect(() => {
    User.fetchMe().then((response) => {
      setUser(response);
    });
  }, []);

  useEffect(() => {
    setLocalFirstName(user?.first_name || "");
  }, [user]);

  const handleNameChange = () => {
    if (user) {
      User.update(user.id, { first_name: localFirstName }).then((response) => {
        setUser(response);
        toast.success("Name updated successfully");
      });
    }
  };

  const handlePasswordChange = async () => {
    if (!newPassword.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)) {
      toast.error("Password must be at least 8 characters long, including a number and a symbol.");
      return;
    }

    try {
      const currentUser = await getCurrentUser();
      await updatePassword({ oldPassword: currentPassword, newPassword });
      toast.success("Password changed successfully");
      setCurrentPassword("");
      setNewPassword("");
    } catch (error: any) {
      console.error("Password change error:", error);

      if (error.message.includes("LimitExceededException")) {
        toast.error("Too many password change requests, please try again after some time.");
      } else {
        toast.error("Password change error");
      }

      logMessage("error", `Failed to change password. Error: ${error.message}`, "username");
    }
  };

  const generateApiKey = async () => {
    if (isGenerating || !user) return;

    setIsGenerating(true);
    try {
      const newApiKey = `key_${Math.random().toString(36).substring(2)}${Date.now().toString(36)}`;
      const updatedApiKeys = [...(user.api_keys || []), newApiKey];
      const updatedUser = await User.update(user.id, { api_keys: updatedApiKeys });
      setUser(updatedUser);

      await navigator.clipboard.writeText(newApiKey);
      toast.success("New API key generated and copied to clipboard");
    } catch (error) {
      toast.error("Failed to generate API key");
      console.error("API key generation error:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const deleteApiKey = async (keyToDelete: string) => {
    if (!user) return;

    try {
      const updatedApiKeys = user.api_keys.filter((key) => key !== keyToDelete);
      const updatedUser = await User.update(user.id, { api_keys: updatedApiKeys });
      setUser(updatedUser);
      toast.success("API key deleted successfully");
    } catch (error) {
      toast.error("Failed to delete API key");
      console.error("API key deletion error:", error);
    }
  };

  const copyApiKey = async (key: string) => {
    try {
      await navigator.clipboard.writeText(key);
      toast.success("API key copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy API key");
    }
  };

  const maskApiKey = (key: string) => {
    if (key.length <= 8) return key;
    return `${key.slice(0, 4)}...${key.slice(-4)}`;
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
      window.location.reload(); // Add this line to force a page refresh
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("Error signing out");
    }
  };

  const renderGeneralTab = () => (
    <div className="card bg-base-100 shadow-xl mb-6">
      <div className="card-body">
        <h2 className="card-title">Profile Information</h2>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            value={localFirstName}
            onChange={(e) => setLocalFirstName(e.target.value)}
            className="input input-bordered w-full"
            placeholder="Enter your name"
          />
        </div>
        <div className="card-actions justify-end mt-4">
          <button onClick={handleNameChange} className="btn btn-primary">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );

  const renderApiTab = () => (
    <div className="card bg-base-100 shadow-xl mb-6">
      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <h2 className="card-title">API Keys</h2>
          <button onClick={generateApiKey} disabled={isGenerating} className="btn btn-primary">
            {isGenerating ? "Generating..." : "Generate New API Key"}
          </button>
        </div>

        <div className="space-y-2">
          {user?.api_keys?.length === 0 && (
            <div className="alert alert-info">
              <span>No API keys generated yet.</span>
            </div>
          )}

          {user?.api_keys?.map((key, index) => (
            <div key={index} className="card bg-base-200">
              <div className="card-body p-4 flex-row justify-between items-center">
                <code className="text-sm">{maskApiKey(key)}</code>
                <div className="flex gap-2">
                  <button onClick={() => copyApiKey(key)} className="btn btn-ghost btn-sm">
                    Copy
                  </button>
                  <button
                    onClick={() => {
                      if (
                        window.confirm("Are you sure you want to delete this API key? This action cannot be undone.")
                      ) {
                        deleteApiKey(key);
                      }
                    }}
                    className="btn btn-ghost btn-sm text-error"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderSecurityTab = () => (
    <>
      <div className="card bg-base-100 shadow-xl mb-6">
        <div className="card-body">
          <h2 className="card-title">Change Password</h2>
          <div className="form-control gap-4">
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="input input-bordered"
              placeholder="Current Password"
            />
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="input input-bordered"
              placeholder="New password"
            />
          </div>
          <div className="card-actions justify-end mt-4">
            <button onClick={handlePasswordChange} className="btn btn-primary">
              Update Password
            </button>
          </div>
        </div>
      </div>

      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title text-error">Danger Zone</h2>
          <button onClick={handleSignOut} className="btn btn-outline btn-error">
            Sign Out
          </button>
          <p className="text-sm mt-4">
            If you wish to delete your account, please send an email to support@composo.ai.
          </p>
        </div>
      </div>
    </>
  );

  return (
    <div className="w-full py-8 h-full overflow-y-auto">
      {/* Header with logo and links */}
      <div className="w-full px-8 mb-8">
        <div className="flex justify-between items-center">
          <img
            src={urlJoin(import.meta.env.BASE_URL, "/icon.png")}
            className="h-6 hover:cursor-pointer"
            alt="Composo logo"
            onClick={() => {
              window.location.href = "/";
            }}
          />
          <div className="flex items-center space-x-4">
            {/* <a href="/api-playground" className="btn btn-ghost btn-sm">
              API Playground
            </a> */}
            <a href={DOCS_LINK} target="_blank" rel="noopener noreferrer" className="btn btn-ghost btn-sm">
              Docs
            </a>
          </div>
        </div>
      </div>

      {/* Rest of your component */}
      <div className="flex flex-col max-w-3xl mx-auto">
        <h1 className="text-2xl font-bold mb-8">Control Center</h1>

        <div className="tabs tabs-boxed mb-8">
          <button className={`tab ${activeTab === "api" ? "tab-active" : ""}`} onClick={() => setActiveTab("api")}>
            API Keys
          </button>
          <button
            className={`tab ${activeTab === "general" ? "tab-active" : ""}`}
            onClick={() => setActiveTab("general")}
          >
            General
          </button>

          <button
            className={`tab ${activeTab === "security" ? "tab-active" : ""}`}
            onClick={() => setActiveTab("security")}
          >
            Security
          </button>
        </div>

        {activeTab === "general" && renderGeneralTab()}
        {activeTab === "api" && renderApiTab()}
        {activeTab === "security" && renderSecurityTab()}
      </div>
    </div>
  );
}
