import { createContext, useContext } from "react";
import React, { useState } from "react";
import { ModalComponentProps, GeneralUsageError, ProModelError, ExceededAnonymousUseError, HelpModal } from "./Modals";

type OpenModalFunction = (modalCode: string, props: { [key: string]: any } | null) => void;

interface ModalContextType {
  openModal: OpenModalFunction;
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("Modal must be used within a ModalProvider");
  }

  return context;
};

export const ModalProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [modalProps, setModalProps] = useState<any>({});

  const closeModal = () => {
    setModalOpen(false);
    setActiveModal(null);
    setModalProps({});
  };

  const openModal: OpenModalFunction = (modalCode, props) => {
    setActiveModal(modalCode);
    setModalProps(props);
    setModalOpen(true);
  };

  const activeModals: { [key: string]: ModalComponentProps } = {
    GENERAL_ERROR_CODE: GeneralUsageError,
    PRO_ERROR_CODE: ProModelError,
    EXCEEDED_ANONYMOUS_USE_ERROR_CODE: ExceededAnonymousUseError,
    HELP_MODAL: HelpModal,
    // Add more modals here as needed
  };

  const ModalComponent = activeModals[activeModal as string] || null;

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalOpen && ModalComponent && <ModalComponent {...modalProps} isOpen={modalOpen} onClose={closeModal} />}
    </ModalContext.Provider>
  );
};
