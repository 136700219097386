import React from "react";

export const FloppyDiskIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 4.44772 4.44772 4 5 4H7V7C7 7.55228 7.44772 8 8 8H15C15.5523 8 16 7.55228 16 7V4H16.1716C16.4368 4 16.6911 4.10536 16.8787 4.29289L19.7071 7.12132C19.8946 7.30886 20 7.56321 20 7.82843V19C20 19.5523 19.5523 20 19 20H18V13C18 12.4477 17.5523 12 17 12H7C6.44772 12 6 12.4477 6 13V20H5C4.44772 20 4 19.5523 4 19V5ZM8 20H16V14H8V20ZM14 4H9V6H14V4ZM5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V7.82843C22 7.03278 21.6839 6.26972 21.1213 5.70711L18.2929 2.87868C17.7303 2.31607 16.9672 2 16.1716 2H5Z"
      />
    </svg>
  );
};

export const HistoryIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M2.954 7.807A1 1 0 0 0 3.968 9c.064.002.13-.003.196-.014l3-.5a1 1 0 0 0-.328-1.972l-.778.13a8 8 0 1 1-2.009 6.247 1 1 0 0 0-1.988.219C2.614 18.11 6.852 22 12 22c5.523 0 10-4.477 10-10S17.523 2 12 2a9.975 9.975 0 0 0-7.434 3.312l-.08-.476a1 1 0 0 0-1.972.328l.44 2.643ZM12 7a1 1 0 0 1 1 1v3.586l2.207 2.207a1 1 0 0 1-1.414 1.414l-2.5-2.5A1 1 0 0 1 11 12V8a1 1 0 0 1 1-1Z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const RainbowWandIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 110" {...props}>
      <defs>
        <linearGradient id="historyGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#ffca2f", stopOpacity: 1 }} />
          <stop offset="25%" style={{ stopColor: "#f17721", stopOpacity: 1 }} />
          <stop offset="50%" style={{ stopColor: "#3f54b1", stopOpacity: 1 }} />
          <stop offset="75%" style={{ stopColor: "#107169", stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: "#faa9ca", stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path
        fill="url(#historyGradient)"
        d="M46.92,39.23l-8.47,8.5a2.16,2.16,0,0,1-3,0L18.67,31a2.68,2.68,0,0,1,0-3.78l7.75-7.74a2.66,2.66,0,0,1,3.78,0L46.92,36.2A2.14,2.14,0,0,1,46.92,39.23Zm6.68,3.65a2.14,2.14,0,0,0-3,0l-8.48,8.5a2.15,2.15,0,0,0,0,3L88.57,100.9a4.3,4.3,0,0,0,6.08,0l5.45-5.45a4.3,4.3,0,0,0,0-6.08ZM73.48,19.13A8.16,8.16,0,0,1,67,12.66l-1.35-7a1.07,1.07,0,0,0-2.11,0l-1.35,7a8.15,8.15,0,0,1-6.47,6.47l-7,1.35a1.07,1.07,0,0,0,0,2.11l7,1.35a8.17,8.17,0,0,1,6.47,6.47l1.35,7a1.07,1.07,0,0,0,2.11,0l1.35-7a8.19,8.19,0,0,1,6.47-6.47l7-1.35a1.07,1.07,0,0,0,0-2.11Zm-61.26.14.62,3.21a1.07,1.07,0,0,0,2.11,0l.62-3.21a5.69,5.69,0,0,1,4.51-4.5l3.2-.62a1.07,1.07,0,0,0,0-2.11l-3.2-.62a5.69,5.69,0,0,1-4.51-4.5L15,3.71a1.07,1.07,0,0,0-2.11,0l-.62,3.21a5.68,5.68,0,0,1-4.5,4.5L4.51,12a1.07,1.07,0,0,0,0,2.11l3.21.62A5.68,5.68,0,0,1,12.22,19.27Zm7.27,36.08a3.85,3.85,0,0,1,3.05,3l.38,2a1.07,1.07,0,0,0,2.11,0l.37-2a3.87,3.87,0,0,1,3.05-3L30.39,55a1.07,1.07,0,0,0,0-2.11l-1.94-.38a3.85,3.85,0,0,1-3.05-3l-.37-2a1.07,1.07,0,0,0-2.11,0l-.38,2a3.84,3.84,0,0,1-3.05,3l-1.94.38a1.07,1.07,0,0,0,0,2.11Z"
      />
    </svg>
  );
};

export const RepeatIcon: React.FC<React.SVGProps<SVGSVGElement> & { className?: string }> = (props) => {
  const { className, ...rest } = props;
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M13 22L10 19M10 19L13 16M10 19H15C18.866 19 22 15.866 22 12C22 9.2076 20.3649 6.7971 18 5.67363M6 18.3264C3.63505 17.2029 2 14.7924 2 12C2 8.13401 5.13401 5 9 5H14M14 5L11 2M14 5L11 8"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
