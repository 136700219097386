import { useEffect, useState } from "react";
import { Evaluatorconfig, Testcase } from "../utils/model";

export const useEvaluatorconfigs = (testcases: Testcase[] | null) => {
  const [evaluatorconfigsByTestcaseId, setEvaluatorconfigsByTestcaseId] = useState<Record<string, Evaluatorconfig[]>>(
    {},
  );

  useEffect(() => {
    const fetchMissingEvaluatorConfigs = async () => {
      if (testcases) {
        const newEvaluatorconfigsByTestcaseId: Record<string, Evaluatorconfig[]> = {};
        const fetchPromises = testcases.map(async (testcase) => {
          if (!evaluatorconfigsByTestcaseId[testcase.id]) {
            const data = await Evaluatorconfig.fetchAll({ keyValueFilters: { datacase_id: testcase.id } });
            newEvaluatorconfigsByTestcaseId[testcase.id] = data;
          }
        });
        await Promise.all(fetchPromises);
        setEvaluatorconfigsByTestcaseId((prevState) => ({
          ...prevState,
          ...newEvaluatorconfigsByTestcaseId,
        }));
      } else {
        setEvaluatorconfigsByTestcaseId({});
      }
    };
    fetchMissingEvaluatorConfigs();
  }, [testcases]);

  const setEvaluatorconfigs = (testcaseId: string, newEvaluatorconfigs: Evaluatorconfig[]) => {
    setEvaluatorconfigsByTestcaseId((prev) => ({ ...prev, [testcaseId]: newEvaluatorconfigs }));
  };

  return { evaluatorconfigsByTestcaseId, setEvaluatorconfigs };
};
