import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { DropdownCase, DropdownDivider, DropdownOption } from "./primitives/Dropdown";
import { TenantType, UserType } from "../utils/interfaces";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Tenant, User } from "../utils/model";
import { TenantContext } from "../utils/TenantContext";
import { setTenant } from "../utils/tenantManager";

export const TenantSwitcher = () => {
  const [tenants, setTenants] = useState<TenantType[]>([]);
  const [authUser, setAuthUser] = useState<UserType | null>(null);
  const activeTenant = useContext<TenantType | null>(TenantContext);

  useEffect(() => {
    Tenant.fetchAll().then((tenants) => {
      setTenants(tenants);
    });

    User.fetchMe().then((response) => {
      setAuthUser(response);
    });
  }, []);

  return (
    <DropdownCase
      label={activeTenant?.id ? activeTenant.name : "Workspace"}
      ChangeIcon={ChevronUpDownIcon}
      dropdownClassName="w-40 ml-4"
    >
      {authUser && (
        <div className="text-xs text-maindarkgray w-full truncate">
          Signed in as
          <br />
          {authUser.email}
        </div>
      )}

      <DropdownDivider label="Workspaces"></DropdownDivider>
      {tenants.map((tenant) => (
        <DropdownOption
          key={tenant.id}
          selected={tenant.id === activeTenant?.id}
          onClick={() => {
            setTenant(tenant);
          }}
        >
          {tenant.name}
        </DropdownOption>
      ))}
      {/* <DropdownOption className="justify-between">
        <div>New</div> <div className="badge badge-accent">pro</div>
      </DropdownOption> */}
    </DropdownCase>
  );
};
