import React from "react";
import { useState } from "react";
import { PlusIcon, ArrowRightIcon, RectangleGroupIcon } from "@heroicons/react/24/outline";
import { BeakerIcon } from "@heroicons/react/20/solid";

interface PlaygroundToggleProps {
  advancedMode: boolean;
  setAdvancedMode: (mode: boolean) => void;
}

export const PlaygroundToggle: React.FC<PlaygroundToggleProps> = ({ advancedMode, setAdvancedMode }) => {
  return (
    <div className="w-58 h-10 rainbow-border flex flex-row relative text-xs font-semibold">
      <div
        className={`h-8 w-28 absolute top-0 bg-maindarkgray opacity-25 transition-all rounded-full ${
          advancedMode ? "start-28" : "start-0"
        }`}
      ></div>
      <div
        className="h-8 w-28 flex flex-row items-center justify-center space-x-2 hover:cursor-pointer"
        onClick={() => setAdvancedMode(false)}
      >
        <RectangleGroupIcon className="w-5 h-5 fill-mainorange text-mainorange" />
        <div>Playground</div>
      </div>
      <div
        className="h-8 w-28 flex flex-row items-center justify-center space-x-2 hover:cursor-pointer"
        onClick={() => setAdvancedMode(true)}
      >
        <BeakerIcon className="w-5 h-5 fill-mainpurple text-mainpurple" />
        <div>Test Suite</div>
      </div>
    </div>
  );
};
