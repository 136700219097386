import React, { useEffect, useMemo, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { Evalcase, Evalset } from "../utils/model";
import { EvalcaseLoadSpec, EvalsetLoadSpec, LoadMethod, ValidLoadSpec } from "./AppPlayground/controllers";

interface HistoryObject {
  loadSpec: ValidLoadSpec;
  output: any | null;
  created: string;
  appName: string;
}

interface RestoreButtonProps {
  onClick: () => void;
  label: string;
}

const RestoreButton: React.FC<RestoreButtonProps> = ({ onClick, label }) => {
  return (
    <button className="btn btn-primary font-normal text-xs w-16 normal-case" onClick={onClick}>
      {label}
    </button>
  );
};

interface HistoryItemProps {
  historyObject: HistoryObject;
  pushToPlayground: (loadSpec: ValidLoadSpec, playgroundId: string) => void;
  appPlaygrounds: { id: string }[];
}

const HistoryItem: React.FC<HistoryItemProps> = ({ historyObject, pushToPlayground, appPlaygrounds }) => {
  const [showLoadOptions, setShowLoadOptions] = useState(false);
  // const { evalsetId, evalcaseId, appName, appApiKey, output, created } = historyObject;

  return (
    <>
      {showLoadOptions ? (
        <div
          key={historyObject.created}
          onClick={() => setShowLoadOptions(false)}
          className="h-16 p-2 w-full flex flex-col bg-neutral justify-between items-end rounded-lg"
        >
          <div className="w-full flex flex-row space-x-2 justify-center">
            {appPlaygrounds.length === 1 ? (
              <RestoreButton
                label="Load"
                onClick={() => pushToPlayground(historyObject.loadSpec, appPlaygrounds[0].id)}
              />
            ) : appPlaygrounds.length === 2 ? (
              <>
                <RestoreButton
                  label="Load Left"
                  onClick={() => pushToPlayground(historyObject.loadSpec, appPlaygrounds[0].id)}
                />
                <RestoreButton
                  label="Load Right"
                  onClick={() => pushToPlayground(historyObject.loadSpec, appPlaygrounds[1].id)}
                />
              </>
            ) : appPlaygrounds.length === 3 ? (
              <>
                <RestoreButton
                  label="Load Left"
                  onClick={() => pushToPlayground(historyObject.loadSpec, appPlaygrounds[0].id)}
                />
                <RestoreButton
                  label="Load Middle"
                  onClick={() => pushToPlayground(historyObject.loadSpec, appPlaygrounds[1].id)}
                />
                <RestoreButton
                  label="Load Right"
                  onClick={() => pushToPlayground(historyObject.loadSpec, appPlaygrounds[2].id)}
                />
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <button
          key={historyObject.created}
          className="h-16 w-full group flex flex-row items-start justify-between px-4 hover:bg-neutral overflow-hidden rounded-lg"
          onClick={() => {
            setShowLoadOptions(true);
          }}
        >
          {/* TIME */}
          {/* No idea why, but min-w-16 doesn't work it needs arbitrary value use */}
          <div className="h-full w-16 min-w-[4rem] text-xs text-maindarkgray whitespace-nowrap flex flex-col items-center justify-center">
            {moment(historyObject.created).format("hh:mm A")}
          </div>
          {/* MIDDLE DIVIDER UI */}
          <div className="relative mx-4 h-full border border-primary">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary rounded-full w-1.5 h-1.5"></div>
          </div>
          {/* HISTORY SNIPPET */}
          <div className="h-full overflow-hidden grow py-2 flex flex-col justify-center items-center">
            <div className="text-left text-sm w-full truncate">
              {historyObject.output != null ? JSON.stringify(historyObject.output) : historyObject.loadSpec.evalsetId}
            </div>
            <div className="text-left text-xs w-full text-maindarkgray truncate">{historyObject.appName}</div>
          </div>
        </button>
      )}
    </>
  );
};

interface HistorySidebarProps {
  isShowing: boolean;
  setIsShowing: (isShowing: boolean) => void;
  pushToPlayground: (loadSpec: ValidLoadSpec, playgroundId: string) => void;
  appPlaygrounds: { id: string }[];
  displayEvalcaseHistory?: boolean;
}

export const HistorySidebar: React.FC<HistorySidebarProps> = ({
  isShowing,
  setIsShowing,
  pushToPlayground,
  appPlaygrounds,
  displayEvalcaseHistory = false,
}) => {
  const [evalcaseHistory, setEvalcaseHistory] = useState<HistoryObject[]>([]);
  const [evalsetHistory, setEvalsetHistory] = useState<HistoryObject[]>([]);

  const groupHistory = (history: HistoryObject[]): Record<string, HistoryObject[]> => {
    return history.reduce((acc: Record<string, HistoryObject[]>, historyObject) => {
      const thisDate = moment(historyObject.created).format("Do MMM YYYY");
      if (acc[thisDate] == null) {
        acc[thisDate] = [];
      }
      acc[thisDate].push(historyObject);
      return acc;
    }, {});
  };

  const getHistory = async () => {
    const evalsets = await Evalset.fetchAll();

    const relevantEvalsets = evalsets
      // .filter((evalset) => {
      //   return moment(evalset.created).isAfter(moment().subtract(30, "days"));
      // })
      .filter((evalset) => {
        return evalset.progress === 100;
      })
      .sort((a, b) => {
        return moment(b.created).diff(moment(a.created));
      });

    const evalcases = await Evalcase.fetchAll();

    // filer evalcases by whether they have a relevant evalset_id
    const relevantEvalcases = evalcases.filter((evalcase) => {
      return relevantEvalsets.some((evalset) => evalset.id === evalcase.evalset_id);
    });

    setEvalsetHistory(
      relevantEvalsets
        .filter((evalset) => {
          // remove evalsets with null dataset_id
          return evalset.dataset_id != null;
        })
        .map((evalset) => {
          return {
            loadSpec: {
              appId: evalset.app_id,
              version: evalset.version,
              apiKey: evalset.app_api_key,
              evalsetId: evalset.id,
              evalcaseId: null,
              customValues: null,
              loadMethod: LoadMethod.EVALSET,
            } as EvalsetLoadSpec,
            appName: evalset.app_name,
            created: evalset.created,
          } as HistoryObject;
        }),
    );

    setEvalcaseHistory(
      relevantEvalcases
        .filter((evalcase) => {
          return evalcase.output != null;
        })
        .sort((a, b) => {
          return moment(b.created).diff(moment(a.created));
        })
        .map((evalcase) => {
          return {
            loadSpec: {
              appId: evalcase.app_id,
              version: evalsets.find((evalset) => evalset.id === evalcase.evalset_id)?.version,
              evalsetId: evalcase.evalset_id,
              evalcaseId: evalcase.id,
              loadMethod: LoadMethod.EVALCASE,
            } as EvalcaseLoadSpec,
            appName: evalcase.app_name,
            created: evalcase.created,
            output: evalcase.output,
          } as HistoryObject;
        }),
    );
  };

  // Note this works for both evalcases and evalsets because they both have a created field
  const groupedEvalcaseHistory = useMemo(() => {
    return groupHistory(evalcaseHistory);
  }, [evalcaseHistory]);

  const groupedEvalsetHistory = useMemo(() => {
    return groupHistory(evalsetHistory);
  }, [evalsetHistory]);

  const groupedHistory = useMemo(() => {
    return displayEvalcaseHistory ? groupedEvalcaseHistory : groupedEvalsetHistory;
  }, [displayEvalcaseHistory, groupedEvalcaseHistory, groupedEvalsetHistory]);

  useEffect(() => {
    getHistory();
  }, []);

  // get history every time the sidebar is opened
  useEffect(() => {
    if (isShowing) {
      getHistory();
    }
  }, [isShowing]);

  const historyTitle = useMemo(() => {
    return displayEvalcaseHistory ? "Playground History" : "Test Suite History";
  }, [displayEvalcaseHistory]);

  return (
    <div
      className={`z-30 fixed right-0 top-0 h-screen ease-in-out transition-all duration-500   ${
        isShowing ? "w-72 " : "w-0"
      }`}
    >
      {/* Child is hardcoded at parent max width to avoid compression when sidebar hides */}
      <div className="w-72 h-full overflow-hidden flex flex-col bg-white shadow border-l">
        <div className="min-h-16 flex flex-col items-end p-2 pl-4 border-b border-mainlightgray">
          <XMarkIcon className="simple-icon-button" onClick={() => setIsShowing(false)} />
          <div className="font-semibold w-full">{historyTitle}</div>
        </div>
        <div className="w-full grow flex flex-col overflow-x-hidden overflow-y-scroll no-scrollbar space-y-4 mt-4">
          {groupedHistory == null ? (
            <div className="w-full h-full flex flex-row items-center justify-center">
              <div className="loading loading-spinner" />
            </div>
          ) : (
            Object.keys(groupedHistory).map((group, index) => (
              <div className="flex flex-col w-full" key={index}>
                <div className="px-4 py-2 bg-white">
                  <div className="font-semibold text-sm">{group}</div>
                </div>
                {groupedHistory[group].map((historyObject, index) => (
                  <HistoryItem
                    key={index}
                    historyObject={historyObject}
                    pushToPlayground={pushToPlayground}
                    appPlaygrounds={appPlaygrounds}
                  />
                ))}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
