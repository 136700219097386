import React from "react";
import { TooltipButton } from "./Tooltip";
import { ArrowPathIcon, CheckCircleIcon, NoSymbolIcon } from "@heroicons/react/24/outline";

import { CRITERIA_DETAILS } from "../../globals";
import "../../index.css";
import { Evalcase, Evalset, Evaluatorconfig, Evaluatorresult } from "../../utils/model";
import { useMultipleEvaluatorConfigs } from "../AppPlayground/EvalcaseResultDisplay";

export const ProgressBadge = ({ run }: { run: Evalset }) => {
  // Create the status component
  if (run.error !== null) {
    return <span className="badge badge-error p-4">Errored</span>;
  } else if (!run || run.progress === undefined || run.progress === null) {
    return <span className="badge badge-warning p-4">Waiting</span>;
  } else if (run.progress === 100) {
    return <span className="badge badge-success p-4">Completed</span>;
  } else {
    return (
      <span className="badge badge-success p-4 flex flex-row items-center">
        In Progress <span className="loading loading-spinner loading-xs ml-2"></span>
      </span>
    );
  }
};

export const ResultsSummary = ({ run, evalcases }: { run: Evalset | null; evalcases: Evalcase[] | null }) => {
  const keys = ["Highlights", "Lowlights", "Improvement Suggestions"];

  const { evaluatorConfigsById, evaluatorResultsById } = useMultipleEvaluatorConfigs(evalcases);

  if (run == null || run.results_summary == null || evalcases == null) {
    return <div className="h-72"></div>;
  }

  // check whether all the evalcases have evaluator results
  const allEvaluatorsPresent = evalcases.every((evalcase) => {
    return evalcase.id in evaluatorResultsById;
  });
  if (!allEvaluatorsPresent) {
    return <div className="h-72"></div>;
  }

  const { casesPassed, evaluatorsPassed, totalEvaluators } = evalcases.reduce(
    (acc, evalcase) => {
      const evaluatorConfig = evaluatorConfigsById[evalcase.id];
      const evaluatorResult = evaluatorResultsById[evalcase.id];

      if (evaluatorConfig == null || evaluatorResult == null) {
        return acc;
      }

      const { numPassed, outOf } = Evalcase.passRate(evaluatorConfig, evaluatorResult);

      return {
        casesPassed: acc.casesPassed + (numPassed === outOf ? 1 : 0),
        evaluatorsPassed: acc.evaluatorsPassed + numPassed,
        totalEvaluators: acc.totalEvaluators + outOf,
      };
    },
    { casesPassed: 0, evaluatorsPassed: 0, totalEvaluators: 0 },
  );
  const evaluatorPassRate = totalEvaluators === 0 ? 0 : Math.round(100 * (evaluatorsPassed / totalEvaluators));

  return (
    <div className="simple-border p-4 bg-mainlightgray mt-4 text-sm flex-row space-x-4 w-full">
      <div className="h-60 space-y-2">
        <h3>Scores</h3>
        <div className={`stats bg-white w-40`}>
          <div className="stat">
            <div
              className={`stat-value ${casesPassed > evalcases.length * 0.9 ? "text-maingreen" : "text-mainorange"}`}
            >
              {casesPassed} / {evalcases.length}
            </div>
            <div className="stat-desc w-full truncate">cases passed</div>
          </div>
        </div>
        <div className={`stats bg-white w-40`}>
          <div className="stat">
            <div className={`stat-value ${evaluatorPassRate > 89 ? "text-maingreen" : "text-mainorange"}`}>
              {evaluatorPassRate}%
            </div>
            <div className="stat-desc w-full truncate">evaluators passed</div>
          </div>
        </div>
      </div>
      <div className="h-60 overflow-y-scroll no-scrollbar ">
        <h3 className="mb-2">Insights</h3>
        {/* <div className="card card-bordered p-2"> */}
        {keys.map((key) => {
          const value = run.results_summary?.split(`${key}:`)[1]?.split("\n")[0];
          return (
            <p key={key} className="text-sm">
              <span className="font-bold">{`${key}: `}</span>
              <span>{value}</span>
            </p>
          );
        })}
        {/* </div> */}
      </div>
    </div>
  );
};

export const CaseScoreStat = ({
  criteria,
  criteriaResults,
  className,
}: {
  criteria: Evaluatorconfig | null;
  criteriaResults: Evaluatorresult | null;
  className?: string;
}) => {
  const maxScore = criteria ? CRITERIA_DETAILS[criteria.config.type].maxScore : null;
  if (criteria == null || criteriaResults?.score == null || maxScore == null) {
    return (
      <div className={`stats bg-white ${className}`}>
        <div className="stat">
          <div className="stat-title">Score</div>
          <div className="stat-value">-</div>
          <div className="stat-desc w-full truncate">on evaluator</div>
        </div>
      </div>
    );
  }

  const percentageScore = criteriaResults.score / maxScore;
  let scoreColour;
  if (percentageScore > 0.8) {
    scoreColour = "text-maingreen";
  } else {
    scoreColour = "text-mainorange";
  }

  return (
    <div className={`stats bg-white ${className}`}>
      <div className="stat">
        <div className="stat-title">Score</div>
        <div className={`stat-value ${scoreColour}`}>
          {criteriaResults.score} / {maxScore}
        </div>
        <div className="stat-desc w-full truncate">
          on {CRITERIA_DETAILS[criteria.config.type].displayName} evaluator
        </div>
      </div>
    </div>
  );
};
