import React, { useEffect, useState } from "react";
import { TenantType } from "./interfaces";
import { getTenant, initializeTenant } from "./tenantManager";

export const TenantContext = React.createContext<TenantType | null>(null);

export const TenantProvider = ({ children }: { children: React.ReactNode }) => {
  const [parsedTenant, setParsedTenant] = useState<TenantType | null>(null);

  useEffect(() => {
    const tenantSetup = async () => {
      const parsedTenant = await getTenant();
      console.log("Parsed tenant", parsedTenant);
      if (parsedTenant) {
        setParsedTenant(parsedTenant);
      } else {
        await initializeTenant();
      }
    };
    console.log("Setting up tenant");
    tenantSetup();
  }, []);

  if (!parsedTenant) {
    return (
      <div className="flex flex-row justify-center items-center h-screen w-screen">
        <div className="loading loading-spinner"></div>
      </div>
    );
  }

  return <TenantContext.Provider value={parsedTenant}>{children}</TenantContext.Provider>;
};
