import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Changed this line
import { toast } from "react-toastify";
import { App, Tenant } from "../../utils/model";
import { tenantedRouteBuilder } from "../../utils/tenantManager";

const AppCreationPage: React.FC = () => {
  const navigate = useNavigate(); // Added this line

  const [settingUp, setSettingUp] = useState<boolean>(true);
  const [apiKey, setApiKey] = useState<string>("");
  const [doingNewRegistration, setDoingNewRegistration] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [formFields, setFormFields] = useState<{ name: string; tenantId: string }>({ name: "", tenantId: "" });

  const registerApp = async () => {
    setDoingNewRegistration(true);

    App.create({ name: formFields.name, api_key: apiKey }, formFields.tenantId)
      .then((newApp) => {
        toast.info("App registered, redirecting...");
        setTimeout(() => {
          const redirectRoute = tenantedRouteBuilder(`/playground?mode=app&load1=${newApp.id}`, formFields.tenantId);
          // Changed this part to use navigate instead of window.location
          navigate(redirectRoute);
        });
      })
      .catch((error) => {
        setErrorMessage(
          "There was an error registering the app. Please check this app has an active runner and isn't already registered.",
        );
        console.error(error);
        return null;
      })
      .finally(() => {
        setDoingNewRegistration(false);
      });
  };

  const pageLoad = async () => {
    const url = new URL(window.location.href);
    const apiKey = url.searchParams.get("api_key");

    if (!apiKey) {
      setErrorMessage("No ID provided in the URL");
      return;
    }

    setApiKey(apiKey);

    await Tenant.fetchAll().then(async (tenants) => {
      setTenants(tenants);

      if (tenants.length === 0) {
        setErrorMessage("Please log in before registering an app");
        return;
      }
    });

    setSettingUp(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  if (errorMessage !== null) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center space-y-12">
        <h2>Error</h2>
        <div className="w-2/6 text-justify">{errorMessage}</div>
        <div>
          <Link to={"/"}>Return home</Link>
        </div>
      </div>
    );
  } else if (settingUp) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center space-y-4">
        <div className="loading loading-spinner"></div>
      </div>
    );
  } else {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center space-y-4">
        <h1>Link App</h1>
        <label className="input input-bordered flex items-center gap-2 w-96 bg-white">
          App Name
          <input
            type="text"
            className="grow"
            placeholder="My App"
            value={formFields.name}
            onChange={(e) =>
              setFormFields((currentFields) => ({
                ...currentFields,
                name: e.target.value,
              }))
            }
          />
        </label>
        <select
          className="select w-96"
          value={formFields.tenantId}
          onChange={(e) =>
            setFormFields((currentFields) => {
              return { ...currentFields, tenantId: e.target.value };
            })
          }
        >
          <option disabled value="">
            Select workspace
          </option>
          {tenants.map((tenant) => (
            <option key={tenant.id} value={tenant.id}>
              {tenant.name}
            </option>
          ))}
        </select>
        <div className="h-4"></div>
        <button className="btn btn-primary w-24 mt-8" onClick={() => registerApp()}>
          {doingNewRegistration ? <div className="loading loading-spinner loading-sm"></div> : "Link"}
        </button>
      </div>
    );
  }
};

export default AppCreationPage;
