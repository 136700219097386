import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { CRITERIA_DETAILS } from "../globals";
import {
  CriteriaTypes,
  CriteriaSelectorEnum,
  NoConfigType,
  JsonConfigType,
  AiGradedCustomConfigType,
  AiGradedFormulaConfigType,
  RagContextFaithfulnessConfigType,
  RagAnswerRelevanceConfigType,
  EvaluatorconfigUpdateType,
} from "../utils/interfaces";
import { Evaluatorconfig } from "../utils/model";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PipelineBuilder } from "./PipelineEditor/PipelineEditor";
import { set } from "date-fns";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";

const PRESET_RUBRICS: { [key: string]: { [key: number]: string } } = {
  Grammar: {
    1: "The writing is ridden with basic and blatant grammatical errors, such as improper verb tense usage, incorrect subject-verb agreement, punctuation errors, and spelling errors that significantly impede comprehension.",
    2: "The writing has a minor number of grammatical mistakes. While these mistakes don't entirely impede comprehension, they do detract from the clarity and professionalism of the writing. There might be some errors in verb tense usage, punctuation, or sentence structure, but the overall meaning can be understood.",
    3: "This score indicates nearly flawless grammar and effective sentence structure. The writing uses appropriate verb tenses, punctuation, and demonstrates a solid understanding of writing conventions. It is polished, and free of spelling and punctuation errors, promoting clear and understandable communication.",
  },
  "Summarisation Ability": {
    1: "A score of 1 attests that the summary provided lacks coherency and misses major points of the original content. It's either too vague or overextended, and it doesn't accurately capture the essence or purpose of the original material.",
    2: "A score of 2 indicates a moderate summarisation ability; the summary covers some key points and provides a basic understanding of the content. However, it may lack depth or detail, include extraneous information, or omit important aspects of the source material.",
    3: "A score of 3 symbolizes excellent summarisation skills. The candidate distills the content down to its most essential parts, conveying all major points in a concise and coherent way. The summary accurately reflects the original content's tone, intent, and message without adding unnecessary details or departing from the main points.",
  },
  "Presence of Personal Information": {
    1: "A score of 1 signifies that there was a gross violation of privacy; a substantial amount of Personally Identifiable Information (PII) was disclosed without appropriate permission or need, potentially leading to a significant security & privacy breach.",
    2: "A score of 2 indicates a minor lapse in privacy etiquette; a minimal amount of less sensitive Personally Identifiable Information (PII) may have been disclosed.",
    3: "A score of 3 represents a model adherence to data protection practices; No PII was disclosed. The individual showed a complete respect and understanding for privacy norms and appropriate handling of sensitive data, ensuring no personal data was shared, leaving no room for privacy infringements.",
  },
  "Tone of Voice": {
    1: "A score of 1 suggests that the tone of voice employed was highly inappropriate or inconsistent with the context, displaying a lack of understanding of the expected communication norms. It may also signify a tone that could be seen as disrespectful or unprofessional.",
    2: "A score of 2 indicates a satisfactory use of tone of voice. There may be occasional inconsistencies but generally, the tone employed is suited to the context. It may be slightly off in terms of professionalism or appropriateness, but not to an extent that severely impairs the message.",
    3: "A score of 3 means that the tone of voice used was exactly appropriate for the context. The speaker demonstrated a thorough understanding of how to use tone to effectively convey a message, and used a tone that was appropriately professional or casual (depending on the context), respectful, and engaging throughout.",
  },
  Accuracy: {
    1: "A score of 1 indicates that the answer provided has poor accuracy; it includes multiple errors and misconceptions, and shows little to no understanding of the material. The answer substantially deviates from the correct response.",
    2: "A score of 2 shows a moderate level of accuracy; the answer provided does have some errors, yet it manages to convey a partial understanding of the material. However, the answer may miss or misinterpret some important aspects of the question.",
    3: "A score of 3 represents a high degree of accuracy; the answer provided is essentially correct, with little to no errors. It shows a clear understanding of the material, matches the question directly, and fully realizes the solution or response.",
  },
};

const PRESET_CRITERIA: { [key: string]: string } = {
  Grammar: "Check if the grammar is correct and coherent.",
  "Summarisation Ability": "Check if the summary is concise and coherent.",
  "Presence of Personal Information": "Check if any personal information is disclosed.",
  "Tone of Voice": "Check if the tone of voice is appropriate.",
  Accuracy: "Check if the information provided is accurate.",
};

interface CriteriaEditorProps {
  open: boolean;
  criteria: Evaluatorconfig;
  updateCriteria: (criteria: EvaluatorconfigUpdateType) => void;
  deleteEvaluatorconfig: (id: string) => void;
}

export const CriteriaEditor: React.FC<CriteriaEditorProps> = ({
  open,
  criteria,
  updateCriteria,
  deleteEvaluatorconfig,
}) => {
  const [localCriteria, setLocalCriteria] = useState<Evaluatorconfig>(criteria);

  useEffect(() => {
    setLocalCriteria(criteria);
  }, [criteria]);

  const setLocalConfig = (config: CriteriaTypes) => {
    setLocalCriteria({ ...localCriteria, config });
  };

  const pushCriteria = () => {
    updateCriteria(localCriteria); // Heavy operation on blur
  };

  const setPipelineConfig = (pipelineConfig: any) => {
    updateCriteria({ ...localCriteria, pipeline_spec: pipelineConfig });
  };

  return (
    <div
      className={`w-full flex flex-col card-container min-h-12 bg-mainlightgray p-4 text-sm space-y-4 ${
        open ? "block" : "hidden"
      }`}
    >
      {/* SELECT BOX AND DESCRIPTION */}

      <div className="w-full flex flex-row items-center justify-between space-x-4">
        <select
          value={localCriteria.config.type}
          className="select select-primary select-sm max-w-48 mt-0"
          onChange={(e) => {
            updateCriteria({
              ...localCriteria,
              config: {
                ...localCriteria.config,
                type: e.target.value as CriteriaSelectorEnum,
              },
            });
          }}
        >
          {Object.keys(CRITERIA_DETAILS).map((criteriaName: string, index) => (
            <option value={criteriaName} key={index}>
              {CRITERIA_DETAILS[criteriaName as CriteriaSelectorEnum].displayName}
            </option>
          ))}
        </select>
        <div className="tooltip" data-tip="Delete evaluator">
          <TrashIcon
            className="tiny-icon-button text-red-700"
            onClick={() => deleteEvaluatorconfig(localCriteria.id)}
          />
        </div>
      </div>

      <div className="text-xs">
        <ReactMarkdown>{CRITERIA_DETAILS[localCriteria.config.type]?.explanation}</ReactMarkdown>
      </div>

      <div className="flex flex-col space-y-4">
        {/* CRITERIA CONFIG SWITCH */}
        {localCriteria.config.type === CriteriaSelectorEnum.AI_GRADED_FORMULA ? (
          // AI GRADED FORMULA
          <AiGradedFormulaConfig
            localCriteria={localCriteria.config as AiGradedFormulaConfigType}
            setLocalCriteria={setLocalConfig}
            pushCriteria={pushCriteria}
          />
        ) : localCriteria.config.type === CriteriaSelectorEnum.EXACT_MATCH ||
          localCriteria.config.type === CriteriaSelectorEnum.VECTOR_SIMILARITY ||
          localCriteria.config.type === CriteriaSelectorEnum.AI_GRADED_SIMILARITY ||
          localCriteria.config.type === CriteriaSelectorEnum.RAG_CONTEXT_PRECISION ||
          localCriteria.config.type === CriteriaSelectorEnum.RAG_CONTEXT_RECALL ? (
          <LabelConfig
            localCriteria={localCriteria.config}
            setLocalCriteria={setLocalConfig}
            pushCriteria={pushCriteria}
          />
        ) : localCriteria.config.type === CriteriaSelectorEnum.AI_GRADED_CUSTOM ? (
          <AiGradedCustomConfig
            localCriteria={localCriteria.config as AiGradedCustomConfigType}
            setLocalCriteria={setLocalConfig}
            pushCriteria={pushCriteria}
          />
        ) : (
          <></>
        )}
      </div>

      <details
        className={`collapse collapse-arrow bg-gray-100 border-base-300 border ${
          [
            CriteriaSelectorEnum.NO_CRITERIA,
            CriteriaSelectorEnum.RAG_ANSWER_RELEVANCE,
            CriteriaSelectorEnum.RAG_CONTEXT_FAITHFULNESS,
            CriteriaSelectorEnum.RAG_CONTEXT_PRECISION,
            CriteriaSelectorEnum.RAG_CONTEXT_RECALL,
          ].includes(localCriteria.config.type)
            ? "hidden"
            : "block"
        }`}
      >
        <summary className="collapse-title ">Advanced settings</summary>
        <div className="collapse-content">
          <PipelineBuilder pipelineSpec={localCriteria.pipeline_spec} setPipelineSpec={setPipelineConfig} />
        </div>
      </details>
    </div>
  );
};

interface AiGradedFormulaConfigProps {
  localCriteria: AiGradedFormulaConfigType;
  setLocalCriteria: (x: CriteriaTypes) => void;
  pushCriteria: () => void;
}

const AiGradedFormulaConfig: React.FC<AiGradedFormulaConfigProps> = ({
  localCriteria,
  setLocalCriteria,
  pushCriteria,
}) => {
  return (
    <>
      <div className="space-y-2">
        <div className="w-full flex flex-row justify-between items-center">
          <h4>Criteria</h4>
          <select
            className="select select-primary select-sm w-64"
            onChange={(e) => {
              e.preventDefault();
              setLocalCriteria({
                ...localCriteria,
                type: CriteriaSelectorEnum.AI_GRADED_FORMULA,
                scoring_rubric: PRESET_RUBRICS[e.target.value as keyof typeof PRESET_RUBRICS],
                criteria: PRESET_CRITERIA[e.target.value as keyof typeof PRESET_CRITERIA],
              });
            }}
            onBlur={pushCriteria}
            value={"preset"}
          >
            <option value={"preset"} disabled>
              Load preset
            </option>
            {Object.keys(PRESET_CRITERIA).map((preset, index) => (
              <option value={preset} key={index}>
                {preset}
              </option>
            ))}
          </select>
        </div>
        <textarea
          value={localCriteria?.criteria || ""}
          onChange={(e) => setLocalCriteria({ ...localCriteria, criteria: e.target.value })}
          onBlur={pushCriteria}
          placeholder="Enter your criteria for assessment here"
          className="textarea textarea-primary textarea-xs w-full"
          rows={2}
        />
      </div>

      <div className="space-y-2">
        <h4>Scoring rubric (optional)</h4>
        {/* Check if scoring_rubric exists, if not then just render with 1, 2, 3 */}
        {(localCriteria.scoring_rubric ? Object.keys(localCriteria.scoring_rubric) : [1, 2, 3]).map((score, index) => (
          <div className="flex flex-row items-center" key={score}>
            <div className="whitespace-nowrap w-20 ml-8 text-sm text-maindarkgray">{`Score ${score}:`}</div>
            <textarea
              value={
                localCriteria.scoring_rubric
                  ? localCriteria.scoring_rubric[Number(score) as keyof typeof localCriteria.scoring_rubric]
                  : ""
              }
              rows={2}
              onChange={(e) =>
                setLocalCriteria({
                  ...localCriteria,
                  scoring_rubric: {
                    // Make sure there are always blank defaults in the scoring rubric, but override with existing and new values
                    1: "",
                    2: "",
                    3: "",
                    ...localCriteria.scoring_rubric,
                    [score]: e.target.value,
                  },
                })
              }
              onBlur={pushCriteria}
              placeholder={`Enter a description of a score ${score} answer here:`}
              className="textarea textarea-primary textarea-xs w-full"
            />
          </div>
        ))}
      </div>

      <div className="space-y-2">
        <h4>Benchmark answer (optional)</h4>
        <textarea
          value={localCriteria?.top_answer || ""}
          onChange={(e) => setLocalCriteria({ ...localCriteria, top_answer: e.target.value })}
          onBlur={pushCriteria}
          rows={2}
          placeholder="Enter benchmark answer here"
          className="textarea textarea-primary textarea-xs w-full"
        />
      </div>
    </>
  );
};

interface LabelConfigProps {
  localCriteria: Exclude<
    CriteriaTypes,
    | NoConfigType
    | AiGradedFormulaConfigType
    | JsonConfigType
    | AiGradedCustomConfigType
    | RagContextFaithfulnessConfigType
    | RagAnswerRelevanceConfigType
  >;
  setLocalCriteria: (x: CriteriaTypes) => void;
  pushCriteria: () => void;
}

const LabelConfig: React.FC<LabelConfigProps> = ({ localCriteria, setLocalCriteria, pushCriteria }) => {
  return (
    <div className="flex flex-col justify-between items-end mt-4">
      <textarea
        value={localCriteria?.label || ""}
        rows={1}
        onChange={(e) => setLocalCriteria({ ...localCriteria, label: e.target.value })}
        onBlur={pushCriteria}
        placeholder="Enter benchmark answer for comparison here..."
        className="textarea textarea-primary textarea-xs w-full"
      />
    </div>
  );
};

interface AiGradedCustomConfigProps {
  localCriteria: AiGradedCustomConfigType;
  setLocalCriteria: (criteria: CriteriaTypes) => void;
  pushCriteria: () => void;
}

const AiGradedCustomConfig: React.FC<AiGradedCustomConfigProps> = ({
  localCriteria,
  setLocalCriteria,
  pushCriteria,
}) => {
  return (
    <div className="flex flex-col justify-between items-end mt-4">
      <textarea
        value={localCriteria?.prompt || ""}
        onChange={(e) => setLocalCriteria({ ...localCriteria, prompt: e.target.value })}
        onBlur={pushCriteria}
        className="textarea textarea-primary textarea-xs w-full h-36"
        placeholder="Enter custom prompt here..."
      />
    </div>
  );
};
