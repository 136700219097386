import React, { useState, useEffect, useRef, useMemo } from "react";
import { XMarkIcon, ArrowRightIcon, PlayCircleIcon, ArrowLongUpIcon } from "@heroicons/react/24/outline";
import { Resizable } from "re-resizable";
import { ParameterBlock } from "../PlaygroundParameterBlock/PlaygroundParameterBlock";
import { useModal } from "../modals/useModal";
import { AppStatusEnum, getLiveStatus } from "../primitives/AppBadges";
import { useUniqueTestcaseFields } from "../../hooks/useUniqueTestcaseFields";
import { useParameterMatches } from "../../utils/useParameterMatches";
import { useAutoRunFetcher } from "../../hooks/useAutoRunFetcher";
import { toast } from "react-toastify";
import { JsonViewer } from "../JsonViewer";
import { LoadMethod, LoadSpec } from "./controllers";
import { usePlaygroundAppChoices } from "./controllers";
import { getEvalOrder } from "./controllers";
import { App, Evalcase, Evalset, Evaluatorconfig, Evaluatorresult, Testcase, Testset } from "../../utils/model";
import { EvalcaseResultMap } from "./EvalcaseResultMap";
import { AppSelectorDropdown } from "./AppSelectorDropdown";
import {
  AppReturnType,
  EvalcaseReturnType,
  EvalsetCreateType,
  ParameterTypes,
  TestsetReturnType,
  VarSourceType,
} from "../../utils/interfaces";
import { ValidLoadSpec } from "./controllers";
import { ResultsSummary } from "../primitives/EvaluationBadges";
import { APP_PARAMETER_VAR_DEFAULTS } from "../../globals";
import { useSelectedVersionDetails } from "./RunnerHooks";
import { useUniqueRunnerVersions } from "./RunnerHooks";
import { useFreshAppRunners } from "./RunnerHooks";
import { VersionDetails } from "../../utils/interfaces";
import { uniqueNamesGenerator, Config, adjectives, colors, animals } from "unique-names-generator";

const customConfig: Config = {
  dictionaries: [adjectives, colors],
  separator: "-",
  length: 2,
};

interface AppPlaygroundProps {
  advancedMode: boolean;
  testset: Testset | null;
  setTestset: React.Dispatch<React.SetStateAction<TestsetReturnType | null>>;
  testcases: Testcase[] | null;
  coreHeight: number;
  deleteMe: () => void;
  anyPlaygroundWantsResults: boolean;
  enableResults: () => void;
  disableResults: () => void;
  externalAppLoadSpec: ValidLoadSpec | null;
  [key: string]: any;
}

interface EvaluationResult {
  // key is the testcase id
  evalcase?: EvalcaseReturnType;
  app_evaluations?: {
    evaluatorconfigs: Evaluatorconfig;
    evaluatorresults: Evaluatorresult;
  }[];
  testset_evaluations?: {
    evaluatorconfigs: Evaluatorconfig;
    evaluatorresults: Evaluatorresult;
  }[];
  testcase_evaluations?: {
    evaluatorconfigs: Evaluatorconfig;
    evaluatorresults: Evaluatorresult;
  }[];
}

export const AppPlayground: React.FC<AppPlaygroundProps> = ({
  advancedMode,
  testset,
  setTestset,
  testcases,
  coreHeight,
  deleteMe,
  anyPlaygroundWantsResults,
  enableResults,
  disableResults,
  externalAppLoadSpec,
  ...rest
}) => {
  const initialOutputHeight = 150;
  const maxOutputHeight = 400;
  const [outputHeight, setOutputHeight] = useState(initialOutputHeight);

  const errorModal = useModal();

  // N.B. This is a load spec, clearing it to null shouldn't clear the app playground
  const [appLoadSpec, setAppLoadSpec] = useState<LoadSpec | null>(null);

  const [userApps, setUserApps] = useState<AppReturnType[] | null>(null);

  // appDetails should be a separate state from userApps because it's not guaranteed the required app will be available with the GET all endpoint
  const [appDetails, setAppDetails] = useState<AppReturnType | null>(null);

  const uniqueRunnerVersions = useUniqueRunnerVersions(appDetails);
  const [selectedRunnerVersion, setSelectedRunnerVersion] = useState<string | null>(null);

  const runnerOptions = useFreshAppRunners(appDetails);
  const versionDetails: VersionDetails | null = useSelectedVersionDetails(runnerOptions, selectedRunnerVersion);

  const [evalset, setEvalset] = useState<Evalset | null>(null);
  const [evalcases, setEvalcases] = useState<Evalcase[] | null>(null);

  // When mode is simple playground, this is the ID to render
  const [simplePlaygroundEvalcaseId, setSimplePlaygroundEvalcaseId] = useState<string | null>(null);

  const evalcase = evalcases?.find((x: EvalcaseReturnType) => x.id === simplePlaygroundEvalcaseId);

  const [appTestsetLinks, setAppTestsetLinks] = useState<any[]>([]);

  const [frozenTestcases, _setFrozenTestcases] = useState<Testcase[] | null>(null);
  const [frozenTestset, _setFrozenTestset] = useState<Testset | null>(null);

  const [getParameterMatchArray, getParameterMatch, setParameterMatch, clearAllDatasetParameterMatches] =
    useParameterMatches(testcases, versionDetails != null ? versionDetails.parameters : null);
  const uniqueTestcaseFields = useUniqueTestcaseFields(testcases);

  useAutoRunFetcher(evalset, evalcases, setEvalset, setEvalcases);

  const orderedEvalcases: (EvalcaseReturnType | null)[] | null = useMemo(() => {
    return getEvalOrder(evalcases, testcases, frozenTestcases);
  }, [testcases, evalcases, frozenTestcases]);

  // ################################
  // ### PAGE LOAD INITIALISATION
  // ################################
  useEffect(() => {
    App.fetchAll().then((apps) => {
      setUserApps(apps);
    });
  }, []); // Fetch apps on page load

  // Convert those into app choices
  const appChoices = usePlaygroundAppChoices(userApps);

  // ################################
  // ### LOAD SPEC INITIALISATION
  // ################################
  // If the external app load spec changes, reset everything and set the app load spec
  useEffect(() => {
    if (externalAppLoadSpec == null) {
      setAppLoadSpec(null);
    } else {
      console.log("Clearing state because external app load spec has changed");

      setAppDetails(null);
      setSelectedRunnerVersion(null);
      clearAllDatasetParameterMatches();
      setEvalset(null);
      setEvalcases(null);

      setAppLoadSpec(externalAppLoadSpec as LoadSpec);
    }
  }, [externalAppLoadSpec]);

  // ################################
  // ### APP DETAILS LOADING
  // ################################
  // Load app details when appLoadSpec changes
  useEffect(() => {
    const run = async () => {
      // ready check
      if (userApps == null || appChoices == null) {
        return;
      }

      if (appLoadSpec && appLoadSpec.appId != null) {
        // load from load spec and set the appId in the load spec to null so we don't keep loading it
        // See if the app is in the user's apps that were loaded from the GET all endpoint
        let app = userApps.find((x) => x.id === appLoadSpec.appId);
        if (!app) {
          // if evalsetId isn't null, it's referring to an app that is now deleted
          app = await App.fetch(appLoadSpec.appId);
        }

        if (appDetails != null && appDetails.id !== app.id) {
          // we're about to change the app, so clear all the matching parameters and clear all the results

          console.log("Clearing state because appLoadSpec has an unloaded app");
          clearAllDatasetParameterMatches();
          setEvalset(null);
          setEvalcases(null);
        }
        setAppDetails(app);

        // and remove the appId from the load spec (remove even if the above fetch fails)
        setAppLoadSpec({ ...appLoadSpec, appId: null });
      } else {
        // don't load any apps by default currently
      }
    };
    run();
  }, [appLoadSpec, userApps, appChoices]); // This needs userApps and appChoices, otherwise it might never run if appLoadSpec is set first

  // ################################
  // ### RUNNER AUTO SELECTION
  // ################################
  useEffect(() => {
    // first check whether we're meant to be loading anything
    if (uniqueRunnerVersions == null || uniqueRunnerVersions.length === 0) {
      // not ready for loading, keep clear
      return;
    }

    // ready for loading, check if we're loading from config
    let runnerToSet = null;
    if (appLoadSpec && appLoadSpec.version != null) {
      // load from load spec and set the version in the load spec to null so we don't keep loading it
      runnerToSet = appLoadSpec.version;

      setAppLoadSpec({ ...appLoadSpec, version: null });
    } else {
      // just load the latest
      console.log("Loading latest runner");
      runnerToSet = uniqueRunnerVersions[uniqueRunnerVersions.length - 1];
    }

    if (runnerToSet !== selectedRunnerVersion) {
      // we're about to change the runner, so clear all the matching parameters and clear all the results
      console.log("Clearing state because runner is changing");
      clearAllDatasetParameterMatches();
      setEvalset(null);
      setEvalcases(null);
    }

    setSelectedRunnerVersion(runnerToSet);
  }, [uniqueRunnerVersions]);

  // ################################
  // ### EVALSET AND EVALCASE POPULATION
  // ################################
  const updateTestset = async (testset_id: string | null | undefined) => {
    if (testset_id != null) {
      await Testset.fetch(testset_id).then((testset) => setTestset(testset));
    } else {
      setTestset(null);
    }
  };

  useEffect(() => {
    const run = async () => {
      // ready check
      if (versionDetails == null) {
        return;
      }

      if (appLoadSpec && appLoadSpec.evalsetId != null && appLoadSpec.evalcaseId != null) {
        // LOADING EVALCASE
        if (advancedMode) {
          toast.error("Cannot load an evaluation case in advanced mode");
        }

        Promise.all([Evalcase.fetch(appLoadSpec.evalcaseId), Evalset.fetch(appLoadSpec.evalsetId)]).then(
          async ([_evalcase, _evalset]) => {
            await updateTestset(_evalset?.dataset_id);

            setEvalset(_evalset);
            setEvalcases([_evalcase]);

            setSimplePlaygroundEvalcaseId(_evalcase.id);
          },
        );

        // and remove the those from the load spec (remove even if the above fetch fails)
        setAppLoadSpec({ ...appLoadSpec, evalsetId: null, evalcaseId: null });
      } else if (appLoadSpec && appLoadSpec.evalsetId != null && appLoadSpec.evalcaseId == null) {
        // LOADING AN EVALSET
        if (!advancedMode) {
          toast.error("Cannot load an evaluation case in simple mode");
        }

        Evalset.fetchEvalsetAndEvalcases(appLoadSpec.evalsetId).then(async ([evalsetResponse, evalcaseResponses]) => {
          await updateTestset(evalsetResponse?.dataset_id);

          setEvalset(evalsetResponse);
          setEvalcases(evalcaseResponses);
          setSimplePlaygroundEvalcaseId(null);
        });

        // and remove the those from the load spec (remove even if the above fetch fails)
        setAppLoadSpec({ ...appLoadSpec, evalsetId: null, evalcaseId: null });
      } else {
        // obvs no evalset/evalcase is loaded by default
      }
    };

    run();
  }, [JSON.stringify(versionDetails?.parameters), appLoadSpec]);

  // ################################
  // ### PARAMETERS POPULATION
  // ################################
  // LOADING IN THE APP DETAILS AND PARAMETERS WHEN THE APP LOAD SPEC CHANGES
  // When should this run?
  // - when the parameter details change
  // - when a new evalset is loaded in
  useEffect(() => {
    const run = async () => {
      // doesn't need to wait for evalset, only needs parameters
      if (versionDetails?.parameters == null) {
        // not ready
        return;
      }

      if (appLoadSpec && appLoadSpec.loadMethod != null) {
        switch (appLoadSpec.loadMethod) {
          case LoadMethod.BLANK:
            for (const parameter of versionDetails.parameters) {
              setParameterMatch(
                parameter.name,
                VarSourceType.CONFIG,
                structuredClone(APP_PARAMETER_VAR_DEFAULTS[parameter.param_type]),
              );
            }
            setAppLoadSpec({ ...appLoadSpec, loadMethod: null });
            break;
          case LoadMethod.CUSTOM:
            for (const parameter of versionDetails.parameters) {
              setParameterMatch(
                parameter.name,
                VarSourceType.CONFIG,
                appLoadSpec.customValues?.[parameter.name] != null
                  ? appLoadSpec.customValues[parameter.name]
                  : structuredClone(APP_PARAMETER_VAR_DEFAULTS[parameter.param_type]),
              );
              setAppLoadSpec({ ...appLoadSpec, loadMethod: null });
            }
            break;
          case LoadMethod.APP_DEMO_VALUES:
            // Load in the demo values from app details
            for (const parameter of versionDetails.parameters) {
              setParameterMatch(parameter.name, VarSourceType.CONFIG, parameter.demo_value);
            }
            setAppLoadSpec({ ...appLoadSpec, loadMethod: null });
            break;

          case LoadMethod.EVALCASE:
            if (evalset == null) {
              // not ready
              console.log("Evalset is not set");
              return;
            }
            if (evalcases == null || evalcase == null) {
              // not ready
              console.log("Evalcases are not set");
              return;
            }

            let testcase_inputs: { [key: string]: string } = {};
            if (evalcase.datacase_id !== null) {
              testcase_inputs = await Testcase.fetch(evalcase.datacase_id).then((testcase) => testcase.inputs);
            }

            for (const [index, parameter] of versionDetails.parameters!.entries()) {
              if (evalset.var_matching[index]?.source === VarSourceType.CONFIG) {
                setParameterMatch(parameter.name, VarSourceType.CONFIG, evalset.var_matching[index].value);
              }

              if (evalset.var_matching[index]?.source === VarSourceType.DATASET) {
                setParameterMatch(
                  parameter.name,
                  VarSourceType.CONFIG,
                  testcase_inputs[evalset.var_matching[index].value],
                );
              }
            }

            setAppLoadSpec({ ...appLoadSpec, loadMethod: null });
            break;

          case LoadMethod.EVALSET:
            // gotta wait for the testset to be loaded in, because changing the testset resets the links
            if (evalset == null || testset == null || testset?.id !== evalset.dataset_id) {
              // not ready
              return;
            }

            for (const [index, parameter] of versionDetails.parameters!.entries()) {
              setParameterMatch(parameter.name, evalset.var_matching[index].source, evalset.var_matching[index].value);
            }

            setAppLoadSpec({ ...appLoadSpec, loadMethod: null });
            break;

          default:
            throw new Error("Invalid load method");
        }
      }
    };

    run();
  }, [JSON.stringify(versionDetails?.parameters), appLoadSpec, evalset, evalcases, evalcase]);

  // changing the testset should always clear the parameter matching
  useEffect(() => {
    clearAllDatasetParameterMatches();
  }, [testset?.id]);

  // ################################
  // ### VARIOUS SAFE EVALSET EFFECTS
  // ################################
  // effect to fetch frozen testset and testcases where possible
  useEffect(() => {
    if (evalset?.dataset_id) {
      Testset.fetchTestsetAndTestcases(evalset.dataset_id).then(([testset, testcases]) => {
        _setFrozenTestset(testset);
        _setFrozenTestcases(testcases);
      });
    } else {
      _setFrozenTestset(null);
      _setFrozenTestcases(null);
    }
  }, [evalset?.dataset_id]);

  // Effect to clear the results if the testset isn't compatible with the existing results
  // Compatible means that there is no overlap between the testcases being passed in and the testcases in the evalset
  // I.e. the length of the returned list from getTestEvalMatches should be less than the sum of the lengths of the testcases and evalcases
  const testsetIsCompatible = (
    evalset: Evalset | null,
    evalcases: Evalcase[] | null,
    orderedEvalcases: (EvalcaseReturnType | null)[] | null,
    testcases: Testcase[] | null,
  ) => {
    if (evalset == null || evalcases == null || orderedEvalcases == null || testcases == null) {
      return true;
    }

    if (evalset?.dataset_id == null) {
      return true;
    }

    return orderedEvalcases.length === testcases.length + evalcases.length;
  };

  // useEffect(() => {
  //   if (!testsetIsCompatible(evalset, evalcases, orderedEvalcases, testcases)) {
  //     console.log("Clearing the state because the testset is not compatible");
  //     setEvalset(null);
  //     setEvalcases(null);
  //   }
  // }, [orderedEvalcases, testcases, evalcases]);

  // effect to enable or disable results in the parent playground
  useEffect(() => {
    if (evalset?.results_summary) {
      enableResults();
    } else {
      disableResults();
    }
  }, [evalset?.results_summary]);

  let [runButtonLive = false, notLiveReason = ""] = [];
  if (!versionDetails) {
    [runButtonLive, notLiveReason] = [false, "Please select an app"];
  } else if (getLiveStatus(versionDetails?.last_active, versionDetails.is_live) !== AppStatusEnum.LIVE) {
    [runButtonLive, notLiveReason] = [false, "The runner for this app is not active"];
  } else if (advancedMode && evalset != null && evalset.results_summary == null) {
    [runButtonLive, notLiveReason] = [false, "A run is already in progress"];
  } else if (advancedMode && !testcases) {
    [runButtonLive, notLiveReason] = [false, "Please select a testset"];
  } else {
    [runButtonLive, notLiveReason] = [true, ""];
  }

  // If the appChoices are not yet populated, show a loading screen
  if (appChoices == null) {
    return (
      <div className="h-full w-full border border-solid shadow flex flex-col justify-center items-center bg-white overflow-hidden">
        <div className="loading loading-spinner text-primary"></div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col" {...rest}>
      <div
        className="duration-700 w-full border border-solid border-mainbordergray shadow flex flex-col justify-between bg-white overflow-hidden rounded-md"
        style={{
          transitionProperty: "height",
          height: coreHeight,
        }}
      >
        {/* APP SELECTOR AND CONTROLS */}
        <div className="flex flex-row justify-between items-center p-4 space-x-4">
          <div className="flex flex-row space-x-4 grow">
            <AppSelectorDropdown
              userApps={userApps}
              selectedApp={appDetails}
              inputAppChoices={appChoices}
              setAppLoadSpec={setAppLoadSpec}
            />
            <select
              className="select flex-auto max-w-32 select-bordered select-sm"
              value={selectedRunnerVersion || undefined}
              onChange={(e) => setSelectedRunnerVersion(e.target.value)}
            >
              <option disabled selected>
                Version
              </option>
              {uniqueRunnerVersions?.map((version: string) => (
                <option key={version} value={version}>
                  {version}
                </option>
              ))}
            </select>
          </div>
          {/* Playground controls collection */}
          <div className="flex flex-row">
            <XMarkIcon className="simple-icon-button " onClick={deleteMe} />
          </div>
        </div>

        {/* PARAMETERS BODY */}
        <div className="grow overflow-y-auto no-scrollbar flex flex-col justify-between shadow-md bg-mainlightgray overflow-x-hidden">
          {appDetails && versionDetails?.parameters ? (
            <ParameterBlock
              advancedMode={advancedMode}
              name={appDetails.name}
              parameters={versionDetails?.parameters}
              docstring={versionDetails?.docstring != null ? versionDetails.docstring : null}
              last_active={versionDetails?.last_active}
              is_live={versionDetails?.is_live}
              getParameterMatch={getParameterMatch}
              setParameterMatch={setParameterMatch}
              uniqueTestcaseFields={uniqueTestcaseFields}
              appTestsetLinks={appTestsetLinks}
              setAppTestsetLinks={setAppTestsetLinks}
            />
          ) : (
            <div className="w-full h-full flex flex-row justify-center items-center space-x-8 pr-16 text-maindarkgray">
              <ArrowLongUpIcon className="h-10 w-10 " />
              <div>Select an app</div>
            </div>
          )}
        </div>

        {/* RUN AND OUTPUT */}
        {advancedMode ? null : (
          <Resizable
            className={`flex flex-col justify-between`}
            onResizeStop={(e, direction, ref, d) => {
              setOutputHeight((current) => current + d.height);
            }}
            size={{ height: outputHeight, width: "100%" }}
            maxHeight={maxOutputHeight}
            minHeight={initialOutputHeight}
          >
            {/* Rendering run output */}
            <div className="grow bg-mainlightgray border-solid border border-mainbordergray rounded-lg m-4 p-2 flex justify-end flex-row items-end space-x-2 overflow-hidden">
              {evalcases &&
                evalcases.find((x: EvalcaseReturnType) => x.id === simplePlaygroundEvalcaseId) &&
                evalcases.find((x: EvalcaseReturnType) => x.id === simplePlaygroundEvalcaseId)?.output && (
                  <div className="h-full grow overflow-y-scroll no-scrollbar whitespace-pre-wrap">
                    <JsonViewer
                      data={evalcases.find((x: EvalcaseReturnType) => x.id === simplePlaygroundEvalcaseId)?.output}
                    />
                  </div>
                  // <div ref={outputContainerRef} style={{ overflowY: "auto", maxHeight: "200px" }}>

                  // </div>
                )}

              {/* Rendering run error */}
              {evalcases &&
                evalcases.find((x: EvalcaseReturnType) => x.id === simplePlaygroundEvalcaseId) &&
                evalcases.find((x: EvalcaseReturnType) => x.id === simplePlaygroundEvalcaseId)?.run_error && (
                  <div className="h-full grow overflow-y-scroll no-scrollbar whitespace-pre-wrap text-red-700">
                    {String(evalcases.find((x: EvalcaseReturnType) => x.id === simplePlaygroundEvalcaseId)?.run_error)}
                  </div>
                )}
              <button
                className={`btn btn-primary btn-sm`}
                onClick={() => {
                  if (!runButtonLive) {
                    toast.error(notLiveReason);
                    return;
                  }

                  // if any of the disabled criteria above are met, don't run
                  if (!appDetails || !appDetails.id) {
                    toast.error("Please select an app");
                    return;
                  }

                  if (!versionDetails || !versionDetails.parameters) {
                    toast.error("Please a version");
                    return;
                  }

                  Evalset.create({
                    app_id: appDetails.id,
                    name: "Playground Run",
                    version: selectedRunnerVersion,
                    var_matching: getParameterMatchArray(versionDetails.parameters),
                  } as EvalsetCreateType)
                    .then((response) => {
                      setEvalset(response);
                      setEvalcases(null);
                      setSimplePlaygroundEvalcaseId(response.evalcase_ids[0]);
                    })
                    .catch((error) => {
                      // If the status code is 429, write the error to console
                      if (error.response.status === 429) {
                        if (error.response.data.message === "EXCEEDED_ANONYMOUS_USE_ERROR_CODE") {
                          errorModal.openModal("EXCEEDED_ANONYMOUS_USE_ERROR_CODE", null);
                        } else if (error.response.data.message === "PRO_ERROR_CODE") {
                          errorModal.openModal("PRO_ERROR_CODE", null);
                        }
                      }
                    });
                }}
              >
                <div className={`${evalset !== null ? "hidden" : "block"}`}>Submit</div>
                {evalset !== null && evalset.results_summary == null ? (
                  <span className="w-5 h-5 loading loading-spinner" />
                ) : (
                  <ArrowRightIcon className="w-5 h-5" />
                )}
              </button>
            </div>
            {/* </div> */}
          </Resizable>
        )}
      </div>

      <div
        className="transition-all duration-700 h-10 mt-10 px-2 w-full flex flex-row items-center justify-between text-sm font-semibold bg-mainbordergray rounded-md shadow"
        style={{
          opacity: advancedMode ? 1 : 0, // Render only if advanced mode is on
        }}
      >
        <h3 className="w-full h-full flex flex-row items-center text-white uppercase">Results</h3>
        <button
          className="btn btn-sm btn-primary -mr-1"
          onClick={() => {
            if (!runButtonLive) {
              toast.error(notLiveReason);
              return;
            }

            if (!versionDetails || !versionDetails.parameters || !testset || !testcases || !uniqueTestcaseFields) {
              return;
            }

            const allLinked =
              appDetails &&
              versionDetails.parameters
                .map((parameter: ParameterTypes) => {
                  const parameterMatch = getParameterMatch(parameter.name);
                  return parameterMatch && parameterMatch.source === "DATASET";
                })
                .filter((x: boolean | null) => x === true).length === uniqueTestcaseFields.length;
            if (!allLinked) {
              toast.error("Use the red link icons to link the app inputs to the testcase fields.");
            } else {
              // number concatenated to name
              // create a
              const shortName: string = uniqueNamesGenerator(customConfig);
              Evalset.create({
                app_id: appDetails.id,
                name: "Evaluation (" + shortName + ")",
                version: selectedRunnerVersion,
                var_matching: getParameterMatchArray(versionDetails.parameters),
                dataset_id: testset.id,
              } as EvalsetCreateType)
                .then((response) => {
                  setEvalset(response);
                  setEvalcases(null);
                })
                .catch((error) => {
                  // If the status code is 429, write the error to console
                  if (error.response.status === 429) {
                    if (error.response.data.message === "EXCEEDED_ANONYMOUS_USE_ERROR_CODE") {
                      errorModal.openModal("EXCEEDED_ANONYMOUS_USE_ERROR_CODE", null);
                    } else if (error.response.data.message === "PRO_ERROR_CODE") {
                      errorModal.openModal("PRO_ERROR_CODE", null);
                    }
                  }
                });
            }
          }}
        >
          Run All
          {evalset != null && evalset.results_summary == null ? (
            <div className="w-4 h-4 loading loading-spinner text-white" />
          ) : (
            <PlayCircleIcon className="w-5 h-5 stroke-2" />
          )}
        </button>
      </div>

      {anyPlaygroundWantsResults ? <ResultsSummary run={evalset} evalcases={evalcases} /> : null}

      <div className="flex flex-col mt-4 space-y-4">
        <EvalcaseResultMap orderedEvalcases={orderedEvalcases} testcases={frozenTestcases} />
      </div>
    </div>
  );
};

export default AppPlayground;
