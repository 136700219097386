import React, { FC } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

interface DemoCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  onClick?: () => void;
  className?: string;
}

export const DemoCard: FC<DemoCardProps> = ({ title, onClick, children, className, ...rest }) => {
  return (
    <div
      className={`shrink flex min-w-[200px] max-w-[400px] card card-bordered bg-white shadow ${className}`}
      key={title}
      {...rest}
    >
      <div className="card-body p-6">
        <div className="flex justify-between items-center mb-2 space-x-4">
          <h3 className="">{title}</h3>
          {onClick && <ArrowTopRightOnSquareIcon className="simple-icon-button" onClick={onClick} />}
        </div>
        {children}
      </div>
    </div>
  );
};
