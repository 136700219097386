export const demoCalendarData = [
  {
    value: 368,
    day: "2024-01-08",
  },
  {
    value: 196,
    day: "2024-06-26",
  },
  {
    value: 60,
    day: "2016-04-07",
  },
  {
    value: 49,
    day: "2017-03-31",
  },
  {
    value: 38,
    day: "2016-08-05",
  },
  {
    value: 365,
    day: "2017-08-13",
  },
  {
    value: 146,
    day: "2018-03-20",
  },
  {
    value: 109,
    day: "2018-05-17",
  },
  {
    value: 117,
    day: "2016-03-16",
  },
  {
    value: 10,
    day: "2015-05-13",
  },
  {
    value: 356,
    day: "2017-07-29",
  },
  {
    value: 360,
    day: "2017-10-03",
  },
  {
    value: 283,
    day: "2017-01-11",
  },
  {
    value: 287,
    day: "2015-09-04",
  },
  {
    value: 41,
    day: "2016-07-12",
  },
];
