import { TestcaseReturnType } from "../utils/interfaces";
import { Testcase, Testset } from "../utils/model";
import { useMemo } from "react";

export const useUniqueTestcaseFields = (testcases: TestcaseReturnType[] | null) => {
  const uniqueTestCaseFields = useMemo(() => {
    if (testcases == null) return null;
    return Testset.getUniqueTestcaseFields(testcases);
  }, [testcases]);
  return uniqueTestCaseFields;
};
