import { useEffect, useMemo, useState } from "react";
import { App, Runner } from "../../utils/model";
import { VersionDetails } from "../../utils/interfaces";
import { AppReturnType } from "../../utils/interfaces";
import { useInterval } from "../../hooks/useInterval";

export const getVersionDetails = (
  runnerOptions: Runner[] | null,
  selectedRunnerVersion: string | null,
): VersionDetails | null => {
  if (runnerOptions == null || selectedRunnerVersion == null) {
    return null;
  }

  const is_live = runnerOptions.some((runner) => runner.version === selectedRunnerVersion && runner.is_live);

  const last_active = runnerOptions
    .filter((runner) => runner.version === selectedRunnerVersion)
    .map((runner) => new Date(runner.last_active).getTime())
    .reduce((a, b) => Math.max(a, b), 0);
  const last_active_date = new Date(last_active).toString();

  const first_created = runnerOptions
    .filter((runner) => runner.version === selectedRunnerVersion)
    .map((runner) => new Date(runner.created).getTime())
    .reduce((a, b) => Math.min(a, b), Infinity);

  const first_created_date = new Date(first_created).toString();

  const runner = runnerOptions.find((runner) => runner.version === selectedRunnerVersion);

  if (runner == null) {
    return null;
  }
  return {
    first_created: first_created_date,
    last_active: last_active_date,
    is_live: is_live,
    version: runner.version,
    parameters: runner.parameters,
    docstring: runner.docstring,
  } as VersionDetails;
};

export const useSelectedVersionDetails = (runnerOptions: Runner[] | null, selectedRunnerVersion: string | null) => {
  const versionDetails = useMemo(
    () => getVersionDetails(runnerOptions, selectedRunnerVersion),
    [runnerOptions, selectedRunnerVersion],
  );

  return versionDetails;
};

export const useAllVersionDetails = (runnerOptions: Runner[] | null): (VersionDetails | null)[] => {
  if (runnerOptions == null) {
    return [];
  }

  return runnerOptions.map((runner) => getVersionDetails(runnerOptions, runner.version));
};

export const useUniqueRunnerVersions = (app: AppReturnType | null) => {
  const fetchAppVersions = async (app: App | null): Promise<string[] | null> => {
    if (app == null) {
      return null;
    }

    return Runner.fetchAll(app.api_key).then((runners) => {
      const appVersions = runners.map((runner) => runner.version);
      // deduplicate versions
      const uniqueAppVersions = appVersions.filter((value, index, self) => self.indexOf(value) === index);
      // versions are in the format major.minor.patch
      // sort versions
      const sortedAppVersions = uniqueAppVersions.sort((a, b) => {
        const aParts = a.split(".").map((x) => parseInt(x));
        const bParts = b.split(".").map((x) => parseInt(x));
        for (let i = 0; i < aParts.length; i++) {
          if (aParts[i] < bParts[i]) {
            return -1;
          } else if (aParts[i] > bParts[i]) {
            return 1;
          }
        }
        return 0;
      });

      return sortedAppVersions;
    });
  };

  const [appVersions, setAppVersions] = useState<string[] | null>(null);

  useEffect(() => {
    fetchAppVersions(app).then((x) => setAppVersions(x));
  }, [app]);

  return appVersions;
};

export const useFreshAppRunners = (app: AppReturnType | null) => {
  const [runners, setRunners] = useState<Runner[] | null>(null);

  const fetchRunners = async () => {
    if (app != null) {
      const fetchedRunners = await Runner.fetchAll(app.api_key);
      setRunners(fetchedRunners);
    }
  };

  useEffect(() => {
    fetchRunners();
  }, [app]);

  useInterval(() => {
    fetchRunners();
  });

  return runners;
};
