import React from "react";
import { EvalcaseResultDisplay } from "./EvalcaseResultDisplay";
import { EvalcaseReturnType, TestcaseReturnType } from "../../utils/interfaces";

type EvalcaseResultMapProps = {
  orderedEvalcases: (EvalcaseReturnType | null)[] | null;
  testcases: TestcaseReturnType[] | null;
};

export const EvalcaseResultMap: React.FC<EvalcaseResultMapProps> = ({ orderedEvalcases, testcases }) => {
  if (testcases == null || orderedEvalcases == null || !Array.isArray(orderedEvalcases) || !Array.isArray(testcases)) {
    return null;
  }

  // It's important to note that ordererdEvalcases can contain nulls
  // This corresponds to the case when there is no evalcase for the specific testcase
  // We should render an empty div in this case

  return (
    <>
      {orderedEvalcases.map((evalcase: EvalcaseReturnType | null, index) => {
        let testcase = null;
        if (evalcase?.datacase_id) {
          testcase = testcases.find((x) => x.id === evalcase.datacase_id) || null;
        }

        return <EvalcaseResultDisplay evalcase={evalcase} testcase={testcase} key={index} />;
      })}
    </>
  );
};
