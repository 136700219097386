import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { DatasetLinkButton } from "./DatasetLinkButton";
import { ParameterTitle } from "./PlaygroundParameterBlock";
import { ParameterTypes } from "../../utils/interfaces";
import { GetParameterMatchFn, SetParameterMatchFn } from "../../utils/useParameterMatches";

interface TitleBlockProps {
  parameter: ParameterTypes;
  advancedMode: boolean;
  uniqueTestcaseFields: string[] | null;
  getParameterMatch: GetParameterMatchFn;
  setParameterMatch: SetParameterMatchFn;
  allLinked: boolean;
}

export const TitleBlock: React.FC<TitleBlockProps> = ({
  parameter,
  advancedMode,
  uniqueTestcaseFields,
  getParameterMatch,
  setParameterMatch,
  allLinked,
}) => {
  return (
    <div className="flex flex-row items-center space-x-2 no-wrap">
      {advancedMode && (
        <DatasetLinkButton
          parameter={parameter}
          uniqueTestcaseFields={uniqueTestcaseFields}
          getParameterMatch={getParameterMatch}
          setParameterMatch={setParameterMatch}
          allLinked={allLinked}
        />
      )}
      <ParameterTitle value={parameter.name} />
      {parameter.description !== null && (
        <div className="tooltip tooltip-bottom" data-tip={parameter.description}>
          <InformationCircleIcon className="simple-icon-button" />
        </div>
      )}
    </div>
  );
};
