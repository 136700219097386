import { useEffect, useState, useRef } from "react";
import { Evalcase, Evalset } from "../utils/model";

const getListCompletedEvalcases = (evalset: Evalset, evalcases: Evalcase[] | null): string[] => {
  return evalset.evalcase_ids.filter((evalcaseId: string) => {
    if (evalcases == null || evalcases.length === 0) {
      return true;
    }
    // Find corresponding evalcase result
    const evalcaseResult = evalcases.find((evalcaseResult) => evalcaseResult.id === evalcaseId);
    if (evalcaseResult == null) {
      throw new Error(`Evalcase result with id ${evalcaseId} not found`);
    }
    // Check if the evalcase is complete
    return !Evalcase.isComplete(evalcaseResult);
  });
};

const refreshEvalData = async (
  evalset: Evalset,
  evalcases: Evalcase[] | null,
): Promise<{ evalset: Evalset | null; evalcase: Evalcase | null }> => {
  if (evalset === null) {
    return { evalset: null, evalcase: null };
  }
  const uncompletedEvalcaseIds = getListCompletedEvalcases(evalset, evalcases);
  const evalsetIsIncomplete = !Evalset.evalsetIsComplete(evalset);
  if (uncompletedEvalcaseIds.length === 0 && evalsetIsIncomplete) {
    // All evalcases are complete but the evalset hasn't finished
    const evalsetResponse = await Evalset.fetch(evalset.id);
    if (evalsetResponse === evalset) {
      return { evalset: null, evalcase: null };
    }
    return { evalset: evalsetResponse, evalcase: null };
  } else if (uncompletedEvalcaseIds.length > 0) {
    // go and get those evalcases!
    const evalcaseToPoll = Math.floor(Math.random() * uncompletedEvalcaseIds.length);
    const evalcaseResponse = await Evalcase.fetch(uncompletedEvalcaseIds[evalcaseToPoll]);
    // If the evalcase is the same as the one we already have, return null
    if (evalcaseResponse === evalcases?.find((evalcase) => evalcase.id === evalcaseResponse.id)) {
      return { evalset: null, evalcase: null };
    }

    return { evalset: null, evalcase: evalcaseResponse };
  }
  return { evalset: null, evalcase: null };
};

export const useAutoRunFetcher = (
  evalset: Evalset | null,
  evalcases: Evalcase[] | null,
  setEvalset: React.Dispatch<React.SetStateAction<Evalset | null>>,
  setEvalcases: React.Dispatch<React.SetStateAction<Evalcase[] | null>>,
) => {
  const lastRequestTimeRef = useRef(0);

  useEffect(() => {
    if (evalset == null) {
      return;
    }
    let isMounted = true;
    let timeoutId: NodeJS.Timeout;

    async function recursiveGetData() {
      if (evalset == null || evalcases == null) {
        return;
      }
      if (isMounted) {
        const currentTime = Date.now();
        const timeSinceLastRequest = currentTime - lastRequestTimeRef.current;
        if (timeSinceLastRequest >= 1000) {
          const { evalset: updatedEvalset, evalcase: updatedEvalcase } = await refreshEvalData(
            evalset as Evalset,
            evalcases as Evalcase[],
          );
          if (updatedEvalset != null) {
            setEvalset(updatedEvalset);
          }
          if (updatedEvalcase) {
            setEvalcases((currentEvalcaseResults) => {
              // If the evalcase result is already in the list, update it, else add it
              // Replace the evalcase result with the new one
              let newEvalcaseResults;
              if (Array.isArray(currentEvalcaseResults)) {
                newEvalcaseResults = currentEvalcaseResults.filter(
                  (evalcaseResult) => evalcaseResult.id !== updatedEvalcase.id,
                );
                newEvalcaseResults.push(updatedEvalcase);
              } else {
                newEvalcaseResults = [updatedEvalcase];
              }
              // Make sure it's always ordered by evalset.evalcase_ids
              newEvalcaseResults.sort((a, b) => {
                return evalset.evalcase_ids.indexOf(a.id) - evalset.evalcase_ids.indexOf(b.id);
              });
              return newEvalcaseResults;
            });
          }
          lastRequestTimeRef.current = currentTime;
        }
        timeoutId = setTimeout(() => {
          if (isMounted) {
            recursiveGetData();
          }
        }, 1000 - timeSinceLastRequest);
      }
    }

    recursiveGetData();

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [evalset, evalcases]);

  useEffect(() => {
    // When the evalset changes, you should always get the evalcases and request the testset updates in the Playground
    if (evalset == null) {
      return;
    }
    Evalset.fetchEvalsetAndEvalcases(evalset.id).then(([evalsetResponse, evalcaseResponses]) => {
      setEvalcases(evalcaseResponses);
    });
  }, [evalset?.id]);
};
