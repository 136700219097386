import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { EditModal } from "./EditValueModal";
import TextareaAutosize from "react-textarea-autosize";

interface InputBoxWithModalProps {
  value: any;
  setValue: (newValue: any) => void;
  className?: string;
}

export const InputBoxWithModal: React.FC<InputBoxWithModalProps> = ({ value, setValue, className }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editingValue, setEditingValue] = useState<string | null>(null);

  const prettiedValue = (value: any): string => {
    if (value === null) {
      return "";
    } else if (typeof value === "string") {
      return value;
    } else if (typeof value === "object") {
      return JSON.stringify(value);
    } else if (typeof value === "number") {
      return value.toString();
    } else {
      return "";
    }
  };

  useEffect(() => {
    setEditingValue(prettiedValue(value));
  }, [value]);

  const parseValue = (newValue: string): any => {
    // try to parse the value as JSON
    try {
      return JSON.parse(newValue);
    } catch (e) {
      return newValue;
    }
  };

  const handleSave = async () => {
    setValue(parseValue(editingValue || ""));
    setModalOpen(false);
  };

  return (
    <div
      className={`h-full group hover:bg-mainlightgray w-full flex flex-row justify-between items-center ${className}`}
    >
      <EditModal
        open={modalOpen}
        setOpen={setModalOpen}
        launchValue={editingValue || ""}
        onSave={(x) => {
          handleSave();
        }}
        onClose={() => setModalOpen(false)}
      />
      <TextareaAutosize
        className="textarea group-hover:bg-mainlightgray bg-white px-2 focus:outline-0 required w-full resize-none no-scrollbar"
        value={editingValue || ""}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setEditingValue(e.target.value);
        }}
        onBlur={handleSave}
        minRows={1}
        maxRows={5}
      />

      <PencilSquareIcon
        className="invisible group-hover:visible tiny-icon-button mr-2"
        onClick={(e) => {
          e.stopPropagation();
          setModalOpen(true);
        }}
      />
    </div>
  );
};
