import React, { useState, useEffect } from "react";
import Modal from "./Modal";

interface EditModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  launchValue: string;
  onSave: (value: string) => void;
  onClose: () => void;
}

export const EditModal: React.FC<EditModalProps> = ({ open, setOpen, launchValue, onSave, onClose }) => {
  const [value, setValue] = useState(launchValue);

  // If the modal is opening, set the value to the launch value
  useEffect(() => {
    if (open) {
      setValue(launchValue);
    }
  }, [open, launchValue]);

  // If the launch value changes, update the value
  useEffect(() => {
    setValue(launchValue);
  }, [launchValue]);

  return (
    <Modal isOpen={open} closeModal={() => setOpen(false)}>
      <div className="modal-box">
        <h3 className="font-bold text-base">Enter New Value</h3>
        <div className="py-4">
          <textarea
            value={value}
            style={{ height: "224px" }}
            onChange={(e) => setValue(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            className="textarea textarea-primary w-full no-scrollbar"
          />
        </div>
        <div className="modal-action">
          <button
            className="btn"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              onSave(value);
              onClose();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};
