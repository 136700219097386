export const parameterNameProcessor = (value: string | null): string | null => {
  if (value === null || value === undefined) {
    return null;
  }
  const processed = value.replace(/[*_-]/g, " ").replace("llm", "LLM");
  return processed;
};

export const isValidChat = (chat: any): boolean => {
  // A chat should be a list of objects, each object should have a "role" and a "content" field that are both strings
  if (chat === null || chat === undefined) {
    return false;
  }

  if (!Array.isArray(chat)) {
    return false;
  }

  for (const message of chat) {
    if (typeof message !== "object") {
      return false;
    }
    if (!isValidString(message.role) || !isValidString(message.content)) {
      return false;
    }
  }

  return true;
};

export const isValidString = (testStr: any): boolean => {
  if (testStr === null || testStr === undefined) {
    return false;
  }
  if (typeof testStr !== "string") {
    return false;
  }
  return true;
};

export function isValidNumber(variable: any): boolean {
  // First try to convert the variable to a number
  const number = Number(variable);

  // Check if the result is a number and it's not NaN (to exclude cases like Number('abc'))
  // This will exclude false positives like '', null, or false, which convert to 0 without
  // technically losing 'information' even though they're not numbers.
  if (!isNaN(number) && variable !== "" && variable !== null && variable !== false) {
    // Check if the variable is an object (if yes, casting can't happen without loss of data)
    if (typeof variable === "object") {
      return false;
    }

    // Convert the number back to its string representation and compare with the original input
    // Trim input if it's a string to handle cases with whitespace like '   123   '.
    let variableAsString = typeof variable === "string" ? variable.trim() : String(variable);

    // In cases where the number is in exponential format, compare it with toExponential string
    if (variableAsString.indexOf("e") !== -1 || variableAsString.indexOf("E") !== -1) {
      return number.toExponential() === variableAsString;
    }

    // Use regular comparison for other cases
    return variableAsString === String(number);
  }
  return false;
}

export function generateRandomAPIKey(): string {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let apiKey = "cp-";

  for (let i = 0; i < 32; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    apiKey += characters[randomIndex];
  }

  return apiKey;
}
