// Import necessary libraries and components
import React, { useState, useRef, useMemo, useEffect, useCallback } from "react";
import urlJoin from "url-join";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Removed Papa from 'papaparse';
import { AgGridReact } from "ag-grid-react";
import { GridApi, GridReadyEvent, CellValueChangedEvent, ColDef } from "ag-grid-community";

import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

// Import xlsx library for XLSX support
import * as XLSX from "xlsx";
import { DOCS_LINK } from "../../globals";

// Constants
export const REQUIRED_COLUMNS = ["Input", "Response"];
export const DEFAULT_COLUMNS = ["Input", "Response", "Context", "Reference", "Version", "Notes"];
export const DEFAULT_COLUMNS_LOWER = DEFAULT_COLUMNS.map((col) => col.toLowerCase());

// Interfaces
export interface RowData {
  id: number;
  [key: string]: any; // Allow dynamic columns including required ones
}

export interface DataGridProps {
  gridData: RowData[];
  columnDefs: ColDef[];
  onCellValueChanged: (params: CellValueChangedEvent) => void;
  gridRef: React.RefObject<AgGridReact<RowData>>;
  onGridReady?: (params: GridReadyEvent) => void;
  rowWrapEnabled: boolean;
}

export interface DataToolbarProps {
  onDeleteSelectedRows: () => void;
  onAddRow: () => void;
  columns: ColDef[];
  onDeleteColumn: (field: string) => void;
  onClearColumn: (field: string) => void;
  onEvaluateColumn: (field: string) => void;
  onAddColumn: (columnName: string) => void;
  onExportJSONL: () => void;
  onImportJSONL: (file: File) => void;
  onExportXLSX: () => void;
  onImportXLSX: (file: File) => void;
  onEvaluateAll: () => void;
  onLoadSampleData: () => void;
  rowWrapEnabled: boolean;
  onToggleRowWrap: () => void;
  isEvaluating: boolean;
  onClearAll: () => void;
}

export interface ExploreToolbarProps {
  versions: string[];
  evaluators: string[];
  selectedVersion: string | null;
  setSelectedVersion: (version: string | null) => void;
  selectedEvaluators: string[];
  setSelectedEvaluators: (evaluators: string[]) => void;
}

// DataGrid Component
export const DataGrid: React.FC<DataGridProps> = ({
  gridData,
  columnDefs,
  onCellValueChanged,
  gridRef,
  onGridReady,
  rowWrapEnabled,
}) => {
  // Function to get color based on value relative to mean and standard deviation
  const getColorForValue = (value: number, mean: number, stdDev: number): string => {
    if (isNaN(value) || isNaN(mean) || isNaN(stdDev) || stdDev === 0) {
      return ""; // No color if data is invalid
    }

    const deviation = (value - mean) / stdDev;

    // Mean is colored orange
    if (Math.abs(deviation) <= 0.5) {
      return "rgba(255,165,0,0.5)"; // Orange
    }
    // 2 standard deviations above mean is green
    else if (deviation >= 2) {
      return "rgba(0,128,0,0.5)"; // Green
    }
    // 2 standard deviations below mean is red
    else if (deviation <= -2) {
      return "rgba(255,0,0,0.5)"; // Red
    }
    // Intermediate values are interpolated between colors
    else if (deviation > 0.5) {
      // Between mean and +2 stdDev
      const ratio = (deviation - 0.5) / (2 - 0.5);
      // Interpolate between orange and green
      const r = Math.round(255 - ratio * 255);
      const g = Math.round(165 + ratio * (128 - 165));
      const b = 0;
      return `rgba(${r},${g},${b},0.5)`;
    } else if (deviation < -0.5) {
      // Between mean and -2 stdDev
      const ratio = (-deviation - 0.5) / (2 - 0.5);
      // Interpolate between orange and red
      const r = 255;
      const g = Math.round(165 - ratio * 165);
      const b = 0;
      return `rgba(${r},${g},${b},0.5)`;
    } else {
      // Close to mean but not within ±0.5 stdDev
      return "rgba(255,165,0,0.5)"; // Orange
    }
  };

  // Compute column statistics
  const columnStats = useMemo(() => {
    const stats: { [key: string]: { mean: number; stdDev: number } } = {};

    columnDefs.forEach((colDef) => {
      const field = colDef.field;
      if (!field) return;

      const values = gridData.map((row) => parseFloat(row[field])).filter((value) => !isNaN(value));

      if (values.length > 0) {
        const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
        const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
        const stdDev = Math.sqrt(variance);
        stats[field] = { mean, stdDev };
      } else {
        stats[field] = { mean: NaN, stdDev: NaN };
      }
    });
    return stats;
  }, [gridData, columnDefs]);

  // Dynamic cell style based on value relative to column statistics
  const dynamicCellStyle = useCallback(
    (params: any) => {
      const field = params.colDef.field;
      const value = params.value;
      const numericValue = parseFloat(value);

      if (!field || DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase())) {
        return { whiteSpace: rowWrapEnabled ? "pre-wrap" : "nowrap" };
      } else if (!isNaN(numericValue) && columnStats[field]) {
        const { mean, stdDev } = columnStats[field];
        const color = getColorForValue(numericValue, mean, stdDev);
        return {
          backgroundColor: color,
          whiteSpace: rowWrapEnabled ? "pre-wrap" : "nowrap",
        };
      } else if (value && isNaN(numericValue)) {
        return {
          color: 'red',
          whiteSpace: rowWrapEnabled ? "pre-wrap" : "nowrap",
        };
      }
      return { whiteSpace: rowWrapEnabled ? "pre-wrap" : "nowrap" };
    },
    [rowWrapEnabled, columnStats],
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 100,
      resizable: true,
      editable: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      wrapText: rowWrapEnabled,
      autoHeight: rowWrapEnabled,
      // cellStyle: dynamicCellStyle,
    }),
    [rowWrapEnabled, dynamicCellStyle],
  );

  return (
    <div className="ag-theme-alpine w-full h-full flex-1 overflow-hidden">
      <AgGridReact
        ref={gridRef}
        rowData={gridData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowSelection="multiple"
        onCellValueChanged={onCellValueChanged}
        stopEditingWhenCellsLoseFocus={true}
        onGridReady={onGridReady}
        tooltipShowDelay={0}
        tooltipHideDelay={1000}
        getRowId={(params) => params.data.id}
      />
    </div>
  );
};

// DataToolbar Component
export const DataToolbar: React.FC<DataToolbarProps> = ({
  onDeleteSelectedRows,
  onAddRow,
  columns,
  onDeleteColumn,
  onClearColumn,
  onEvaluateColumn,
  onAddColumn,
  onExportJSONL,
  onImportJSONL,
  onExportXLSX,
  onImportXLSX,
  onEvaluateAll,
  onLoadSampleData,
  rowWrapEnabled,
  onToggleRowWrap,
  isEvaluating,
  onClearAll,
}) => {
  const [isAddColumnModalOpen, setIsAddColumnModalOpen] = useState(false);
  const [newColumnName, setNewColumnName] = useState("");
  const [selectedEvaluatorType, setSelectedEvaluatorType] = useState<string>("");
  const [selectedContextVerificationOption, setSelectedContextVerificationOption] = useState<string>("");
  const [selectedRewardType, setSelectedRewardType] = useState<string>("");
  const [selectedBinaryType, setSelectedBinaryType] = useState<string>("");

  return (
    <div className="space-y-6 h-full flex flex-col overflow-y-scroll">
      <h3 className="text-lg font-semibold">Data Tools</h3>
      <div className="grid grid-cols-2 gap-2">
        <button className="btn btn-sm btn-primary" onClick={onAddRow} disabled={isEvaluating}>
          Add Row
        </button>
        <button className="btn btn-sm btn-error" onClick={onDeleteSelectedRows} disabled={isEvaluating}>
          Delete Selected
        </button>
        <button className="btn btn-sm btn-warning col-span-2" onClick={onClearAll} disabled={isEvaluating}>
          Clear All
        </button>
      </div>
      {/* Row Wrap Toggle */}
      <div className="flex items-center space-x-2 mt-4">
        <input
          type="checkbox"
          checked={rowWrapEnabled}
          onChange={onToggleRowWrap}
          className="toggle toggle-primary"
          disabled={isEvaluating}
        />
        <label className="text-sm font-medium select-none">Enable Row Wrap</label>
      </div>

      <h3 className="text-lg font-semibold">Import / Export</h3>
      <div className="grid grid-cols-2 gap-2">
        {/* Import JSONL */}
        <div>
          <label htmlFor="import-jsonl" className="btn btn-sm btn-primary cursor-pointer w-full">
            Import JSONL
          </label>
          <input
            id="import-jsonl"
            type="file"
            accept=".jsonl"
            className="hidden"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                onImportJSONL(e.target.files[0]);
                e.target.value = ""; // reset input
              }
            }}
            disabled={isEvaluating}
          />
        </div>

        {/* Import XLSX */}
        <div>
          <label htmlFor="import-xlsx" className="btn btn-sm btn-primary cursor-pointer w-full">
            Import XLSX
          </label>
          <input
            id="import-xlsx"
            type="file"
            accept=".xlsx"
            className="hidden"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                onImportXLSX(e.target.files[0]);
                e.target.value = ""; // reset input
              }
            }}
            disabled={isEvaluating}
          />
        </div>

        {/* Export JSONL */}
        <button className="btn btn-sm btn-primary w-full" onClick={onExportJSONL} disabled={isEvaluating}>
          Export JSONL
        </button>

        {/* Export XLSX */}
        <button className="btn btn-sm btn-primary w-full" onClick={onExportXLSX} disabled={isEvaluating}>
          Export XLSX
        </button>

        {/* Load Sample Data */}
        <button className="btn btn-sm btn-primary w-full col-span-2" onClick={onLoadSampleData} disabled={isEvaluating}>
          Load Sample Data
        </button>
      </div>
      <h3 className="text-lg font-semibold">Evaluators</h3>
      <div className="flex flex-col space-y-2 flex-1">
        <button
          className="btn btn-sm btn-primary"
          onClick={() => setIsAddColumnModalOpen(true)}
          disabled={isEvaluating}
        >
          Add Evaluator
        </button>
        <button className="btn btn-sm btn-primary" onClick={onEvaluateAll} disabled={isEvaluating}>
          {isEvaluating ? "Evaluating..." : "Evaluate All"}
        </button>
        {/* Existing Evaluators */}
        <div className="space-y-1 flex-1 h-32">
          {columns
            .filter((col) => !DEFAULT_COLUMNS_LOWER.includes(col.field!.toLowerCase()))
            .map((col) => (
              <div key={col.field} className="flex items-center justify-between bg-base-100 rounded p-2">
                <span className="text-sm font-medium">{col.field}</span>
                <div className="dropdown dropdown-left">
                  <label tabIndex={0} className="btn btn-xs btn-ghost">
                    &#x22EE;
                  </label>
                  <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-32">
                    <li>
                      <a
                        onClick={() => onEvaluateColumn(col.field!)}
                        className={`${isEvaluating ? "opacity-50 cursor-not-allowed" : ""}`}
                      >
                        Evaluate
                      </a>
                    </li>
                    <li>
                      <a onClick={() => onClearColumn(col.field!)}>Clear</a>
                    </li>
                    <li>
                      <a onClick={() => onDeleteColumn(col.field!)}>Remove</a>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* Add Evaluator Modal */}
      {isAddColumnModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Add Evaluator</h3>
            <div className="mt-4">
              {/* Evaluator Type Selection */}
              <label className="label">
                <span className="label-text">Select Evaluator Category</span>
              </label>
              <select
                value={selectedEvaluatorType}
                onChange={(e) => {
                  setSelectedEvaluatorType(e.target.value);
                  setNewColumnName("");
                  setSelectedContextVerificationOption("");
                  setSelectedRewardType("");
                  setSelectedBinaryType("");
                }}
                className="select select-bordered w-full"
                disabled={isEvaluating}
              >
                <option value="" disabled>
                  Choose evaluator category
                </option>
                <option value="Continuous Reward">Continuous Reward</option>
                <option value="Binary">Binary</option>
                <option value="Context Verification">Context Verification</option>
              </select>
            </div>

            {/* For Continuous Reward */}
            {selectedEvaluatorType === "Continuous Reward" && (
              <>
                <div className="mt-4">
                  <label className="label">
                    <span className="label-text">Evaluator Criteria</span>
                  </label>
                  <div className="flex items-center space-x-2">
                    <select
                      value={selectedRewardType}
                      onChange={(e) => setSelectedRewardType(e.target.value)}
                      className="select select-bordered"
                      disabled={isEvaluating}
                    >
                      <option value="" disabled>
                        Choose reward type
                      </option>
                      <option value="Reward responses">Reward responses</option>
                      <option value="Penalize responses">Penalize responses</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Enter evaluator criteria"
                      className="input input-bordered flex-1"
                      value={newColumnName}
                      onChange={(e) => setNewColumnName(e.target.value)}
                      disabled={isEvaluating}
                    />
                  </div>
                </div>
                {/* Display the concatenated Evaluator Name */}
                {selectedRewardType && newColumnName.trim() && (
                  <div className="mt-2">
                    <label className="label">
                      <span className="label-text">Evaluator Name</span>
                    </label>
                    <input
                      type="text"
                      className="input input-bordered w-full"
                      value={`${selectedRewardType} ${newColumnName.trim()}`}
                      readOnly
                    />
                  </div>
                )}
              </>
            )}

            {/* For Binary */}
            {selectedEvaluatorType === "Binary" && (
              <>
                <div className="mt-4">
                  <label className="label">
                    <span className="label-text">Evaluator Criteria</span>
                  </label>
                  <div className="flex items-center space-x-2">
                    <select
                      value={selectedBinaryType}
                      onChange={(e) => setSelectedBinaryType(e.target.value)}
                      className="select select-bordered"
                      disabled={isEvaluating}
                    >
                      <option value="" disabled>
                        Choose binary type
                      </option>
                      <option value="Response passes if">Response passes if</option>
                      <option value="Response fails if">Response fails if</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Enter evaluator criteria"
                      className="input input-bordered flex-1"
                      value={newColumnName}
                      onChange={(e) => setNewColumnName(e.target.value)}
                      disabled={isEvaluating}
                    />
                  </div>
                </div>
                {/* Display the concatenated Evaluator Name */}
                {selectedBinaryType && newColumnName.trim() && (
                  <div className="mt-2">
                    <label className="label">
                      <span className="label-text">Evaluator Name</span>
                    </label>
                    <input
                      type="text"
                      className="input input-bordered w-full"
                      value={`${selectedBinaryType} ${newColumnName.trim()}`}
                      readOnly
                    />
                  </div>
                )}
              </>
            )}

            {/* For Context Verification */}
            {selectedEvaluatorType === "Context Verification" && (
              <div className="mt-4">
                <label className="label">
                  <span className="label-text">Select Context Verification Type</span>
                </label>
                <select
                  value={selectedContextVerificationOption}
                  onChange={(e) => setSelectedContextVerificationOption(e.target.value)}
                  className="select select-bordered w-full"
                  disabled={isEvaluating}
                >
                  <option value="" disabled>
                    Choose option
                  </option>
                  <option value="Context Faithfulness">Context Faithfulness</option>
                  <option value="Context Recall">Context Recall</option>
                  <option value="Context Precision">Context Precision</option>
                </select>
              </div>
            )}

            {/* Modal Actions */}
            <div className="modal-action">
              <button
                className="btn btn-primary"
                onClick={() => {
                  let columnName = "";
                  if (selectedEvaluatorType === "Continuous Reward") {
                    if (!selectedRewardType) {
                      alert("Please select Reward type.");
                      return;
                    }
                    if (!newColumnName.trim()) {
                      alert("Evaluator criteria cannot be empty.");
                      return;
                    }
                    columnName = `${selectedRewardType} ${newColumnName.trim()}`;
                  } else if (selectedEvaluatorType === "Binary") {
                    if (!selectedBinaryType) {
                      alert("Please select Binary type.");
                      return;
                    }
                    if (!newColumnName.trim()) {
                      alert("Evaluator criteria cannot be empty.");
                      return;
                    }
                    columnName = `${selectedBinaryType} ${newColumnName.trim()}`;
                  } else if (selectedEvaluatorType === "Context Verification") {
                    if (!selectedContextVerificationOption) {
                      alert("Please select Context Verification option.");
                      return;
                    }
                    columnName = selectedContextVerificationOption;
                  }

                  if (!columnName) {
                    alert("Evaluator name cannot be empty.");
                    return;
                  }

                  onAddColumn(columnName);
                  // Reset modal state
                  setNewColumnName("");
                  setSelectedEvaluatorType("");
                  setSelectedContextVerificationOption("");
                  setSelectedRewardType("");
                  setSelectedBinaryType("");
                  setIsAddColumnModalOpen(false);
                }}
                disabled={
                  isEvaluating ||
                  !selectedEvaluatorType ||
                  (selectedEvaluatorType === "Continuous Reward" && (!selectedRewardType || !newColumnName.trim())) ||
                  (selectedEvaluatorType === "Binary" && (!selectedBinaryType || !newColumnName.trim())) ||
                  (selectedEvaluatorType === "Context Verification" && !selectedContextVerificationOption)
                }
              >
                Add
              </button>
              <button
                className="btn"
                onClick={() => {
                  setIsAddColumnModalOpen(false);
                  setNewColumnName("");
                  setSelectedEvaluatorType("");
                  setSelectedContextVerificationOption("");
                  setSelectedRewardType("");
                  setSelectedBinaryType("");
                }}
                disabled={isEvaluating}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// ExploreToolbar Component
const ExploreToolbar: React.FC<ExploreToolbarProps> = ({
  versions,
  evaluators,
  selectedVersion,
  setSelectedVersion,
  selectedEvaluators,
  setSelectedEvaluators,
}) => (
  <div className="space-y-4 h-full flex flex-col overflow-hidden">
    <h3 className="text-lg font-semibold">Explore Tools</h3>
    {/* Version Filter */}
    <div>
      <label className="label">
        <span className="label-text">Filter by Version</span>
      </label>
      <select
        value={selectedVersion || ""}
        onChange={(e) => setSelectedVersion(e.target.value || null)}
        className="select select-bordered w-full"
      >
        <option value="">All Versions</option>
        {versions.map((version) => (
          <option key={version} value={version}>
            {version}
          </option>
        ))}
      </select>
    </div>
    {/* Evaluator Filter (Checkboxes) */}
    <div className="flex flex-col flex-grow overflow-hidden">
      <label className="label">
        <span className="label-text">Filter by Evaluator(s)</span>
      </label>
      <div className="space-y-2 overflow-y-auto">
        {evaluators.map((evaluator) => (
          <div key={evaluator} className="flex items-center">
            <input
              type="checkbox"
              id={`evaluator-${evaluator}`}
              className="checkbox checkbox-primary mr-2"
              checked={selectedEvaluators.includes(evaluator)}
              onChange={() => {
                if (selectedEvaluators.includes(evaluator)) {
                  setSelectedEvaluators(selectedEvaluators.filter((e) => e !== evaluator));
                } else {
                  setSelectedEvaluators([...selectedEvaluators, evaluator]);
                }
              }}
            />
            <label htmlFor={`evaluator-${evaluator}`} className="text-sm font-medium break-words">
              {evaluator}
            </label>
          </div>
        ))}
      </div>
    </div>
  </div>
);

// ExploreView Component
const ExploreView: React.FC<{
  gridData: RowData[];
  columnDefs: ColDef[];
  selectedVersion: string | null;
  selectedEvaluators: string[];
}> = ({ gridData, columnDefs, selectedVersion, selectedEvaluators }) => {
  // Get evaluator columns
  const evaluatorColumns = useMemo(() => {
    const evaluators = columnDefs
      .map((col) => col.field!)
      .filter((field) => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()) && field !== "id");
    if (selectedEvaluators.length > 0) {
      return evaluators.filter((evaluator) => selectedEvaluators.includes(evaluator));
    }
    return evaluators;
  }, [columnDefs, selectedEvaluators]);

  // Get versions
  const versions = useMemo(() => {
    return Array.from(new Set(gridData.map((row) => row.Version))).filter((v) => v != null);
  }, [gridData]);

  // Filtered Data
  const filteredData = useMemo(() => {
    return gridData.filter((row) => {
      const versionMatch = selectedVersion ? row.Version === selectedVersion : true;
      return versionMatch;
    });
  }, [gridData, selectedVersion]);

  // Data for Bar Chart: Average evaluator scores by version
  const dataForBarChart = useMemo(() => {
    const barChartVersions = selectedVersion ? [selectedVersion] : versions;
    return barChartVersions.map((version) => {
      const rowsForVersion = filteredData.filter((row) => row.Version === version);
      const dataPoint: any = {
        version,
      };
      evaluatorColumns.forEach((evaluator) => {
        const total = rowsForVersion.reduce((sum, row) => {
          const value = parseFloat(row[evaluator]);
          if (!isNaN(value)) {
            return sum + value;
          } else {
            return sum;
          }
        }, 0);
        const count = rowsForVersion.reduce((acc, row) => {
          const value = parseFloat(row[evaluator]);
          if (!isNaN(value)) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0);
        dataPoint[evaluator] = count > 0 ? parseFloat((total / count).toFixed(3)) : 0;
      });
      return dataPoint;
    });
  }, [filteredData, versions, evaluatorColumns, selectedVersion]);

  // Data for Second Bar Chart: High-level average stats per version
  const dataForSecondBarChart = useMemo(() => {
    const barChartVersions = selectedVersion ? [selectedVersion] : versions;
    return barChartVersions.map((version) => {
      const rowsForVersion = filteredData.filter((row) => row.Version === version);
      const total = rowsForVersion.reduce((sum, row) => {
        let rowTotal = 0;
        let rowCount = 0;
        evaluatorColumns.forEach((evaluator) => {
          const value = parseFloat(row[evaluator]);
          if (!isNaN(value)) {
            rowTotal += value;
            rowCount++;
          }
        });
        if (rowCount > 0) {
          return sum + rowTotal / rowCount;
        } else {
          return sum;
        }
      }, 0);
      const count = rowsForVersion.length;
      return {
        version,
        average: count > 0 ? parseFloat((total / count).toFixed(3)) : 0,
      };
    });
  }, [filteredData, versions, evaluatorColumns, selectedVersion]);

  // Define new color scheme
  const BAR_COLORS = [
    "#4e79a7", // Blue
    "#f28e2b", // Orange
    "#e15759", // Red
    "#76b7b2", // Teal
    "#59a14f", // Green
    "#edc949", // Yellow
    "#af7aa1", // Purple
    "#ff9da7", // Pink
    "#9c755f", // Brown
    "#bab0ab", // Grey
  ];

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="flex-1 flex flex-col space-y-6 overflow-auto px-4 pb-4">
        {/* Bar Chart */}
        <div className="flex-1 flex flex-col">
          <h3 className="text-xl font-semibold mb-2 flex-shrink-0">Average Evaluator Scores by Version</h3>
          <div className="flex-1">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={dataForBarChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="version" />
                <YAxis domain={[0, 1]} />
                <Tooltip />
                {/* Removed Legend */}
                {evaluatorColumns.map((evaluator, index) => (
                  <Bar dataKey={evaluator} fill={BAR_COLORS[index % BAR_COLORS.length]} key={evaluator} />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Second Bar Chart */}
        <div className="flex-1 flex flex-col">
          <h3 className="text-xl font-semibold mb-2 flex-shrink-0">Average Total Score by Version</h3>
          <div className="flex-1">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={dataForSecondBarChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="version" />
                <YAxis domain={[0, 1]} />
                <Tooltip />
                {/* Removed Legend */}
                <Bar dataKey="average" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Additional components like Statistics Table can be added here if needed */}
      </div>
    </div>
  );
};


// APIPlayground Component
const APIPlayground: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"data" | "explore">("data");

  const [gridData, setGridData] = useState<RowData[]>([]);
  const [rowWrapEnabled, setRowWrapEnabled] = useState(false);

  const onClearAll = () => {
    setGridData([]);
    setColumnDefs(getInitialColumnDefs(rowWrapEnabled));
  };

  // Function to generate initial column definitions based on rowWrapEnabled
  const getInitialColumnDefs = (wrapText: boolean): ColDef[] =>
    DEFAULT_COLUMNS.map((col) => ({
      field: col,
      headerTooltip: col,
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      wrapText: wrapText,
      autoHeight: wrapText,
      cellStyle: { "white-space": wrapText ? "pre-wrap" : "nowrap" },
    }));

  // Initialize columnDefs with default columns
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(getInitialColumnDefs(rowWrapEnabled));

  const gridRef = useRef<AgGridReact<RowData>>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const [selectedVersionFilter, setSelectedVersionFilter] = useState<string | null>(null);
  const [selectedEvaluatorsFilter, setSelectedEvaluatorsFilter] = useState<string[]>([]);

  const [isEvaluating, setIsEvaluating] = useState(false);

  // Test API connection on component mount
  // DONT CHANGE THIS UNLESS EXPLICITLY REQUESTED TOs
  useEffect(() => {
    const testConnection = async () => {
      console.log("Testing API connection...");
      const myHeaders = new Headers();
      myHeaders.append("API-Key", "key_1sh5xegttinim3n2tra7");
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "messages": [
          {
            "role": "user",
            "content": "Hi, how are you?"
          },
          {
            "role": "user",
            "content": "Hi, it's great to meet you! How are you doing today?"
          }
        ],
        "evaluation_criteria": "Reward responses that demonstrate overall quality"
      });

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow" as RequestRedirect
      };

      try {
        const response = await fetch("https://platform.composo.ai/api/v1/evals/reward", requestOptions);
        const result = await response.json();
        if (!result.score && result.score !== 0) {
          toast.error("API connection failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.success("API connected successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } catch (error) {
        toast.error("API connection failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };

    testConnection();
  }, []);

  const handleGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const handleCellValueChanged = (params: CellValueChangedEvent) => {
    const updatedData = gridData.map((row) => {
      if (row.id === params.data.id) {
        return { ...row, [params.colDef.field!]: params.newValue };
      }
      return row;
    });
    setGridData(updatedData);
  };

  const deleteSelectedRows = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) {
      const idsToDelete = selectedRows.map((row) => row.id);
      setGridData((prevData) => prevData.filter((row) => !idsToDelete.includes(row.id)));
    }
  };

  const addRow = () => {
    const newId = gridData.length > 0 ? Math.max(...gridData.map((row) => row.id)) + 1 : 1;
    const newRow: RowData = { id: newId };
    // Initialize all columns with empty strings
    columnDefs.forEach((col) => {
      if (col.field) {
        newRow[col.field] = "";
      }
    });
    setGridData((prevData) => [...prevData, newRow]);
  };

  const deleteColumn = (fieldToDelete: string) => {
    if (DEFAULT_COLUMNS_LOWER.includes(fieldToDelete.toLowerCase())) {
      // Prevent deletion of default columns
      alert(`The column "${fieldToDelete}" cannot be deleted.`);
      return;
    }
    setColumnDefs((prevDefs) => prevDefs.filter((col) => col.field?.toLowerCase() !== fieldToDelete.toLowerCase()));
    setGridData((prevData) =>
      prevData.map((row) => {
        const updatedRow = { ...row };
        delete updatedRow[fieldToDelete];
        return updatedRow;
      }),
    );
  };

  const onAddColumn = (columnName: string) => {
    const newColumnName = columnName.trim();
    if (!newColumnName) {
      alert("Column name cannot be empty.");
      return;
    }
    const existingFieldsLower = columnDefs.map((col) => col.field!.toLowerCase());
    if (
      existingFieldsLower.includes(newColumnName.toLowerCase()) ||
      DEFAULT_COLUMNS_LOWER.includes(newColumnName.toLowerCase())
    ) {
      alert("Column name already exists or is reserved.");
      return;
    }
    setColumnDefs((prevDefs) => [
      ...prevDefs,
      {
        field: newColumnName,
        headerTooltip: newColumnName,
        sortable: true,
        filter: true,
        cellDataType: 'text',
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        wrapText: rowWrapEnabled,
        autoHeight: rowWrapEnabled,
        // cellStyle is handled in DataGrid's defaultColDef
      },
    ]);
    setGridData((prevData) =>
      prevData.map((row) => ({
        ...row,
        [newColumnName]: "",
      })),
    );
  };

  const onClearColumn = (field: string) => {
    setGridData((prevData) => prevData.map((row) => ({ ...row, [field]: null })));
  };

  const BATCH_SIZE = 4;

  const onEvaluateColumn = async (evaluatorName: string) => {
    if (isEvaluating) return;
    setIsEvaluating(true);

    // Filter rows that need evaluation
    const rowsToEvaluate = gridData
      .map((row, index) => ({ row, index }))
      .filter(({ row }) => !row[evaluatorName]);

    // Process in batches
    for (let i = 0; i < rowsToEvaluate.length; i += BATCH_SIZE) {
      const batch = rowsToEvaluate.slice(i, i + BATCH_SIZE);

      const batchPromises = batch.map(({ row, index }) =>
        evaluate(row["Input"], row["Response"], row["Context"], row["Reference"], evaluatorName)
          .then(result => ({ result, index }))
          .catch(error => {
            console.error(`Error evaluating row ${index}:`, error);
            return { result: { score: null, detail: "Evaluation failed" }, index };
          })
      );

      const results = await Promise.all(batchPromises);

      // Update grid data with batch results
      setGridData(prevData => {
        const newData = [...prevData];
        results.forEach(({ result, index }) => {
          if (result.score !== null && result.score !== undefined) {
            newData[index] = { ...newData[index], [evaluatorName]: result.score };
          } else if (result.detail) {
            newData[index] = { ...newData[index], [evaluatorName]: result.detail };
          }
        });
        return newData;
      });
    }

    setIsEvaluating(false);
  };

  const onEvaluateAll = async () => {
    if (isEvaluating) return;
    setIsEvaluating(true);

    const evaluatorColumns = columnDefs
      .filter((col) => !DEFAULT_COLUMNS_LOWER.includes(col.field!.toLowerCase()))
      .map((col) => col.field!);

    for (const evaluator of evaluatorColumns) {
      // Filter rows that need evaluation for this evaluator
      const rowsToEvaluate = gridData
        .map((row, index) => ({ row, index }))
        .filter(({ row }) => !row[evaluator]);

      // Process in batches
      for (let i = 0; i < rowsToEvaluate.length; i += BATCH_SIZE) {
        const batch = rowsToEvaluate.slice(i, i + BATCH_SIZE);

        const batchPromises = batch.map(({ row, index }) =>
          evaluate(row["Input"], row["Response"], row["Context"], row["Reference"], evaluator)
            .then(result => ({ result, index }))
            .catch(error => {
              console.error(`Error evaluating row ${index}, evaluator ${evaluator}:`, error);
              return { result: { score: null, detail: "Evaluation failed" }, index };
            })
        );

        const results = await Promise.all(batchPromises);

        // Update grid data with batch results
        setGridData(prevData => {
          const newData = [...prevData];
          results.forEach(({ result, index }) => {
            if (result.score !== null && result.score !== undefined) {
              newData[index] = { ...newData[index], [evaluator]: result.score.toString() };
            } else if (result.detail) {
              newData[index] = { ...newData[index], [evaluator]: result.detail };
            }
          });
          return newData;
        });
      }
    }

    setIsEvaluating(false);
  };

  interface EvaluationResult {
    score: number | null;
    detail?: string;
  }

  // Helper function to create consistent request options
  const createRequestOptions = (payload: any): RequestInit => {
    const myHeaders = new Headers();
    myHeaders.append("API-Key", "key_1sh5xegttinim3n2tra7");
    myHeaders.append("Content-Type", "application/json");

    let body;
    try {
      body = JSON.stringify(payload);
      console.log("Request payload:", body);
    } catch (error) {
      console.error("Error stringifying payload:", error);
      throw error;
    }

    return {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow" as RequestRedirect
    };
  };

  const evaluate = async (
    input: string,
    response: string,
    context: string,
    reference: string,
    evaluatorName: string,
  ): Promise<EvaluationResult> => {
    try {
      console.log("Evaluating with input:", input, "Type:", typeof input);
      console.log("Evaluating with response:", response, "Type:", typeof response);

      if (typeof response !== "string" || response.trim().length === 0) {
        console.error("Invalid response:", response);
        return {
          score: null,
          detail: "Response is invalid or empty.",
        };
      }

      const evaluatorNameLower = evaluatorName.toLowerCase();
      const API_BASE_URL = "https://platform.composo.ai/api/v1/evals";

      if (evaluatorNameLower.startsWith("reward responses") || evaluatorNameLower.startsWith("penalize responses")) {
        // Reward Evaluation
        // if input is an empty string or null, skip the user message
        let payload;
        if (input.trim() === "") {
          payload = {
            messages: [{ role: "assistant", content: response }],
            evaluation_criteria: evaluatorName,
          };
        } else {
          payload = {
            messages: [
              { role: "user", content: input },
              { role: "assistant", content: response },
            ],
            evaluation_criteria: evaluatorName,
          };
        }


        const res = await fetch(`${API_BASE_URL}/reward`, createRequestOptions(payload));
        const data = await res.json();

        if (!res.ok) {
          return {
            score: null,
            detail: data.detail || `Reward API Error: ${res.status} ${res.statusText}`,
          };
        }

        return { score: data.score };
      } else if (
        evaluatorNameLower.startsWith("response passes if") ||
        evaluatorNameLower.startsWith("response fails if")
      ) {
        // Binary Evaluation
        const payload = {
          messages: [
            { role: "user", content: input },
            { role: "assistant", content: response },
          ],
          reference_output: reference || null,
          evaluation_criteria: evaluatorName,
        };

        const res = await fetch(`${API_BASE_URL}/binary`, createRequestOptions(payload));
        const data = await res.json();

        if (!res.ok) {
          return {
            score: null,
            detail: data.detail || `Binary Evaluation API Error: ${res.status} ${res.statusText}`,
          };
        }

        return { score: data.passed ? 1 : 0 };
      } else if (evaluatorNameLower.startsWith("context faithfulness")) {
        // Context Faithfulness Verification
        const payload = {
          input: input,
          response: response,
          context: [context],
        };

        const res = await fetch(`${API_BASE_URL}/verify/faithfulness`, createRequestOptions(payload));
        const data = await res.json();

        if (!res.ok) {
          return {
            score: null,
            detail: data.detail || `Faithfulness Verification API Error: ${res.status} ${res.statusText}`,
          };
        }

        return { score: data.score };
      } else if (evaluatorNameLower.startsWith("context recall")) {
        // Context Recall Evaluation
        const payload = {
          input: input,
          response: response,
          context: [context],
          reference: reference,
        };

        const res = await fetch(`${API_BASE_URL}/verify/context_recall`, createRequestOptions(payload));
        const data = await res.json();

        if (!res.ok) {
          return {
            score: null,
            detail: data.detail || `Context Recall API Error: ${res.status} ${res.statusText}`,
          };
        }

        return { score: data.score };
      } else if (evaluatorNameLower.startsWith("context precision")) {
        // Context Precision Evaluation
        const payload = {
          input: input,
          response: response,
          context: [context],
          reference: reference || null,
        };

        const res = await fetch(`${API_BASE_URL}/verify/context_precision`, createRequestOptions(payload));
        const data = await res.json();

        if (!res.ok) {
          return {
            score: null,
            detail: data.detail || `Context Precision API Error: ${res.status} ${res.statusText}`,
          };
        }

        return { score: data.score };
      } else {
        return {
          score: null,
          detail: `Unknown evaluator: ${evaluatorName}`,
        };
      }
    } catch (error: any) {
      // Handle any unexpected errors
      console.error(`Evaluation error for evaluator "${evaluatorName}":`, error);
      return {
        score: null,
        detail: `Error: ${error.message || 'An unexpected error occurred'}`,
      };
    }
  };

  const handleExportJSONL = () => {
    if (gridData.length === 0) {
      console.error("No data to export");
      return;
    }

    const dataToExport = gridData.map(({ id, ...rest }) => rest); // Exclude the 'id' field

    const jsonlContent = dataToExport.map((row) => JSON.stringify(row)).join("\n");

    const blob = new Blob([jsonlContent], { type: "application/jsonl;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.jsonl");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("File download not supported in this browser.");
    }
  };

  const handleExportXLSX = () => {
    if (gridData.length === 0) {
      console.error("No data to export");
      return;
    }

    const dataToExport = gridData.map(({ id, ...rest }) => rest); // Exclude the 'id' field

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    const xlsxContent = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const blob = new Blob([xlsxContent], { type: "application/octet-stream" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.xlsx");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("File download not supported in this browser.");
    }
  };

  const handleImportJSONL = (file: File) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const content = e.target?.result;
      if (typeof content === "string") {
        const lines = content.split("\n").filter((line) => line.trim() !== "");
        const data: RowData[] = [];
        const fieldsSet = new Set<string>();

        // Create a mapping of lowercase standard column names to their correct casing
        const standardColumnsMap = new Map<string, string>();
        DEFAULT_COLUMNS.forEach((col) => {
          standardColumnsMap.set(col.toLowerCase(), col);
        });

        for (let i = 0; i < lines.length; i++) {
          try {
            const parsedLine = JSON.parse(lines[i]);
            // Standardize the keys in parsedLine
            const standardizedLine: any = {};
            Object.keys(parsedLine).forEach((key) => {
              const lowerKey = key.toLowerCase();
              const standardKey = standardColumnsMap.get(lowerKey) || key;
              standardizedLine[standardKey] = parsedLine[key];
            });
            // Convert empty strings to null for non-default columns
            Object.keys(standardizedLine).forEach((key) => {
              if (!DEFAULT_COLUMNS_LOWER.includes(key.toLowerCase()) && standardizedLine[key] === "") {
                standardizedLine[key] = null;
              }
            });
            data.push({ id: i + 1, ...standardizedLine });
            Object.keys(standardizedLine).forEach((key) => fieldsSet.add(key));
          } catch (error) {
            console.error(`Error parsing line ${i + 1}:`, error);
            alert(`Error parsing JSONL file at line ${i + 1}.`);
            return;
          }
        }

        const fields = Array.from(fieldsSet);
        const fieldsLower = fields.map((f) => f.toLowerCase());

        // Check for missing required columns (case-insensitive)
        const missingColumns = REQUIRED_COLUMNS.filter(
          (col) => !fieldsLower.includes(col.toLowerCase()),
        );

        if (missingColumns.length > 0) {
          alert(`Error: Missing required columns: ${missingColumns.join(", ")}`);
          return;
        }

        // Determine which default columns are missing
        const defaultColumnsToAdd = DEFAULT_COLUMNS.filter(
          (col) => !fields.map((f) => f.toLowerCase()).includes(col.toLowerCase()),
        );

        // Build column definitions
        const newColumns: ColDef[] = [
          // Include default columns
          ...DEFAULT_COLUMNS.map((col) => ({
            field: col,
            headerTooltip: col,
            sortable: true,
            filter: true,
            cellEditor: "agLargeTextCellEditor",
            cellEditorPopup: true,
            wrapText: rowWrapEnabled,
            autoHeight: rowWrapEnabled,
            // cellStyle is handled in DataGrid's defaultColDef
          })),
          // Add any additional fields from the JSONL
          ...fields
            .filter((field) => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()))
            .map((field) => {
              return {
                field: field,
                sortable: true,
                filter: true,
                cellDataType: 'text',
                cellEditor: "agLargeTextCellEditor",
                cellEditorPopup: true,
                wrapText: rowWrapEnabled,
                autoHeight: rowWrapEnabled,
                // cellStyle is handled in DataGrid's defaultColDef
              };
            }),
        ];

        // Remove duplicate columns while preserving properties
        const uniqueColumnsMap = new Map<string, ColDef>();
        newColumns.forEach((col) => {
          const fieldLower = col.field!.toLowerCase();
          if (!uniqueColumnsMap.has(fieldLower)) {
            uniqueColumnsMap.set(fieldLower, {
              ...col,
              headerTooltip: col.headerTooltip || col.field,
            });
          } else {
            // Merge properties of duplicate columns
            uniqueColumnsMap.set(fieldLower, {
              ...uniqueColumnsMap.get(fieldLower)!,
              ...col,
              headerTooltip: col.headerTooltip || col.field,
            });
          }
        });
        const uniqueColumns = Array.from(uniqueColumnsMap.values());

        setColumnDefs(uniqueColumns);

        // Initialize missing default columns with empty strings in data
        const updatedData = data.map((rowData) => {
          defaultColumnsToAdd.forEach((col) => {
            if (!(col in rowData)) {
              rowData[col] = "";
            }
          });
          return rowData;
        });

        setGridData(updatedData);
      }
    };
    reader.onerror = function (e) {
      console.error("File reading error:", e);
      alert("Error reading JSONL file.");
    };
    reader.readAsText(file);
  };

  const handleImportXLSX = (file: File) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Create a mapping of lowercase standard column names to their correct casing
      const standardColumnsMap = new Map<string, string>();
      DEFAULT_COLUMNS.forEach((col) => {
        standardColumnsMap.set(col.toLowerCase(), col);
      });

      const rawData = XLSX.utils.sheet_to_json<RowData>(worksheet, { defval: "" });

      if (rawData.length === 0) {
        alert("The XLSX file is empty.");
        return;
      }

      // Standardize column names in the data
      const jsonData = rawData.map((rowData, index) => {
        const standardizedRow: any = {};
        Object.keys(rowData).forEach((key) => {
          const lowerKey = key.toLowerCase();
          const standardKey = standardColumnsMap.get(lowerKey) || key;
          standardizedRow[standardKey] = rowData[key];
        });
        return standardizedRow;
      });

      const fields = Object.keys(jsonData[0]);
      const fieldsLower = fields.map((f) => f.toLowerCase());

      // Check for missing required columns (case-insensitive)
      const missingColumns = REQUIRED_COLUMNS.filter(
        (col) => !fieldsLower.includes(col.toLowerCase()),
      );

      if (missingColumns.length > 0) {
        alert(`Error: Missing required columns: ${missingColumns.join(", ")}`);
        return;
      }

      // Determine which default columns are missing
      const defaultColumnsToAdd = DEFAULT_COLUMNS.filter(
        (col) => !fields.map((f) => f.toLowerCase()).includes(col.toLowerCase()),
      );

      // Build column definitions
      const newColumns: ColDef[] = [
        // Include default columns
        ...DEFAULT_COLUMNS.map((col) => ({
          field: col,
          headerTooltip: col,
          sortable: true,
          filter: true,
          cellEditor: "agLargeTextCellEditor",
          cellEditorPopup: true,
          wrapText: rowWrapEnabled,
          autoHeight: rowWrapEnabled,
          // cellStyle is handled in DataGrid's defaultColDef
        })),
        // Add any additional fields from the XLSX
        ...fields
          .filter((field) => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()))
          .map((field) => {
            return {
              field: field,
              headerTooltip: field,
              sortable: true,
              filter: true,
              cellEditor: "agLargeTextCellEditor",
              cellEditorPopup: true,
              wrapText: rowWrapEnabled,
              autoHeight: rowWrapEnabled,
              // cellStyle is handled in DataGrid's defaultColDef
            };
          }),
      ];

      // Remove duplicate columns while preserving properties
      const uniqueColumnsMap = new Map<string, ColDef>();
      newColumns.forEach((col) => {
        const fieldLower = col.field!.toLowerCase();
        if (!uniqueColumnsMap.has(fieldLower)) {
          uniqueColumnsMap.set(fieldLower, {
            ...col,
            headerTooltip: col.headerTooltip || col.field,
          });
        } else {
          // Merge properties of duplicate columns
          uniqueColumnsMap.set(fieldLower, {
            ...uniqueColumnsMap.get(fieldLower)!,
            ...col,
            headerTooltip: col.headerTooltip || col.field,
          });
        }
      });
      const uniqueColumns = Array.from(uniqueColumnsMap.values());

      setColumnDefs(uniqueColumns);

      const evaluatorColumns = fields.filter(
        (field) => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase())
      );

      const updatedData = jsonData.map((rowData, index) => {
        defaultColumnsToAdd.forEach((col) => {
          if (!(col in rowData)) {
            rowData[col] = "";
          }
        });
        evaluatorColumns.forEach((col) => {
          if (rowData[col] === "") {
            rowData[col] = null;
          }
        });
        const { id, ...rest } = rowData;
        return { id: index + 1, ...rest };
      });

      setGridData(updatedData);
    };
    reader.onerror = function (e) {
      console.error("File reading error:", e);
      alert("Error reading XLSX file.");
    };
    reader.readAsArrayBuffer(file);
  };

  const onLoadSampleData = () => {
    const { gridData: sampleGridData, columnDefs: sampleColumnDefs } = getSampleData();

    // Include default columns
    const evaluatorColumns = sampleColumnDefs.filter(
      (colDef) => !DEFAULT_COLUMNS_LOWER.includes(colDef.field!.toLowerCase()),
    );

    const newColumnDefs: ColDef[] = [
      ...DEFAULT_COLUMNS.map((col) => ({
        field: col,
        headerTooltip: col,
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        wrapText: rowWrapEnabled,
        autoHeight: rowWrapEnabled,
        // cellStyle is handled in DataGrid's defaultColDef
      })),
      ...evaluatorColumns.map((colDef) => ({
        ...colDef,
        headerTooltip: colDef.headerTooltip || colDef.field,
        // cellStyle is handled in DataGrid's defaultColDef
      })),
    ];

    setColumnDefs(newColumnDefs);
    setGridData(sampleGridData);
  };

  // Function to get sample data
  const getSampleData = () => {
    const gridData: RowData[] = [
      // Entries for "Write an email promoting our new electric SUV to potential customers."
      {
        id: 1,
        Input: "Write an email promoting our new electric SUV to potential customers.",
        Response:
          "Dear Valued Customer,\n\nWe are excited to introduce our brand-new electric SUV, the EcoDrive X. With zero emissions and a range of 300 miles, it redefines efficient driving.\n\nBest regards,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "1.0",
        "Reward responses that highlight key features": "0.8",
        "Penalize responses that contain grammatical errors": "0",
        "Response passes if it includes a call-to-action": "1",
        "Response fails if it lacks professional tone": "0",
      },
      {
        id: 2,
        Input: "Write an email promoting our new electric SUV to potential customers.",
        Response:
          "Hello,\n\nCheck out our new electric SUV! It’s eco-friendly and super cool. Get yours now!\n\nCheers,\nAutoCorp",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "2.0",
        "Reward responses that highlight key features": "0.6",
        "Penalize responses that contain grammatical errors": "0",
        "Response passes if it includes a call-to-action": "1",
        "Response fails if it lacks professional tone": "1",
      },
      {
        id: 3,
        Input: "Write an email promoting our new electric SUV to potential customers.",
        Response:
          "Dear Valued Customer,\n\nIntroducing the EcoDrive X—our latest electric SUV. Experience unparalleled performance with zero emissions. Schedule a test drive today!\n\nSincerely,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "3.0",
        "Reward responses that highlight key features": "1.0",
        "Penalize responses that contain grammatical errors": "0",
        "Response passes if it includes a call-to-action": "1",
        "Response fails if it lacks professional tone": "0",
      },
      // Entries for "Compose a follow-up email to a client interested in our latest sedan."
      {
        id: 4,
        Input: "Compose a follow-up email to a client interested in our latest sedan.",
        Response:
          "Dear Mr. Smith,\n\nThank you for your interest in our 2023 LuxSedan. We would like to invite you for a test drive at your convenience.\n\nBest regards,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "1.0",
        "Reward responses that personalize the message": "0.9",
        "Penalize responses that contain errors": "0",
        "Response passes if it offers next steps": "1",
        "Response fails if missing contact information": "0",
      },
      {
        id: 5,
        Input: "Compose a follow-up email to a client interested in our latest sedan.",
        Response: "Hi,\n\nJust checking if you’re still interested in our sedan. Let us know.\n\nThanks,\nAutoCorp",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "2.0",
        "Reward responses that personalize the message": "0.5",
        "Penalize responses that contain errors": "0",
        "Response passes if it offers next steps": "0",
        "Response fails if missing contact information": "1",
      },
      {
        id: 6,
        Input: "Compose a follow-up email to a client interested in our latest sedan.",
        Response:
          "Dear Ms. Johnson,\n\nWe hope this message finds you well. Following up on your interest in the 2023 LuxSedan, we have exciting financing options available. Please contact us to discuss further.\n\nWarm regards,\nAutoCorp Sales Team\n(555) 123-4567",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "3.0",
        "Reward responses that personalize the message": "1.0",
        "Penalize responses that contain errors": "0",
        "Response passes if it offers next steps": "1",
        "Response fails if missing contact information": "0",
      },
      // Entries for "Draft an email offering a special discount on last year's car models."
      {
        id: 7,
        Input: "Draft an email offering a special discount on last year's car models.",
        Response:
          "Dear Customer,\n\nFor a limited time, enjoy up to 20% off on our 2022 models. Don’t miss this exclusive offer!\n\nBest,\nAutoCorp Sales",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "1.0",
        "Reward responses that mention discount details": "0.8",
        "Penalize responses that create urgency unethically": "0",
        "Response passes if it includes a call-to-action": "1",
        "Response fails if it lacks professional tone": "0",
      },
      {
        id: 8,
        Input: "Draft an email offering a special discount on last year's car models.",
        Response: "Hey,\n\nWe have some old cars on sale. Buy now!\n\nAutoCorp",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "2.0",
        "Reward responses that mention discount details": "0.4",
        "Penalize responses that create urgency unethically": "0",
        "Response passes if it includes a call-to-action": "0",
        "Response fails if it lacks professional tone": "1",
      },
      {
        id: 9,
        Input: "Draft an email offering a special discount on last year's car models.",
        Response:
          "Dear Valued Customer,\n\nWe are pleased to offer a special 25% discount on our remaining 2022 models. This is a great opportunity to own a premium vehicle at an exceptional price. Contact us today to learn more.\n\nSincerely,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "3.0",
        "Reward responses that mention discount details": "1.0",
        "Penalize responses that create urgency unethically": "0",
        "Response passes if it includes a call-to-action": "1",
        "Response fails if it lacks professional tone": "0",
      },
      // Entries for "Write an email to confirm an appointment for a test drive."
      {
        id: 10,
        Input: "Write an email to confirm an appointment for a test drive.",
        Response:
          "Dear Mr. Lee,\n\nThis is to confirm your appointment for a test drive of the EcoDrive X on March 5th at 2:00 PM. We look forward to seeing you.\n\nBest regards,\nAutoCorp Sales Team",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "1.0",
        "Reward responses that confirm details accurately": "0.9",
        "Penalize responses that contain errors in date/time": "0",
        "Response passes if it is courteous and professional": "1",
        "Response fails if missing key information": "0",
      },
      {
        id: 11,
        Input: "Write an email to confirm an appointment for a test drive.",
        Response: "Hi,\n\nYour test drive is confirmed.\n\nThanks,\nAutoCorp",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "2.0",
        "Reward responses that confirm details accurately": "0.5",
        "Penalize responses that contain errors in date/time": "0",
        "Response passes if it is courteous and professional": "0",
        "Response fails if missing key information": "1",
      },
      {
        id: 12,
        Input: "Write an email to confirm an appointment for a test drive.",
        Response:
          "Dear Ms. Brown,\n\nWe are writing to confirm your test drive appointment for the LuxSedan on April 12th at 10:00 AM. If you have any questions or need to reschedule, please do not hesitate to contact us.\n\nWarm regards,\nAutoCorp Sales Team\n(555) 123-4567",
        Context: "",
        Reference: "",
        Notes: "",
        Version: "3.0",
        "Reward responses that confirm details accurately": "1.0",
        "Penalize responses that contain errors in date/time": "0",
        "Response passes if it is courteous and professional": "1",
        "Response fails if missing key information": "0",
      },
    ];

    const columnDefsSample: ColDef[] = [
      { field: "Input", sortable: true, filter: true },
      { field: "Response", sortable: true, filter: true },
      { field: "Context", sortable: true, filter: true },
      { field: "Reference", sortable: true, filter: true },
      { field: "Notes", sortable: true, filter: true },
      { field: "Version", sortable: true, filter: true },
      {
        field: "Reward responses that highlight key features",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Penalize responses that contain grammatical errors",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Response passes if it includes a call-to-action",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Response fails if it lacks professional tone",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Reward responses that personalize the message",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Penalize responses that contain errors",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Response passes if it offers next steps",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Response fails if missing contact information",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Reward responses that confirm details accurately",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Penalize responses that contain errors in date/time",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Response passes if it is courteous and professional",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
      {
        field: "Response fails if missing key information",
        sortable: true,
        filter: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        // cellStyle is handled in DataGrid's defaultColDef
      },
    ];

    return { gridData: gridData, columnDefs: columnDefsSample };
  };

  const toggleRowWrap = () => {
    setRowWrapEnabled(!rowWrapEnabled);
  };

  // Compute versions and evaluator columns
  const versions = useMemo(() => {
    return Array.from(new Set(gridData.map((row) => row.Version))).filter((v) => v != null);
  }, [gridData]);

  const evaluatorColumns = useMemo(() => {
    return columnDefs
      .map((col) => col.field!)
      .filter((field) => !DEFAULT_COLUMNS_LOWER.includes(field.toLowerCase()) && field !== "id");
  }, [columnDefs]);

  // Update columnDefs when rowWrapEnabled changes
  useEffect(() => {
    setColumnDefs((prevDefs) =>
      prevDefs.map((col) => ({
        ...col,
        wrapText: rowWrapEnabled,
        autoHeight: rowWrapEnabled,
        // cellStyle is handled in DataGrid's defaultColDef
      })),
    );
  }, [rowWrapEnabled]);

  return (
    <div className="h-full bg-base-100 flex flex-col">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <header className="w-full border-b border-base-200">
        <div className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <img
              src={urlJoin(import.meta.env.BASE_URL, "/icon.png")}
              className="h-8 transition-transform hover:scale-105 hover:cursor-pointer"
              alt="Composo logo"
              onClick={() => {
                window.location.href = "/";
              }}
            />
            <a
              href={DOCS_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary btn-sm hover:opacity-90 transition-opacity"
            >
              Documentation
            </a>
          </div>
        </div>
      </header>

      <main className="container mx-auto px-6 flex-1 flex flex-col mb-8 overflow-hidden">
        <div className="flex gap-2 mt-8 mb-6">
          {["data", "explore"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab as "data" | "explore")}
              className={`
                                    px-6 py-2 rounded-lg font-medium transition-all
                                    ${activeTab === tab
                  ? "bg-primary text-primary-content shadow-md"
                  : "bg-base-200 hover:bg-base-300"
                }
                                `}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        <div className="flex gap-6 flex-1 overflow-hidden">
          {activeTab === "data" && (
            <div className="flex-1 flex flex-col overflow-hidden">
              <DataGrid
                gridData={gridData}
                columnDefs={columnDefs}
                onCellValueChanged={handleCellValueChanged}
                gridRef={gridRef}
                onGridReady={handleGridReady}
                rowWrapEnabled={rowWrapEnabled}
              />
            </div>
          )}

          {activeTab === "explore" && (
            <div className="flex-1 flex flex-col overflow-hidden">
              <ExploreView
                gridData={gridData}
                columnDefs={columnDefs}
                selectedVersion={selectedVersionFilter}
                selectedEvaluators={selectedEvaluatorsFilter}
              />
            </div>
          )}

          <div className="w-72 bg-base-200 rounded-lg p-6 shadow-lg h-full flex flex-col overflow-hidden">
            {activeTab === "data" ? (
              <DataToolbar
                onDeleteSelectedRows={deleteSelectedRows}
                onAddRow={addRow}
                columns={columnDefs}
                onDeleteColumn={deleteColumn}
                onClearColumn={onClearColumn}
                onEvaluateColumn={onEvaluateColumn}
                onAddColumn={onAddColumn}
                onExportJSONL={handleExportJSONL}
                onImportJSONL={handleImportJSONL}
                onExportXLSX={handleExportXLSX}
                onImportXLSX={handleImportXLSX}
                onEvaluateAll={onEvaluateAll}
                onLoadSampleData={onLoadSampleData}
                rowWrapEnabled={rowWrapEnabled}
                onToggleRowWrap={toggleRowWrap}
                isEvaluating={isEvaluating}
                onClearAll={onClearAll}
              />
            ) : (
              <ExploreToolbar
                versions={versions}
                evaluators={evaluatorColumns}
                selectedVersion={selectedVersionFilter}
                setSelectedVersion={setSelectedVersionFilter}
                selectedEvaluators={selectedEvaluatorsFilter}
                setSelectedEvaluators={setSelectedEvaluatorsFilter}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

// Function to get sample data
const getSampleData = () => {
  const gridData: RowData[] = [
    // Entries for "Write an email promoting our new electric SUV to potential customers."
    {
      id: 1,
      Input: "Write an email promoting our new electric SUV to potential customers.",
      Response:
        "Dear Valued Customer,\n\nWe are excited to introduce our brand-new electric SUV, the EcoDrive X. With zero emissions and a range of 300 miles, it redefines efficient driving.\n\nBest regards,\nAutoCorp Sales Team",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "1.0",
      "Reward responses that highlight key features": "0.8",
      "Penalize responses that contain grammatical errors": "0",
      "Response passes if it includes a call-to-action": "1",
      "Response fails if it lacks professional tone": "0",
    },
    {
      id: 2,
      Input: "Write an email promoting our new electric SUV to potential customers.",
      Response:
        "Hello,\n\nCheck out our new electric SUV! It’s eco-friendly and super cool. Get yours now!\n\nCheers,\nAutoCorp",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "2.0",
      "Reward responses that highlight key features": "0.6",
      "Penalize responses that contain grammatical errors": "0",
      "Response passes if it includes a call-to-action": "1",
      "Response fails if it lacks professional tone": "1",
    },
    {
      id: 3,
      Input: "Write an email promoting our new electric SUV to potential customers.",
      Response:
        "Dear Valued Customer,\n\nIntroducing the EcoDrive X—our latest electric SUV. Experience unparalleled performance with zero emissions. Schedule a test drive today!\n\nSincerely,\nAutoCorp Sales Team",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "3.0",
      "Reward responses that highlight key features": "1.0",
      "Penalize responses that contain grammatical errors": "0",
      "Response passes if it includes a call-to-action": "1",
      "Response fails if it lacks professional tone": "0",
    },
    // Entries for "Compose a follow-up email to a client interested in our latest sedan."
    {
      id: 4,
      Input: "Compose a follow-up email to a client interested in our latest sedan.",
      Response:
        "Dear Mr. Smith,\n\nThank you for your interest in our 2023 LuxSedan. We would like to invite you for a test drive at your convenience.\n\nBest regards,\nAutoCorp Sales Team",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "1.0",
      "Reward responses that personalize the message": "0.9",
      "Penalize responses that contain errors": "0",
      "Response passes if it offers next steps": "1",
      "Response fails if missing contact information": "0",
    },
    {
      id: 5,
      Input: "Compose a follow-up email to a client interested in our latest sedan.",
      Response: "Hi,\n\nJust checking if you’re still interested in our sedan. Let us know.\n\nThanks,\nAutoCorp",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "2.0",
      "Reward responses that personalize the message": "0.5",
      "Penalize responses that contain errors": "0",
      "Response passes if it offers next steps": "0",
      "Response fails if missing contact information": "1",
    },
    {
      id: 6,
      Input: "Compose a follow-up email to a client interested in our latest sedan.",
      Response:
        "Dear Ms. Johnson,\n\nWe hope this message finds you well. Following up on your interest in the 2023 LuxSedan, we have exciting financing options available. Please contact us to discuss further.\n\nWarm regards,\nAutoCorp Sales Team\n(555) 123-4567",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "3.0",
      "Reward responses that personalize the message": "1.0",
      "Penalize responses that contain errors": "0",
      "Response passes if it offers next steps": "1",
      "Response fails if missing contact information": "0",
    },
    // Entries for "Draft an email offering a special discount on last year's car models."
    {
      id: 7,
      Input: "Draft an email offering a special discount on last year's car models.",
      Response:
        "Dear Customer,\n\nFor a limited time, enjoy up to 20% off on our 2022 models. Don’t miss this exclusive offer!\n\nBest,\nAutoCorp Sales",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "1.0",
      "Reward responses that mention discount details": "0.8",
      "Penalize responses that create urgency unethically": "0",
      "Response passes if it includes a call-to-action": "1",
      "Response fails if it lacks professional tone": "0",
    },
    {
      id: 8,
      Input: "Draft an email offering a special discount on last year's car models.",
      Response: "Hey,\n\nWe have some old cars on sale. Buy now!\n\nAutoCorp",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "2.0",
      "Reward responses that mention discount details": "0.4",
      "Penalize responses that create urgency unethically": "0",
      "Response passes if it includes a call-to-action": "0",
      "Response fails if it lacks professional tone": "1",
    },
    {
      id: 9,
      Input: "Draft an email offering a special discount on last year's car models.",
      Response:
        "Dear Valued Customer,\n\nWe are pleased to offer a special 25% discount on our remaining 2022 models. This is a great opportunity to own a premium vehicle at an exceptional price. Contact us today to learn more.\n\nSincerely,\nAutoCorp Sales Team",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "3.0",
      "Reward responses that mention discount details": "1.0",
      "Penalize responses that create urgency unethically": "0",
      "Response passes if it includes a call-to-action": "1",
      "Response fails if it lacks professional tone": "0",
    },
    // Entries for "Write an email to confirm an appointment for a test drive."
    {
      id: 10,
      Input: "Write an email to confirm an appointment for a test drive.",
      Response:
        "Dear Mr. Lee,\n\nThis is to confirm your appointment for a test drive of the EcoDrive X on March 5th at 2:00 PM. We look forward to seeing you.\n\nBest regards,\nAutoCorp Sales Team",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "1.0",
      "Reward responses that confirm details accurately": "0.9",
      "Penalize responses that contain errors in date/time": "0",
      "Response passes if it is courteous and professional": "1",
      "Response fails if missing key information": "0",
    },
    {
      id: 11,
      Input: "Write an email to confirm an appointment for a test drive.",
      Response: "Hi,\n\nYour test drive is confirmed.\n\nThanks,\nAutoCorp",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "2.0",
      "Reward responses that confirm details accurately": "0.5",
      "Penalize responses that contain errors in date/time": "0",
      "Response passes if it is courteous and professional": "0",
      "Response fails if missing key information": "1",
    },
    {
      id: 12,
      Input: "Write an email to confirm an appointment for a test drive.",
      Response:
        "Dear Ms. Brown,\n\nWe are writing to confirm your test drive appointment for the LuxSedan on April 12th at 10:00 AM. If you have any questions or need to reschedule, please do not hesitate to contact us.\n\nWarm regards,\nAutoCorp Sales Team\n(555) 123-4567",
      Context: "",
      Reference: "",
      Notes: "",
      Version: "3.0",
      "Reward responses that confirm details accurately": "1.0",
      "Penalize responses that contain errors in date/time": "0",
      "Response passes if it is courteous and professional": "1",
      "Response fails if missing key information": "0",
    },
  ];

  const columnDefsSample: ColDef[] = [
    { field: "Input", headerTooltip: "Input", sortable: true, filter: true },
    { field: "Response", headerTooltip: "Response", sortable: true, filter: true },
    { field: "Context", headerTooltip: "Context", sortable: true, filter: true },
    { field: "Reference", headerTooltip: "Reference", sortable: true, filter: true },
    { field: "Notes", headerTooltip: "Notes", sortable: true, filter: true },
    { field: "Version", headerTooltip: "Version", sortable: true, filter: true },
    {
      field: "Reward responses that highlight key features",
      headerTooltip: "Reward responses that highlight key features",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Penalize responses that contain grammatical errors",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Response passes if it includes a call-to-action",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Response fails if it lacks professional tone",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Reward responses that personalize the message",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Penalize responses that contain errors",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Response passes if it offers next steps",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Response fails if missing contact information",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Reward responses that confirm details accurately",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Penalize responses that contain errors in date/time",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Response passes if it is courteous and professional",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
    {
      field: "Response fails if missing key information",
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      // cellStyle is handled in DataGrid's defaultColDef
    },
  ];

  return { gridData: gridData, columnDefs: columnDefsSample };
};

export default APIPlayground;
