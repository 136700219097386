import React, { useEffect, useState } from "react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import TextareaAutosize from "react-textarea-autosize";

export interface ConversationMessage {
  role: "user" | "assistant";
  content: string;
}

export const MOCK_CONVERSATION_HISTORY: ConversationMessage[] = [
  {
    role: "user",
    content: "Hello",
  },
  {
    role: "assistant",
    content: "Hi there, how can I help?",
  },
];

interface ConversationHistoryProps {
  messages: ConversationMessage[];
  setMessages: (messages: ConversationMessage[]) => void;
  className?: string;
  xs?: boolean;
  disabled?: boolean;
}

export const ConversationHistory: React.FC<ConversationHistoryProps> = ({
  messages,
  setMessages,
  className,
  xs = false,
  disabled = false,
}) => {
  const [localMessages, setLocalMessages] = useState<ConversationMessage[]>(messages);

  useEffect(() => {
    setLocalMessages(messages);
  }, [messages]);

  if (localMessages == null || !Array.isArray(localMessages)) {
    return null;
  }

  if (localMessages.some((x) => x?.role === undefined || x?.content === undefined)) {
    return null;
  }

  const setContentAtIndex = (index: number, content: string) => {
    const currentMessages = [...localMessages];
    currentMessages[index].content = content;
    setLocalMessages(currentMessages);
  };

  const addMessage = () => {
    const currentMessages = [...localMessages];
    currentMessages.push({
      role: "user",
      content: "",
    });
    setMessages(currentMessages);
  };

  const flipRole = (index: number) => {
    const currentMessages = [...localMessages];
    currentMessages[index].role = currentMessages[index].role === "user" ? "assistant" : "user";
    setMessages(currentMessages);
  };

  return (
    <div className={`relative flex font-normal flex-col min-w-[200px] ${xs ? "text-xs" : "text-sm"} ${className}`}>
      <div
        className="absolute left-0 top-0 w-full h-full z-2 opacity-50 bg-mainbordergray rounded-xl"
        style={{
          visibility: disabled ? "visible" : "hidden",
        }}
      ></div>
      <div className={`bg-white border-solid border border-mainbordergray rounded-xl w-full ${xs ? "p-2" : "p-4"}`}>
        {localMessages.map((message, i) => (
          <div
            key={i}
            className="group flex flex-row items-center justify-start p-2 bg-white rounded-md border-none capitalize font-normal hover:bg-mainlightgray"
          >
            <button
              onClick={() => flipRole(i)}
              className="w-20 text-titlebrown btn btn-sm shadow-none border-none capitalize font-normal bg-transparent hover:bg-mainlightgray justify-start"
            >
              {message.role}
            </button>
            <TextareaAutosize
              className="flex-grow mx-2 resize-none group-hover:bg-mainlightgray bg-white focus:bg-white focus:cursor-text"
              defaultValue={message.content}
              onChange={(e) => setContentAtIndex(i, e.target.value)}
              onBlur={() => setMessages(localMessages)}
              minRows={1}
              maxRows={8}
            />
            <button
              disabled={disabled}
              onClick={() => {
                const currentMessages = [...localMessages];
                currentMessages.splice(i, 1);
                setMessages(currentMessages);
              }}
            >
              <MinusCircleIcon className="invisible group-hover:visible simple-icon-button text-primary fill-transparent" />
            </button>
          </div>
        ))}
        <hr></hr>
        <button
          className="flex flex-row items-center justify-start btn btn-sm shadow-none bg-white border-none capitalize font-normal hover:bg-mainlightgray w-full"
          onClick={addMessage}
        >
          <PlusCircleIcon className="simple-icon-button text-primary fill-transparent" />
          <div className="text-maindarkgray">Add message</div>
        </button>
      </div>
    </div>
  );
};
