import React from "react";
import { differenceInMinutes } from "date-fns";
import { FloatParameterType, IntegerParameterType, ParameterType, ParameterTypes } from "../../utils/interfaces";
import axios from "axios";

export enum AppStatusEnum {
  LIVE = "LIVE",
  DROPPED = "DROPPED",
  UNLINKED = "UNLINKED",
}

export const getLiveStatus = (last_active: string | null, is_live: boolean): AppStatusEnum => {
  if (last_active === null) {
    return AppStatusEnum.UNLINKED;
  }

  return is_live ? AppStatusEnum.LIVE : AppStatusEnum.DROPPED;
};

interface LiveStatusBadgeProps {
  last_active: string | null;
  is_live: boolean;
  className?: string;
}

export const LiveStatusBadge: React.FC<LiveStatusBadgeProps> = ({ last_active, is_live, className = "" }) => {
  const status = getLiveStatus(last_active, is_live);

  let badge_classname: string;
  if (status === AppStatusEnum.LIVE) {
    badge_classname = "badge badge-success";
  } else if (status === AppStatusEnum.DROPPED) {
    badge_classname = "badge badge-error";
  } else {
    badge_classname = "badge badge-info";
  }

  const messages: Record<AppStatusEnum, string> = {
    [AppStatusEnum.LIVE]: "Live",
    [AppStatusEnum.DROPPED]: "Dropped",
    [AppStatusEnum.UNLINKED]: "Unlinked",
  };

  const tooltipText: Record<AppStatusEnum, string> = {
    [AppStatusEnum.LIVE]: "App has a connected runner and is ready to use",
    [AppStatusEnum.DROPPED]: "App was been previously connected but that connection is now dropped",
    [AppStatusEnum.UNLINKED]: "App has never been connected to a runner",
  };

  return (
    <div className="tooltip tooltip-bottom cursor-pointer	" data-tip={tooltipText[status]}>
      <div className={`${badge_classname} ${className}`}>{messages[status]}</div>
    </div>
  );
};

interface NumberConstraintsProps {
  parameter: FloatParameterType | IntegerParameterType;
}

const NumberConstraints: React.FC<NumberConstraintsProps> = ({ parameter }) => {
  if (parameter.allowableMin !== null && parameter.allowableMax !== null) {
    return (
      <div>
        Between {parameter.allowableMin} and {parameter.allowableMax}
      </div>
    );
  } else if (parameter.allowableMin !== null) {
    return <div>Greater than {parameter.allowableMin}</div>;
  } else if (parameter.allowableMax !== null) {
    return <div>Less than {parameter.allowableMax}</div>;
  } else {
    return <div>No constraints</div>;
  }
};

interface ConstraintDisplayProps {
  parameter: ParameterTypes;
}

export const ConstraintDisplay: React.FC<ConstraintDisplayProps> = ({ parameter }) => {
  if (parameter.param_type === ParameterType.FLOAT || parameter.param_type === ParameterType.INTEGER) {
    return (
      <div>
        <NumberConstraints parameter={parameter} />
      </div>
    );
  } else if (parameter.param_type === ParameterType.MULTICHOICESTRING) {
    return <div>Any from: {parameter?.choices?.join(", ")}</div>;
  } else {
    return <div>No constraints</div>;
  }
};
