import React, { useState, useEffect, Fragment, useContext } from "react";
import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { APP_ENDPOINT } from "../../globals";
import urlJoin from "url-join";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../presentation_components/primitives/Breadcrumbs";
import { Dialog, Transition } from "@headlessui/react";
import { LiveStatusBadge } from "../../presentation_components/primitives/AppBadges";
import { ApiKeyBadge } from "../../presentation_components/primitives/ApiKeyBadge";
import NewAppModal from "../../presentation_components/NewAppModal";
import { USER_ENDPOINT, DEMO_APPS } from "../../globals";
import moment from "moment";
import { DemoCard } from "../../presentation_components/DemoCard";
import { App, Runner } from "../../utils/model";
import { tenantedRouteBuilder } from "../../utils/tenantManager";
import { AppCreateType, TenantType } from "../../utils/interfaces";
import { TenantContext } from "../../utils/TenantContext";

export default function ProjectsPage() {
  const activeTenant = useContext<TenantType | null>(TenantContext);

  const [isOpen, setIsOpen] = useState(false);
  const [apps, setApps] = useState<App[]>([]);

  useEffect(() => {
    App.fetchAll().then((apps) => {
      setApps(apps);
      return apps;
    });

    const intervalId = setInterval(() => {
      App.fetchAll().then((apps) => {
        setApps(apps);
        return apps;
      });
    }, 5000); // fetch at interval

    // cleanup function
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const submitDemoApp = (indexToLoad: number) => {
    // window.location.href = "/link?api_key=".concat(api_key);
    if (!activeTenant) {
      return;
    }

    App.create(
      {
        name: DEMO_APPS[indexToLoad].name,
        api_key: DEMO_APPS[indexToLoad].api_key,
      } as AppCreateType,
      activeTenant.id,
    ).then((newApp) => {
      toast.success("Demo App loaded");
      setApps((prevApps) => [...prevApps, newApp]);
    });
  };

  console.log("apps", apps);

  return (
    <div className="platform-container">
      <Breadcrumbs routes={[{ name: "Apps", href: tenantedRouteBuilder("/project") }]} appendArrow={true} />

      <NewAppModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSuccess={(data: any) => {
          App.fetchAll().then((apps) => {
            setApps(apps);
            return apps;
          });
        }}
        alreadyOwnedApps={apps}
      />

      <div className="flex flex-row justify-between items-center pb-6 pt-8">
        <h1>My Apps</h1>
        <div className="flex flex-row items-center space-x-4">
          <button className="simple-icon-button">
            <ArrowPathIcon
              onClick={() => {
                setApps([]);
                App.fetchAll().then((apps) => {
                  setApps(apps);
                  return apps;
                });
              }}
            />
          </button>

          <button className="btn btn-primary text-white" onClick={() => setIsOpen(true)}>
            Link New App
          </button>
        </div>
      </div>

      <div className="flex flex-col w-full flex-grow">
        <table className="table">
          <thead className="table-header">
            <tr className="">
              <th>App</th>
              <th>Latest Version</th>
              <th>Date Created</th>
              <th>Api Key</th>
              <th>Linking Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {apps.map((app: App) => (
              <tr
                key={app.id}
                className="w-full hover:bg-mainlightgray hover:cursor-pointer"
                onClick={() => {
                  // if you're on this page you're already at /project
                  // so all you need to do is add /app_id to the url
                  window.location.href = urlJoin(window.location.href, app.id.toString());
                }}
              >
                <td className=" overflow-hidden whitespace-nowrap text-ellipsis">{app.name}</td>
                <td className=" overflow-hidden whitespace-nowrap text-ellipsis">{app.latest_version}</td>
                <td className="whitespace-nowrap text-ellipsis">{moment(app.created).format("Do MMM YYYY")}</td>

                <td className=" relative">
                  <ApiKeyBadge apiKey={app.api_key} />
                </td>

                <td className="">
                  <LiveStatusBadge last_active={app.last_active} is_live={app.is_live} />
                </td>
                <td className="">
                  <XMarkIcon
                    className="simple-icon-button"
                    onClick={(e) => {
                      e.preventDefault(); // stop the row from being clicked
                      e.stopPropagation(); // stop the row from being clicked
                      App.delete(app.id).then(() => {
                        toast.success("App deleted.");
                        App.fetchAll().then((apps) => {
                          setApps(apps);
                          return apps;
                        });
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Test Set Library Section */}
      <div className="mt-auto space-y-4">
        <hr className="border-t border-mainbordergray" />
        <h2>Apps Library</h2>

        <div className="flex flex-row flex-wrap justify-start">
          {DEMO_APPS.map((demoApp, index) => (
            <DemoCard className="mr-8 mb-8" key={index} title={demoApp.name} onClick={() => submitDemoApp(index)}>
              <div className="flex justify-between space-x-4">
                <div className="text-maindarkgray text-xs">{demoApp.hook}</div>
              </div>
            </DemoCard>
          ))}
        </div>
      </div>
    </div>
  );
}
