import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";

export interface BreadcrumbItem {
  name: string;
  href?: string;
  editCallback?: (newName: string) => void;
}

interface EditableBreadcrumbBoxProps {
  name: string;
  editCallback: (newName: string) => void;
  className: string;
}

const EditableBreadcrumbBox: React.FC<EditableBreadcrumbBoxProps> = ({ name, editCallback, className }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(name);

  // Use useEffect to update newName when name prop changes
  useEffect(() => {
    if (name !== null && name !== "") {
      setNewName(name);
    }
  }, [name]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      editCallback(newName);
    }
  };

  const divClassname = `flex flex-row flex-nowrap items-center justify-start ${className}`;

  return isEditing ? (
    <div className={divClassname}>
      <input
        value={newName}
        className="focus:outline-none border-none w-96" // Make the input undetectable
        onChange={(e) => setNewName(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={() => {
          setIsEditing(false);
          editCallback(newName);
        }}
        autoFocus
      />
      <div className={divClassname}></div>
    </div>
  ) : (
    <div className={divClassname}>
      {name}
      <PencilSquareIcon className="tiny-icon-button ml-2" onClick={() => setIsEditing(true)} />
    </div>
  );
};

interface BreadcrumbsProps {
  routes: BreadcrumbItem[];
  appendArrow?: boolean;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ routes, appendArrow = true }) => {
  if (appendArrow) {
    if (routes[routes.length - 1].name !== "") {
      routes.push({ name: "", href: "" });
    }
  }

  const styleByIndex = (index: number) => {
    if (index === routes.length - 1) {
      return "text-primary"; // Could consider making this a different color
    } else {
      return "text-primary";
    }
  };

  return (
    <div className="flex flex-row breadcrumbs flex-shrink bg-white">
      <ul>
        {routes.map((item, index) => {
          const itemTag =
            item.href !== "#" && item.href !== undefined && item.href !== null && item.href !== "" ? (
              <Link to={item.href} className={`link-reset ${styleByIndex(index)}`}>
                {item.name}
              </Link>
            ) : item.editCallback !== null && item.editCallback !== undefined ? (
              <EditableBreadcrumbBox
                name={item.name}
                editCallback={item.editCallback}
                className={styleByIndex(index)}
              />
            ) : (
              <span className={styleByIndex(index)}>{item.name}</span>
            );

          return <li key={index}>{itemTag}</li>;
        })}
      </ul>
    </div>
  );
};
