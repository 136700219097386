import React, { useState, Fragment } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Changed this line
import { Transition, Dialog } from "@headlessui/react";
import { Dropzone } from "../Dropzone";
import { Testcase, Testset } from "../../utils/model";
import { TestcaseCreateType } from "../../utils/interfaces";
import { CriteriaSelectorEnum } from "../../utils/interfaces";
import { tenantedRouteBuilder } from "../../utils/tenantManager";

interface NewTestsetModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function NewTestsetModal({ isOpen, setIsOpen }: NewTestsetModalProps): React.ReactElement {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const navigate = useNavigate(); // Changed this line

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const arrayInputObjectsCallback = async (arrayOfObjects: Array<Record<string, string>> | null) => {
    const newTestset = await Testset.create({ name: "New test set" });

    if (arrayOfObjects !== null) {
      const tcs = arrayOfObjects.map(async (row, index) => {
        return await Testcase.create({
          name: "Test case " + (index + 1),
          inputs: row,
          dataset_id: newTestset.id,
        });
      });
    } else {
      // couple of default testcases
      await Testcase.create({
        name: "Test case 1",
        inputs: { user_message: "" },
        dataset_id: newTestset.id,
      });
      await Testcase.create({
        name: "Test case 2",
        inputs: { user_message: "" },
        dataset_id: newTestset.id,
      });
    }

    toast.success("Test set created");
    navigate(tenantedRouteBuilder("/testset/" + newTestset.id)); // Changed this line
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-titlebrown flex flex-row justify-between items-center"
                  >
                    Create A New Test Set{" "}
                  </Dialog.Title>
                  <div className="relative">
                    <div
                      className={`absolute w-full h-full flex flex-col space-y-8 items-center justify-center bg-white z-20 ${
                        isProcessing ? "visible" : "hidden"
                      }`}
                    >
                      <div>Processing. This could take a few minutes...</div>
                      <div className="loading loading-spinner"></div>
                    </div>
                    <div className={`flex flex-row items-center mt-12 space-x-10 items-stretch `}>
                      <div className="flex flex-col justify-start items-start space-y-4">
                        <h4>Upload Testset</h4>
                        <div className="w-full flex-grow ">
                          <Dropzone id="csv-dropzone" onUploadCallback={arrayInputObjectsCallback} accept=".csv">
                            <p className="text-xs text-maindarkgray dark:text-maindarkgray">
                              CSV or XLSX files only. Max File Size: 100MB.
                            </p>
                            <button
                              onClick={() => {
                                const templateData: Array<Array<string>> = [
                                  ["user_message"],
                                  ["How is Google supporting Ukraine?"],
                                  [
                                    "How is Google helping people find new ways to empower people to stay on top of their wellness",
                                  ],
                                  ["What is Google's position on OpenAI?"],
                                  [
                                    "How much of the total voting power of Google's common stock do Larry & Sergey have?",
                                  ],
                                ];

                                const csvContent =
                                  "data:text/csv;charset=utf-8," + templateData.map((e) => e.join(",")).join("\n");

                                const encodedUri = encodeURI(csvContent);
                                const link = document.createElement("a");
                                link.setAttribute("href", encodedUri);
                                link.setAttribute("download", "template.csv");
                                document.body.appendChild(link);

                                link.click();

                                document.body.removeChild(link);
                              }}
                              className="btn btn-outline btn-sm mt-6"
                            >
                              Download Template
                            </button>
                          </Dropzone>
                        </div>
                      </div>
                      <div className="divider divider-horizontal">OR</div>
                      <div className="flex flex-col justify-center space-y-2 relative ">
                        <h4 className="absolute top-0 left-0">Start Fresh</h4>
                        <button className="btn btn-primary" onClick={() => arrayInputObjectsCallback(null)}>
                          Create in Composo
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
