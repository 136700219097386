import React, { useEffect, useState } from "react";
import { PLAYGROUND_DATACASE_HEIGHT, CRITERIA_DETAILS } from "../../globals";
import { CaseScoreStat } from "../primitives/EvaluationBadges";
import { JsonViewer } from "../JsonViewer";
import { Evalcase, Evaluatorconfig, Evaluatorresult, Testcase } from "../../utils/model";
import { CriteriaSelectorEnum, CriteriaTypes, EvalcaseReturnType, TestcaseReturnType } from "../../utils/interfaces";
import { set } from "date-fns";
import { EditableScore } from "../primitives/EditableScore";
import { EditableTitleDisplay } from "../EditableTitleDisplay";
import { EditableTextDisplay } from "../EditableTextDisplay";
import { PencilIcon, PencilSquareIcon } from "@heroicons/react/20/solid";

const SUMMARY_TAB_NAME = "summary";

interface EvalcaseResultDisplayProps {
  evalcase: EvalcaseReturnType | null;
  testcase: TestcaseReturnType | null;
}

const SummaryErrorDisplay = ({ evalcase }: { evalcase: Evalcase }) => {
  return (
    <div className="w-full h-full flex flex-col justify-center">
      <h4 className="ml-2 my-2">Error</h4>
      <div className="w-full p-2 overflow-y-scroll no-scrollbar whitespace-pre-wrap text-mainorange">
        {evalcase.run_error}
      </div>
    </div>
  );
};

const SummarySuccessDisplay = ({
  evalcase,
  evaluatorconfigs,
  evaluatorresults,
}: {
  evalcase: Evalcase;
  evaluatorconfigs: Evaluatorconfig[];
  evaluatorresults: Evaluatorresult[];
}) => {
  if (evaluatorresults == null) {
    return <div>Error: Criteria results were not generated</div>;
  }

  const { numPassed, outOf } = Evalcase.passRate(evaluatorconfigs, evaluatorresults);

  return (
    <>
      <div className="w-1/2 h-full flex flex-col">
        <h4 className="ml-2 my-2">App Output</h4>
        <div className="w-full grow bg-mainlightgray border-solid border border-mainbordergray rounded-lg p-2 overflow-y-scroll no-scrollbar whitespace-pre-wrap">
          <JsonViewer data={evalcase.output} />
        </div>
      </div>
      <div className="flex flex-col w-1/2 h-full justify-start items-start px-4 overflow-y-scroll no-scrollbar">
        <div className="w-full flex flex-row justify-center mt-4 mb-4">
          {outOf === 0 ? (
            <div className="text-mainorange">No evaluators were configured</div>
          ) : (
            <div className={`stats bg-white ${numPassed === outOf ? "text-maingreen" : "text-mainorange"}`}>
              <div className="stat">
                {/* <div className="stat-title">Score</div> */}
                <div className="stat-value">
                  {numPassed} / {outOf}
                </div>
                <div className="stat-desc w-full truncate">Evaluators passed</div>
              </div>
            </div>
          )}
        </div>
        {evaluatorresults.map((thisEvaluatorresult, index) => {
          const thisEvaluatorconfig = evaluatorconfigs.find(
            (config) => config.id === thisEvaluatorresult.evaluatorconfig_id,
          );

          if (thisEvaluatorconfig == null) {
            throw new Error("Could not find evaluator config for result");
          }

          return (
            <div className="flex flex-row w-full items-center justify-between" key={index}>
              <div className="">{CRITERIA_DETAILS[thisEvaluatorconfig.config.type].displayName}:</div>
              <div
                className={`font-mono ${
                  Evaluatorresult.didPass(thisEvaluatorconfig, thisEvaluatorresult)
                    ? "text-maingreen"
                    : "text-mainorange"
                } `}
              >
                {thisEvaluatorresult.user_score || thisEvaluatorresult.score} /{" "}
                {CRITERIA_DETAILS[thisEvaluatorconfig.config.type].maxScore}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const CriteriaSuccessDisplay = ({
  evaluatorconfig,
  setEvaluatorconfig,
  evaluatorresult,
  setEvaluatorresult,
}: {
  evaluatorconfig: Evaluatorconfig | null;
  setEvaluatorconfig: (x: Evaluatorconfig | null) => void;
  evaluatorresult: Evaluatorresult | null;
  setEvaluatorresult: (x: Evaluatorresult | null) => void;
}) => {
  const [localComment, setLocalComment] = useState("");

  useEffect(() => {
    let comment = evaluatorresult?.user_comment || evaluatorresult?.comment;
    setLocalComment(comment || "");
  }, [evaluatorresult]);

  return (
    <>
      <div className="w-1/2 h-full flex flex-col items-center justify-center px-4">
        {/* <CaseScoreStat criteria={criteria} criteriaResults={criteriaResult} className="" /> */}
        <EditableScore
          title="Score"
          undertext={
            evaluatorconfig ? "on " + CRITERIA_DETAILS[evaluatorconfig.config.type].displayName + " evaluator" : ""
          }
          score={
            evaluatorresult?.user_score != null
              ? evaluatorresult.user_score
              : evaluatorresult?.score != null
                ? evaluatorresult.score
                : null
          }
          outOf={evaluatorconfig ? CRITERIA_DETAILS[evaluatorconfig?.config.type].maxScore : null}
          successThreshold={evaluatorconfig ? CRITERIA_DETAILS[evaluatorconfig?.config.type].successThreshold : null}
          onScoreChange={(newScore) => {
            if (evaluatorresult != null) {
              Evaluatorresult.update(evaluatorresult?.id, { user_score: newScore });
              setEvaluatorresult({ ...evaluatorresult, user_score: newScore });
            }
          }}
        />
      </div>
      <div className="w-1/2 h-full flex flex-col justify-center">
        <h4 className="ml-2 my-2">Comment</h4>
        <textarea
          className="w-full h-full textarea textarea-xs resize-none"
          value={localComment}
          onChange={(event) => setLocalComment(event.target.value)}
          onBlur={(event) => {
            let updatedComment = event.target.value;
            setLocalComment(updatedComment);
            if (evaluatorresult != null) {
              Evaluatorresult.update(evaluatorresult?.id, { user_comment: updatedComment });
              if (evaluatorresult != null) {
                setEvaluatorresult({ ...evaluatorresult, user_comment: updatedComment });
              }
            }
          }}
        />
      </div>
    </>
  );
};

const CriteriaErrorDisplay = ({
  criteria,
  criteriaResult,
}: {
  criteria: Evaluatorconfig | null;
  criteriaResult: Evaluatorresult | null;
}) => {
  return (
    <div className="w-full h-full flex flex-col justify-center">
      <h4 className="ml-2 my-2">Error</h4>
      <div className="w-full p-2 overflow-y-scroll no-scrollbar whitespace-pre-wrap text-mainorange">
        {criteriaResult?.eval_error}
      </div>
    </div>
  );
};

const RunningSummaryDisplay = ({ evalcase }: { evalcase: Evalcase }) => {
  return (
    <>
      <div className="w-1/2 h-full flex flex-col">
        <h4 className="ml-2 my-2">App Output</h4>
        <div className="w-full grow bg-mainlightgray border-solid border border-mainbordergray rounded-lg p-2 overflow-y-scroll no-scrollbar whitespace-pre-wrap">
          <JsonViewer data={evalcase.output} />
        </div>
      </div>
      <div className="w-1/2 h-full flex flex-col items-center justify-center">
        <div className="loading loading-spinner"></div>
      </div>
    </>
  );
};

const RunningCriteriaDisplay = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="loading loading-spinner"></div>
    </div>
  );
};

const Display = ({
  selectedTab,
  testcase,
  evalcase,
  evaluatorconfigs,
  setEvaluatorconfigs,
  evaluatorresults,
  setEvaluatorresults,
}: {
  selectedTab: string;
  testcase: Testcase;
  evalcase: Evalcase;
  evaluatorconfigs: Evaluatorconfig[] | null;
  setEvaluatorconfigs: (x: Evaluatorconfig[] | null) => void;
  evaluatorresults: Evaluatorresult[] | null;
  setEvaluatorresults: (x: Evaluatorresult[] | null) => void;
}) => {
  // The job of this component is simply to be a router

  // calculate allResultsGenerated which should check that every config which has a type other than "NO_CRITERIA" has a corresponding result
  const allResultsGenerated = evaluatorconfigs?.every(
    (config) =>
      config.config.type === CriteriaSelectorEnum.NO_CRITERIA ||
      evaluatorresults?.some((result) => result.evaluatorconfig_id === config.id),
  );

  // Using a switch statement to decide which component to render
  if (selectedTab === SUMMARY_TAB_NAME) {
    if (Evalcase.didError(evalcase)) {
      return <SummaryErrorDisplay evalcase={evalcase} />;
    } else if (!evaluatorconfigs || !evaluatorresults || !allResultsGenerated) {
      return <RunningSummaryDisplay evalcase={evalcase} />;
    } else {
      return (
        <SummarySuccessDisplay
          evalcase={evalcase}
          evaluatorconfigs={evaluatorconfigs}
          evaluatorresults={evaluatorresults}
        />
      );
    }
  } else if (selectedTab !== SUMMARY_TAB_NAME) {
    // Dynamically getting the selected evaluator
    const selectedEvaluatorresult = evaluatorresults?.find((result) => result.id === selectedTab) || null;
    const selectedEvaluatorconfig =
      evaluatorconfigs?.find((config) => config.id === selectedEvaluatorresult?.evaluatorconfig_id) || null;

    const setSelectedEvaluatorresult = (x: Evaluatorresult | null) => {
      if (evaluatorresults == null) {
        return;
      }
      setEvaluatorresults(
        evaluatorresults.map((result) => {
          if (result.id === selectedTab) {
            return x || result;
          } else {
            return result;
          }
        }),
      );
    };

    const setSelectedEvaluatorconfig = (x: Evaluatorconfig | null) => {
      if (evaluatorconfigs == null) {
        return;
      }
      setEvaluatorconfigs(
        evaluatorconfigs.map((config) => {
          if (config.id === selectedEvaluatorresult?.evaluatorconfig_id) {
            return x || config;
          } else {
            return config;
          }
        }),
      );
    };

    if (evaluatorresults == null || evaluatorconfigs == null || evaluatorresults?.length === 0) {
      return <div>Error: no evaluation results to display</div>;
    } else if (selectedEvaluatorresult == null) {
      return <RunningCriteriaDisplay />;
    } else if (Evaluatorresult.didError(selectedEvaluatorresult)) {
      return <CriteriaErrorDisplay criteria={selectedEvaluatorconfig} criteriaResult={selectedEvaluatorresult} />;
    } else {
      return (
        <CriteriaSuccessDisplay
          evaluatorconfig={selectedEvaluatorconfig}
          setEvaluatorconfig={setSelectedEvaluatorconfig}
          evaluatorresult={selectedEvaluatorresult}
          setEvaluatorresult={setSelectedEvaluatorresult}
        />
      );
    }
  } else {
    return <div>Error</div>;
  }
};

export const useEvaluatorConfigs = (evalcase: Evalcase | null) => {
  const [_evaluatorconfigs, setEvaluatorConfigs] = useState<Evaluatorconfig[] | null>(null);
  const [evaluatorresults, setEvaluatorResults] = useState<Evaluatorresult[] | null>(null);

  // get stuff when evalcase changes
  useEffect(() => {
    if (evalcase == null) {
      setEvaluatorConfigs(null);
      setEvaluatorResults(null);
      return;
    }

    const fetchConfigs = async () => {
      const configs = await Evaluatorconfig.fetchAll({
        keyValueFilters: {
          datacase_id: evalcase.datacase_id,
        },
      });
      setEvaluatorConfigs(configs);

      const results = await Evaluatorresult.fetchAll({
        keyValueFilters: {
          evalcase_id: evalcase.id,
        },
      });
      setEvaluatorResults(results);
    };

    fetchConfigs();
  }, [evalcase]);

  // // remove results if configs are removed
  // useEffect(() => {
  //   if (_evaluatorconfigs == null) {
  //     setEvaluatorResults(null);
  //   }
  // }, [_evaluatorconfigs]);

  // // remove configs if results are removed
  // useEffect(() => {
  //   if (evaluatorresults == null) {
  //     setEvaluatorConfigs(null);
  //   }
  // }, [evaluatorresults]);

  const evaluatorconfigs = _evaluatorconfigs?.filter(
    (config) => config.config.type !== CriteriaSelectorEnum.NO_CRITERIA,
  );

  const _setEvaluatorconfigs = (x: Evaluatorconfig[] | null) => setEvaluatorConfigs(x);
  const _setEvaluatorResults = (x: Evaluatorresult[] | null) => setEvaluatorResults(x);

  if (evaluatorconfigs == null || evaluatorresults == null || evalcase == null) {
    return {
      evaluatorconfigs: null,
      setEvaluatorconfigs: _setEvaluatorconfigs,
      evaluatorresults: null,
      setEvaluatorresults: _setEvaluatorResults,
    };
  } else {
    return {
      evaluatorconfigs: evaluatorconfigs,
      setEvaluatorconfigs: _setEvaluatorconfigs,
      evaluatorresults: evaluatorresults,
      setEvaluatorresults: _setEvaluatorResults,
    };
  }
};

export const useMultipleEvaluatorConfigs = (evalcases: Evalcase[] | null) => {
  const [evaluatorConfigsById, setEvaluatorConfigsById] = useState<{ [key: string]: Evaluatorconfig[] | null }>({});
  const [evaluatorResultsById, setEvaluatorResultsById] = useState<{ [key: string]: Evaluatorresult[] | null }>({});

  useEffect(() => {
    if (evalcases == null || evalcases.length === 0) {
      setEvaluatorConfigsById({});
      setEvaluatorResultsById({});
      return;
    }

    const fetchConfigsAndResults = async () => {
      const configsById: { [key: string]: Evaluatorconfig[] | null } = {};
      const resultsById: { [key: string]: Evaluatorresult[] | null } = {};

      await Promise.all(
        evalcases.map(async (evalcase) => {
          const configs = await Evaluatorconfig.fetchAll({
            keyValueFilters: {
              datacase_id: evalcase.datacase_id,
            },
          });

          const results = await Evaluatorresult.fetchAll({
            keyValueFilters: {
              evalcase_id: evalcase.id,
            },
          });

          configsById[evalcase.id] = configs;
          resultsById[evalcase.id] = results;
        }),
      );

      setEvaluatorConfigsById(configsById);
      setEvaluatorResultsById(resultsById);
    };

    fetchConfigsAndResults();
  }, [evalcases]);

  return { evaluatorConfigsById, evaluatorResultsById };
};

export const EvalcaseResultDisplay: React.FC<EvalcaseResultDisplayProps> = ({ testcase, evalcase }) => {
  const [selectedTab, setSelectedTab] = useState(SUMMARY_TAB_NAME);

  const { evaluatorconfigs, setEvaluatorconfigs, evaluatorresults, setEvaluatorresults } =
    useEvaluatorConfigs(evalcase);

  if (testcase == null || evalcase == null || evaluatorconfigs == null || evaluatorresults == null) {
    return (
      <div
        className="relative w-full p-0 m-0 flex flex-row items-center justify-center"
        style={{
          height: PLAYGROUND_DATACASE_HEIGHT,
        }}
        key={evalcase?.id}
      >
        No result to display
      </div>
    );
  }

  return (
    <div
      className="relative p-0 m-0 "
      style={{
        height: PLAYGROUND_DATACASE_HEIGHT,
      }}
    >
      <div className="" style={{ height: 32 }}>
        <select
          value={selectedTab}
          className="select select-primary select-xs w-48"
          onChange={(e) => setSelectedTab(e.target.value)}
        >
          <option value={"summary"}>Summary</option>
          {evaluatorresults.map((thisResult: Evaluatorresult, index) => {
            const thisConfig = evaluatorconfigs.find((config) => config.id === thisResult.evaluatorconfig_id);
            if (thisConfig == null) {
              return null;
              // this shouldn't really occur, but it seems to...
              // throw new Error("Could not find evaluator config for result");
            }
            return (
              <option value={thisResult.id} key={thisResult.id}>
                {CRITERIA_DETAILS[thisConfig?.config.type].displayName}
              </option>
            );
          })}
        </select>
      </div>

      <div
        className="w-full text-xs bg-mainlightgray border border-mainbordergray shadow rounded-md p-2 "
        style={{
          height: PLAYGROUND_DATACASE_HEIGHT - 32,
        }}
      >
        <div className="w-full h-full flex flex-row">
          <Display
            selectedTab={selectedTab}
            testcase={testcase}
            evalcase={evalcase}
            evaluatorconfigs={evaluatorconfigs}
            setEvaluatorconfigs={setEvaluatorconfigs}
            evaluatorresults={evaluatorresults}
            setEvaluatorresults={setEvaluatorresults}
          />
        </div>
      </div>
    </div>
  );
};
