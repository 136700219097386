import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BreadcrumbItem, Breadcrumbs } from "../../presentation_components/primitives/Breadcrumbs";
import { ConstraintDisplay } from "../../presentation_components/primitives/AppBadges";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { ApiKeyBadge } from "../../presentation_components/primitives/ApiKeyBadge";
import moment from "moment";
import { TruncateText } from "../../presentation_components/primitives/TruncateText";
import { LiveStatusBadge } from "../../presentation_components/primitives/AppBadges";
import ReactMarkdown from "react-markdown";
import { ParameterTypes, VersionDetails } from "../../utils/interfaces";
import { App, Runner } from "../../utils/model";
import { tenantedRouteBuilder } from "../../utils/tenantManager";
import {
  useFreshAppRunners,
  useUniqueRunnerVersions,
  useSelectedVersionDetails,
  useAllVersionDetails,
  getVersionDetails,
} from "../../presentation_components/AppPlayground/RunnerHooks";
import { ResponsiveCalendar } from "@nivo/calendar";
import { demoCalendarData } from "../../../data/demoData";

enum Tabs {
  Tab1 = "tab1",
  Tab2 = "tab2",
  Tab3 = "tab3",
  // Add more tabs here as needed
}

const PerformanceTab = () => {
  return (
    <div className="flex flex-col space-y-4 h-96">
      <ResponsiveCalendar
        data={demoCalendarData}
        from="2023-03-01"
        to="2024-05-09"
        emptyColor="#eeeeee"
        colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
          },
        ]}
      />
    </div>
  );
};

const VersionDetailsCard: React.FC<{ versionDetails: VersionDetails | null }> = ({ versionDetails }) => {
  if (!versionDetails) {
    return <div>Version details are not available</div>;
  }

  console.log(versionDetails?.first_created);

  return (
    <>
      {versionDetails?.docstring ? (
        <div className="w-full mb-8">
          <ReactMarkdown>{versionDetails.docstring}</ReactMarkdown>
        </div>
      ) : null}
      {versionDetails?.parameters ? (
        <div className="mt-8 w-full">
          <div className="flex flex-col w-full overflow">
            <table
              // style={{
              //   tableLayout: "fixed",
              // }}
              className="table"
            >
              <thead className="w-full table-header">
                <tr className="w-full">
                  <th className=""></th>
                  {versionDetails.parameters.map((param, index) => (
                    <th className="table-header-cell" key={index}>
                      {param.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-bold">Description:</td>
                  {versionDetails.parameters.map((param, index) => (
                    <td key={index}>
                      <TruncateText text={param.description || ""} pushX={3} pushY={3} />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="font-bold">Default value</td>
                  {versionDetails.parameters.map((param, index) => (
                    <td key={index}>
                      <TruncateText
                        text={
                          param.param_type === "CONVERSATIONHISTORY"
                            ? JSON.stringify(param.demo_value)
                            : String(param.demo_value)
                        }
                        pushX={10}
                        pushY={10}
                        lineClamp={10}
                        minBoundLettersPerLine={10}
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="font-bold">Permissable Values</td>
                  {versionDetails.parameters.map((param, index) => (
                    <td key={index}>
                      <ConstraintDisplay parameter={param as ParameterTypes} />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="flex flex-row w-full flex-grow items-center justify-center">
          App data will appear here after it has been linked
        </div>
      )}
    </>
  );
};

export interface VersionTabProps {
  appData: App | null;
  runners: Runner[] | null;
  uniqueRunnerVersions: string[] | null;
}

const VersionTab: React.FC<VersionTabProps> = ({ appData, runners, uniqueRunnerVersions }) => {
  const [selectedRunnerVersion, setSelectedRunnerVersion] = useState<string | null>(null);
  // const versionDetails = useSelectedVersionDetails(runners, selectedRunnerVersion);
  const allVersionDetails = useAllVersionDetails(runners);

  // whenever appData changes or uniqueRunnerVersions changes, update the selectedRunnerVersion to the latest version
  useEffect(() => {
    if (uniqueRunnerVersions && uniqueRunnerVersions.length > 0) {
      setSelectedRunnerVersion(uniqueRunnerVersions[uniqueRunnerVersions.length - 1]);
    }
  }, [appData, uniqueRunnerVersions]);

  if (!uniqueRunnerVersions || !allVersionDetails) {
    return <div>No versions available</div>;
  }

  return (
    <>
      <div className="join join-vertical w-full mt-8">
        {uniqueRunnerVersions
          .slice() // slice before reverse to avoid mutating the original array
          .reverse()
          .map((version, index) => {
            const versionDetails = getVersionDetails(runners, version);
            if (!versionDetails) {
              return null;
            }
            return (
              <div className="collapse collapse-arrow join-item border border-base-300">
                <input
                  type="radio"
                  name="my-accordion-4"
                  checked={selectedRunnerVersion === versionDetails?.version}
                  onClick={() => {
                    if (selectedRunnerVersion === versionDetails?.version) {
                      setSelectedRunnerVersion(null);
                    } else {
                      setSelectedRunnerVersion(versionDetails?.version || null);
                    }
                  }}
                />
                <div className="collapse-title flex flex-row hover:cursor-pointer items-center justify-start">
                  <h2 className="w-40">
                    Version <span className="font-mono ml-1">{versionDetails?.version}</span>
                  </h2>
                  <LiveStatusBadge last_active={versionDetails.last_active} is_live={versionDetails.is_live} />
                </div>
                <div className="collapse-content">
                  <VersionDetailsCard versionDetails={versionDetails} />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

const HistoryTab = () => {
  return <div>History Tab</div>;
};

export default function AppDetails() {
  const params = useParams<{ id: string }>();
  const [appData, setAppData] = useState<App | null>(null);

  const runners = useFreshAppRunners(appData);
  const uniqueRunnerVersions = useUniqueRunnerVersions(appData);

  const latestVersionDetails = useSelectedVersionDetails(
    runners,
    uniqueRunnerVersions ? uniqueRunnerVersions[uniqueRunnerVersions.length - 1] : null,
  );

  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Tab2);

  const fetchAppData = async () => {
    if (!params.id) {
      return;
    }
    App.fetch(params.id).then((app) => {
      setAppData(app);
    });
  };

  useEffect(() => {
    fetchAppData(); // initial fetch
  }, [params.id]);

  console.log("appData", appData);
  console.log("uniqueRunnerVersions", uniqueRunnerVersions);

  // useEffect(() => {
  //   fetchAppData(); // initial fetch
  //   const intervalId = setInterval(() => {
  //     fetchAppData();
  //   }, 20000); // fetch every 20 seconds

  //   // cleanup function
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [params.id]);

  if (!params.id) {
    return <div>No app ID was provided</div>;
  }

  const breadcrumbRoutes: BreadcrumbItem[] = [
    { name: "Apps", href: tenantedRouteBuilder("/project") },
    {
      name: appData?.name || "Loading...",
      href: "",
      editCallback: (x: string) => {
        setAppData((prev) => (prev ? { ...prev, name: x } : null));
        if (appData) {
          App.update(appData.id, { name: x }).then((data) => setAppData(data));
        }
      },
    },
  ];

  return (
    <div className="platform-container">
      <Breadcrumbs routes={breadcrumbRoutes} appendArrow={false} />

      <div className="w-full flex flex-row justify-center">
        <div role="tablist" className="tabs tabs-bordered w-1/2">
          <div
            role="tab"
            className={`tab ${selectedTab === Tabs.Tab2 ? "tab-active" : ""}`}
            // onClick={() => setSelectedTab(Tabs.Tab2)}
          >
            Versions
          </div>
          <div
            role="tab"
            className={`tab ${selectedTab === Tabs.Tab1 ? "tab-active" : ""}`}
            // onClick={() => setSelectedTab(Tabs.Tab1)}
          >
            Performance (coming soon)
          </div>

          <div
            role="tab"
            className={`tab ${selectedTab === Tabs.Tab3 ? "tab-active" : ""}`}
            // onClick={() => setSelectedTab(Tabs.Tab3)}
          >
            History (coming soon)
          </div>
        </div>
      </div>

      {
        {
          [Tabs.Tab1]: <PerformanceTab />,
          [Tabs.Tab2]: <VersionTab runners={runners} appData={appData} uniqueRunnerVersions={uniqueRunnerVersions} />,
          [Tabs.Tab3]: <div>History</div>,
        }[selectedTab]
      }
    </div>
  );
}
